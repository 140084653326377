<template>
  <week-card-skeleton v-if="loading" />
  <v-card v-else @click="$emit('click', week.id)" color="#F4F5F5" flat>
    <div class="week-border">
      <v-card-title class="d-flex justify-space-between">
        <h4 class="title font-weight-black">{{ week.name }}</h4>
        <div v-if="editable">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('view')">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sub-object</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-subtitle v-if="hideDays">{{ subtitle }}</v-card-subtitle>
      <template v-if="!hideDays">
        <v-card-text class="trix-content" v-html="week.description"></v-card-text>
        <v-row class="px-4 mb-2">
          <v-col
            v-for="day in week.days"
            :key="day.id"
            cols="12"
            md="6"
            lg="4"
            xl="3"
            align-self="center"
          >
            <day-card :day="day" @cardClicked="$emit('dayClicked', day.id)"></day-card>
          </v-col>
        </v-row>
      </template>
    </div>
  </v-card>
</template>

<script>
import DayCard from "./DayCard";
import WeekCardSkeleton from "@/components/SkeletonLoaders/Cards/WeekCardSkeleton";
export default {
  name: "WeekCard",
  components: { WeekCardSkeleton, DayCard },
  props: {
    week: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false
    },
    hideDays: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      weekDescriptionOpen: false
    };
  },
  computed: {
    loading() {
      return !!!this.week.id;
    },
    subtitle() {
      return `${this.week.child_count} Days`;
    }
  }
};
</script>

<style scoped>
.week-border {
  border: solid 1px #a0a1a1;
}
</style>
