import DayService from "../../services/api/day-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    day: null,
    days: [{}, {}]
  },
  mutations: {
    SET_DAY(state, day) {
      state.day = day;
    },
    SET_DAYS(state, days) {
      state.days = days;
    },
    SET_DAY_ROUNDS(state, rounds) {
      state.day.rounds = rounds;
    }
  },
  actions: {
    async fetchDay({ commit }, { programId, cycleId, dayId }) {
      const response = await DayService.getDay(programId, cycleId, dayId);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async fetchDayByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateDays({ commit, state }, query) {
      const response = await StaffService.getDays(query);
      const days = query.page > 1 ? [...state.days, ...response.data.data] : response.data.data;

      commit("SET_DAYS", days);
      return response.data;
    },
    async fetchTemplateDay({ commit }, id) {
      const response = await StaffService.getDay(id);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async createDay({ commit }, form) {
      const response = await StaffService.createDay(form);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async editDay({ commit }, { id, form }) {
      const response = await StaffService.updateDay(id, form);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async deleteDay({ commit, dispatch }, id) {
      const response = await StaffService.deleteDay(id);
      commit("SET_DAY", null);
      dispatch("fetchTemplateDays");
      return response.data.data;
    },
    async sortRounds({ commit, dispatch }, { parentId, childList }) {
      commit("SET_DAY_ROUNDS", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortDayRounds(parentId, childIds);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async associateRound({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addRoundToDay(parentId, childId, association);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async updateAssociationRound({ commit }, { parentId, childId, association }) {
      const response = await StaffService.updateDayRound(childId, association);
      commit("SET_DAY", response.data.data);
      return response.data.data;
    },
    async removeAssociationRound({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeDayRound(childId);
      dispatch("fetchTemplateDay", parentId);
      return response.data.data;
    }
  },
  getters: {
    parentInfo: state => state.day?.parent
  }
};
