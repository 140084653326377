import baseAxios from "../axios-service";

export default {
  getFaqs({
    perPage = null,
    filterQuestion = null,
    filterAnswer = null,
    filterPublished = null,
    sortQuestion = null,
    sortAnswer = null,
    sortPublished = null
  } = {}) {
    const query = {
      per_page: perPage,
      "filter[question]": filterQuestion,
      "filter[answer]": filterAnswer,
      "filter[published]": filterPublished,
      "sort[question]": sortQuestion,
      "sort[answer]": sortAnswer,
      "sort[published]": sortPublished
    };
    return baseAxios.get(`/faqs`, { params: query });
  }
};
