<template>
  <div>
    <v-alert type="info" dense prominent dismissible outlined>
      <h3 class="text-subtitle-1 font-weight-bold">
        Please ensure that all items have sub-items and that everything ends with an exercise.
      </h3>
      <h5 class="text-subtitle-2">
        This ensures that everything displays properly and that durations are calculated correctly.
      </h5>
    </v-alert>
    <v-row align="center">
      <v-col cols="auto">
        <v-select
          label="Type"
          :value="objectType"
          :items="objectTypes"
          outlined
          hide-details
          @change="updateType"
        />
      </v-col>
      <v-col cols="auto" md="5" lg="4" xl="3">
        <v-text-field
          label="Title"
          v-model.trim="titleSearch"
          placeholder="Search by title"
          append-icon="mdi-magnify"
          outlined
          hide-details
          @input="updateQuery"
        />
      </v-col>
      <v-col>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="auto">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small color="#3bb273" dark @click="updateModal(true)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <item-list
      :object-type="objectType"
      :query="objectQuery"
      show-time="duration"
      builder
      class="mt-3"
    />
    <workout-builder-modal
      :modal-open="modalOpen"
      :object-type="objectType"
      @set-modal="updateModal"
    ></workout-builder-modal>
  </div>
</template>

<script>
import WorkoutBuilderModal from "@/components/Modals/WorkoutBuilderModal";
import ItemList from "@/components/Lists/ItemList";

import { objectTypes } from "@/utils/Constants";

export default {
  name: "BuilderList",
  components: {
    ItemList,
    WorkoutBuilderModal
  },
  props: {
    objectType: {
      type: String,
      default: "programs"
    }
  },
  data: () => ({
    modalOpen: false,
    titleSearch: undefined,
    objectQuery: {
      filterName: undefined,
      sort: "name"
    },
    objectTypes: objectTypes
  }),
  methods: {
    updateQuery() {
      this.objectQuery.filterName = this.titleSearch;
    },
    updateModal(value) {
      this.modalOpen = value;
    },
    updateType(type) {
      this.$router.push({
        name: "Workout Builder",
        params: { objectType: type }
      });
      this.titleSearch = undefined;
    }
  }
};
</script>

<style scoped></style>
