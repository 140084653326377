import StaffService from "../../services/api/staff-service";

export default {
  namespaced: true,
  state: {
    // Sub Object Types
    programCategory: null,
    programCategories: [],
    bodyPosition: null,
    bodyPositions: [],
    gripType: null,
    gripTypes: [],
    bundles: []
  },
  mutations: {
    SET_PROGRAM_CATEGORY(state, category) {
      state.programCategory = category;
    },
    SET_PROGRAM_CATEGORIES(state, categories) {
      state.programCategories = categories;
    },
    SET_BODY_POSITION(state, bodyPosition) {
      state.bodyPosition = bodyPosition;
    },
    SET_BODY_POSITIONS(state, bodyPositions) {
      state.bodyPositions = bodyPositions;
    },
    SET_GRIP_TYPE(state, gripType) {
      state.gripType = gripType;
    },
    SET_GRIP_TYPES(state, gripTypes) {
      state.gripTypes = gripTypes;
    },
    SET_BUNDLES(state, bundles) {
      state.bundles = bundles;
    }
  },
  actions: {
    /** Membermouse Bundles **/
    async fetchBundles({ commit }) {
      const response = await StaffService.getBundles();
      commit("SET_BUNDLES", response.data.data);
      return response.data.data;
    },
    /** Program Categories **/
    async fetchProgramCategories({ commit }, query) {
      const response = await StaffService.getProgramCategories(query);
      commit("SET_PROGRAM_CATEGORIES", response.data.data);
      return response.data.data;
    },
    async fetchProgramCategory({ commit }, id) {
      const response = await StaffService.getProgramCategory(id);
      commit("SET_PROGRAM_CATEGORY", response.data.data);
      return response.data.data;
    },
    async createProgramCategory({ commit, dispatch }, form) {
      const response = await StaffService.createProgramCategory(form);
      commit("SET_PROGRAM_CATEGORY", response.data.data);
      dispatch("fetchProgramCategories");
      return response.data.data;
    },
    async editProgramCategory({ commit, dispatch }, { id, form }) {
      const response = await StaffService.updateProgramCategory(id, form);
      commit("SET_PROGRAM_CATEGORY", response.data.data);
      dispatch("fetchProgramCategories");
      return response.data.data;
    },
    async deleteProgramCategory({ commit, dispatch }, id) {
      const response = await StaffService.deleteProgramCategory(id);
      commit("SET_PROGRAM_CATEGORY", null);
      dispatch("fetchProgramCategories");
      return response.data.data;
    },
    /** Body Positions **/
    async fetchBodyPositions({ commit }, query) {
      const response = await StaffService.getBodyPositions(query);
      commit("SET_BODY_POSITIONS", response.data.data);
      return response.data.data;
    },
    async fetchBodyPosition({ commit }, id) {
      const response = await StaffService.getBodyPosition(id);
      commit("SET_BODY_POSITION", response.data.data);
      return response.data.data;
    },
    async createBodyPosition({ commit, dispatch }, form) {
      const response = await StaffService.createBodyPosition(form);
      commit("SET_BODY_POSITION", response.data.data);
      dispatch("fetchBodyPositions");
      return response.data.data;
    },
    async editBodyPosition({ commit, dispatch }, { id, form }) {
      const response = await StaffService.updateBodyPosition(id, form);
      commit("SET_BODY_POSITION", response.data.data);
      dispatch("fetchBodyPositions");
      return response.data.data;
    },
    async deleteBodyPosition({ commit, dispatch }, id) {
      const response = await StaffService.deleteBodyPosition(id);
      commit("SET_BODY_POSITION", null);
      dispatch("fetchBodyPositions");
      return response.data.data;
    },
    /** Grip Types **/
    async fetchGripTypes({ commit }, query) {
      const response = await StaffService.getGripTypes(query);
      commit("SET_GRIP_TYPES", response.data.data);
      return response.data.data;
    },
    async fetchGripType({ commit }, id) {
      const response = await StaffService.getGripType(id);
      commit("SET_GRIP_TYPE", response.data.data);
      return response.data.data;
    },
    async createGripType({ commit, dispatch }, form) {
      const response = await StaffService.createGripType(form);
      commit("SET_GRIP_TYPE", response.data.data);
      dispatch("fetchGripTypes");
      return response.data.data;
    },
    async editGripType({ commit, dispatch }, { id, form }) {
      const response = await StaffService.updateGripType(id, form);
      commit("SET_GRIP_TYPE", response.data.data);
      dispatch("fetchGripTypes");
      return response.data.data;
    },
    async deleteGripType({ commit, dispatch }, id) {
      const response = await StaffService.deleteGripType(id);
      commit("SET_GRIP_TYPE", null);
      dispatch("fetchGripTypes");
      return response.data.data;
    }
  },
  getters: {}
};
