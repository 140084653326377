<template>
  <v-chip small label class="px-2">
    <span v-show="prefix" class="mr-1">{{ prefix }}</span>
    <v-icon small class="mr-1">mdi-alarm</v-icon>
    {{ formattedTime }}
  </v-chip>
</template>

<script>
export default {
  name: "TimeBadge",
  props: {
    time: [Number, String],
    prefix: String
  },
  computed: {
    formattedTime() {
      const time = Number(this.time);
      if (time > 0) {
        const momentDuration = this.$moment.duration(time, "s");
        const hours = momentDuration.hours() || 0;
        const minutes = momentDuration.minutes() || 0;
        const seconds = momentDuration.seconds() || 0;
        let durationString = [
          hours ? `${hours} hr` : null,
          minutes ? `${minutes} m` : null,
          seconds ? `${seconds} s` : null
        ];
        return durationString.join(" ");
      }

      return "0 s";
    }
  }
};
</script>

<style scoped></style>
