<template>
  <v-form>
    <!-- Sets Table -->
    <v-simple-table class="mb-6">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <h3 class="subtitle-1 text-capitalize">Sets</h3>
            </th>
            <th class="text-left">
              <h3 class="subtitle-1 text-capitalize">Reps</h3>
            </th>
            <th class="text-left">
              <h3 class="subtitle-1 text-capitalize">Weight</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="set in form.sets" :key="set.set">
            <td>{{ set.set }}.</td>
            <td>
              <v-text-field
                v-model="set.reps"
                type="number"
                outlined
                dense
                hide-details
                class="py-3"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="set.weight"
                type="number"
                outlined
                dense
                hide-details
                class="py-3"
                :suffix="settings.weightUnit"
              ></v-text-field>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- Notes -->
    <v-textarea outlined hide-details label="Notes" rows="3" v-model="form.notes"></v-textarea>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import { mapGetters } from "vuex";

export default {
  name: "LiftingForm2",
  components: { ContinueButton },
  props: {
    sets: {
      type: Number,
      required: true
    },
    log: Object
  },
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.setForm();
  },
  computed: {
    ...mapGetters({ settings: "auth/settings" })
  },
  watch: {
    log: "setForm"
  },
  methods: {
    setForm() {
      this.form = {
        type: "lifting_2",
        sets: this.log?.sets || this.generateSets(),
        notes: this.log?.notes || null
      };
    },
    submit() {
      this.$emit("submit", this.form);
    },
    generateSets() {
      const sets = [];
      for (let i = 1; i <= this.sets; i++) {
        sets.push({
          set: i,
          reps: null,
          weight: null
        });
      }
      return sets;
    }
  }
};
</script>

<style scoped></style>
