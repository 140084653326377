<template>
  <past-programs-list v-if="tab === 'Past'" @programClicked="selectProgram" />
  <program-logbook v-else current-program />
</template>

<script>
import ProgramLogbook from "../components/Objects/ProgramLogbook";
import PastProgramsList from "../components/Lists/PastProgramsList";
import { mapGetters } from "vuex";

export default {
  name: "Logbook",
  metaInfo: {
    title: "Logbook"
  },
  components: {
    PastProgramsList,
    ProgramLogbook
  },
  computed: {
    tab() {
      return this.$route.query.tab || "Current";
    },
    ...mapGetters({ selectedProgramId: "auth/activeProgramId" })
  },
  methods: {
    selectProgram(id) {
      this.$router.push({ name: "Logbook - Past", params: { pastProgramId: id } });
    }
  }
};
</script>
