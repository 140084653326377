import ExerciseService from "../../services/api/exercise-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "../../services/api/shared-service";

export default {
  namespaced: true,
  state: {
    exercises: [{}, {}],
    exercise: null
  },
  mutations: {
    SET_EXERCISE(state, exercise) {
      state.exercise = exercise;
    },
    SET_EXERCISES(state, exercises) {
      state.exercises = exercises;
    }
  },
  actions: {
    async fetchExercise({ commit }, id) {
      const response = await ExerciseService.getExercise(id);
      commit("SET_EXERCISE", response.data.data);
      return response.data.data;
    },
    async fetchExerciseByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_EXERCISE", response.data.data);
      return response.data.data;
    },
    async completeExercise({ dispatch }, { programId, exerciseHash, form }) {
      await ExerciseService.completeExercise(programId, exerciseHash, form);
      return dispatch("fetchExerciseByHash", exerciseHash);
    },
    async restartExercise({ dispatch }, { programId, exerciseHash }) {
      await ExerciseService.resetExercise(programId, exerciseHash);
      return dispatch("fetchExerciseByHash", exerciseHash);
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateExercises({ commit, state }, query) {
      const response = await StaffService.getExercises(query);
      const exercises =
        query.page > 1 ? [...state.exercises, ...response.data.data] : response.data.data;

      commit("SET_EXERCISES", exercises);
      return response.data;
    },
    async fetchTemplateExercise({ commit }, id) {
      const response = await StaffService.getExercise(id);
      commit("SET_EXERCISE", response.data.data);
      return response.data.data;
    },
    async createExercise({ commit }, form) {
      const response = await StaffService.createExercise(form);
      commit("SET_EXERCISE", response.data.data);
      return response.data.data;
    },
    async editExercise({ commit }, { id, form }) {
      const response = await StaffService.updateExercise(id, form);
      commit("SET_EXERCISE", response.data.data);
      return response.data.data;
    },
    async deleteExercise({ commit, dispatch }, id) {
      const response = await StaffService.deleteExercise(id);
      commit("SET_EXERCISE", null);
      dispatch("fetchTemplateExercises");
      return response.data.data;
    }
  },
  getters: {
    parentInfo: state => state.exercise?.parent,
    nextExercise: state => {
      if (state.exercise?.next_exercise) {
        const nextExercise = state.exercise.next_exercise;
        const parentType = nextExercise.parent.type.toLowerCase();
        const parentHash = nextExercise.parent.hash_key;
        const nextHash = nextExercise.hash_key;
        const nextType = nextExercise.type.toLowerCase();
        let route;

        if (parentType === "round") {
          route = `${parentType}/${parentHash}/${nextType}-${nextHash}`;
        } else {
          route = `${parentType}/${parentHash}/${nextHash}`;
        }

        return { ...state.exercise.next_exercise, route };
      }
      return undefined;
    },
    prevExercise: state => {
      if (state.exercise?.previous_exercise) {
        const prevExercise = state.exercise.previous_exercise;
        const parentType = prevExercise.parent.type.toLowerCase();
        const parentHash = prevExercise.parent.hash_key;
        const prevHash = prevExercise.hash_key;
        const prevType = prevExercise.type.toLowerCase();
        let route;

        if (parentType === "round") {
          route = `${parentType}/${parentHash}/${prevType}-${prevHash}`;
        } else {
          route = `${parentType}/${parentHash}/${prevHash}`;
        }

        return { ...state.exercise.previous_exercise, route };
      }
      return undefined;
    }
  }
};
