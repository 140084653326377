<template>
  <v-navigation-drawer
    app
    mobile-breakpoint="sm"
    mini-variant
    mini-variant-width="96"
    class="elevation-3"
    style="min-height: 728px"
    perminent
  >
    <v-list class="pt-0">
      <!-- Hard code the height to match the header height -->
      <v-list-item style="background-color: #29304E; height: 87px;">
        <img
          src="https://ik.imagekit.io/illuminationfoundry/training-beta/logos/logo-only-white_KqGl9r1Dy.svg"
          alt="logo"
        />
      </v-list-item>
      <v-list-item
        v-for="item in firstList"
        :key="item.text"
        :to="{ name: item.linkTo, query: item.query }"
        active-class="active-link"
        class="py-2"
      >
        <v-list-item-icon>
          <div class="text-center">
            <v-icon v-text="item.icon"></v-icon>
            <br />
            <div style="font-size: x-small">{{ item.text }}</div>
          </div>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list style="position: absolute; bottom:0; width: 100%">
      <v-divider></v-divider>
      <v-list-item
        v-for="item in secondList"
        :key="item.text"
        :to="{ name: item.linkTo }"
        active-class="active-link"
        class="py-2"
        :disabled="item.disabled"
      >
        <v-list-item-icon>
          <div class="text-center">
            <v-icon>
              {{ item.icon }}
            </v-icon>
            <br />
            <div style="font-size: x-small">{{ item.text }}</div>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="mb-4 mt-5" @click="$emit('setSideMenu', true)">
        <v-list-item-avatar color="#3d70b2" size="50">
          <h3 style="color: #ebf0f7">{{ userInitials }}</h3>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideNavBar",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstList: [
        {
          icon: "mdi-book-variant-multiple",
          text: "My Program",
          linkTo: "My Program"
        },
        {
          icon: "mdi-poll",
          text: "Logbook",
          linkTo: "Logbook",
          query: { tab: "Current" }
        }
      ],
      secondList: [
        {
          icon: "mdi-text-box-multiple",
          text: "All Programs",
          linkTo: "Training Programs"
        },
        {
          icon: "mdi-help-circle",
          text: "FAQ",
          linkTo: "FAQs"
        }
      ]
    };
  },
  computed: {
    userInitials() {
      if (this.user.name) {
        return this.user.name
          .split(" ")
          .map(item => {
            return item[0];
          })
          .join("");
      }
      return "";
    }
  }
};
</script>

<style scoped>
.active-link {
  color: #2473d5 !important;
  font-weight: bold;
  background-color: #e9f1fb;
}
.active-link:after {
  content: "";
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  border-right: #2473d5 solid 4px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
