<template>
  <v-form class="mt-3">
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="nameErrors"
      v-model.trim="$v.form.name.$model"
    ></v-text-field>
    <vue-trix
      v-model="$v.form.description.$model"
      :class="descriptionErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Description"
    ></vue-trix>
    <trix-error-messages
      v-if="descriptionErrors.length > 0"
      :error-messages="descriptionErrors"
    ></trix-error-messages>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import TrixErrorMessages from "../FormComponents/TrixErrorMessages";
import { required } from "vuelidate/lib/validators";

export default {
  name: "DefaultObjectForm",
  components: { TrixErrorMessages, ContinueButton },
  props: {
    object: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        description: null
      }
    };
  },
  validations: {
    form: {
      name: { required },
      description: { required }
    }
  },
  mounted() {
    if (this.object) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    object: function() {
      if (this.object) {
        this.setForm();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required && errors.push("Description is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.object.name,
        description: this.object.description
      };
    },
    resetForm() {
      this.form = {
        name: null,
        description: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
