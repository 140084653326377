<template>
  <v-row>
    <v-col v-for="program in programs" :key="program.id" cols="12" sm="6" lg="4" xl="3">
      <program-card
        :program="program"
        :isBookmarked="!builder ? program.id === selectedProgramId : false"
        @click="$emit('programClicked', program.id)"
      ></program-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProgramCard from "../Cards/ProgramCard";
import store from "../../store";

export default {
  name: "ProgramsList",
  components: { ProgramCard },
  props: {
    builder: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    loading: true
  }),
  mounted() {
    this.fetchPrograms();
  },
  watch: {
    query: "fetchPrograms"
  },
  computed: {
    ...mapState({ programs: state => state.program.programs }),
    ...mapGetters({ selectedProgramId: "auth/activeProgramId" })
  },
  methods: {
    fetchPrograms() {
      if (this.builder) {
        store.dispatch("program/fetchTemplatePrograms", this.query).then(() => {
          this.loading = false;
        });
      } else {
        store.dispatch("program/fetchPrograms", this.query).then(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
