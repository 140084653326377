<template>
  <v-form class="mt-3">
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="nameErrors"
      v-model.trim="$v.form.name.$model"
    ></v-text-field>
    <vue-trix v-model="form.description" placeholder="Description"></vue-trix>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../../Buttons/ContinueButton";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ProgramCategoryForm",
  components: { ContinueButton },
  props: {
    programCategory: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {}
    };
  },
  validations: {
    form: {
      name: { required }
    }
  },
  mounted() {
    if (this.programCategory) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    programCategory: function() {
      if (this.programCategory) {
        this.setForm();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.programCategory.name,
        description: this.programCategory.description
      };
    },
    resetForm() {
      this.form = {
        name: null,
        description: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitSubForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
