<template>
  <v-app style="overflow-x: hidden">
    <side-nav-bar
      v-if="$vuetify.breakpoint.mdAndUp"
      :user="user"
      @setSideMenu="updateSideMenu"
    ></side-nav-bar>

    <top-nav-bar @menuClick="sideMenuOpen = true"></top-nav-bar>

    <side-menu :sideMenuOpen="sideMenuOpen" :user="user" @setSideMenu="updateSideMenu"></side-menu>

    <v-main class="bg-grey">
      <v-container fluid class="pa-2 pa-md-4 px-lg-6">
        <router-view />
      </v-container>
    </v-main>

    <intro-video-modal v-model="introVideoOpen" />

    <bottom-nav-bar v-if="$vuetify.breakpoint.smAndDown"></bottom-nav-bar>
  </v-app>
</template>

<script>
import SideNavBar from "../components/Navigation/SideNavBar.vue";
import TopNavBar from "../components/Navigation/TopNavBar.vue";
import BottomNavBar from "../components/Navigation/BottomNavBar.vue";
import SideMenu from "../components/Navigation/SideMenu.vue";
import { mapGetters, mapState } from "vuex";
import IntroVideoModal from "@/components/Modals/IntroVideoModal";

export default {
  name: "DefaultLayout",
  components: {
    IntroVideoModal,
    BottomNavBar,
    TopNavBar,
    SideNavBar,
    SideMenu
  },
  data: () => ({
    introVideoOpen: false,
    sideMenuOpen: false
  }),
  mounted() {
    this.introVideoOpen = this.settings.showIntroVideo;
  },
  computed: {
    ...mapState({ user: state => state.auth.user }),
    ...mapGetters({ settings: "auth/settings" })
  },
  methods: {
    updateSideMenu(value) {
      this.sideMenuOpen = value;
    }
  }
};
</script>

<style scoped></style>
