<template>
  <v-card v-if="loading">
    <v-card-text>
      <exercise-skeleton />
    </v-card-text>
  </v-card>
  <div v-else>
    <exercise
      :hide-title="hideTitle"
      :activeDay="myProgram"
      :exercise="exercise"
      :button-loading="buttonLoading"
      @completeExercise="completeExercise"
      @restartExercise="restartExercise"
    />
    <exercise-nav
      id="exerciseNav"
      :highlight-next="highlightNext"
      @next-clicked="highlightNext = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import NProgress from "nprogress";
import Superset from "../../../components/Objects/Superset";
import SupersetSkeleton from "../../../components/SkeletonLoaders/Objects/SupersetSkeleton";
import Exercise from "@/components/Objects/Exercise";
import ExerciseSkeleton from "@/components/SkeletonLoaders/Objects/ExerciseSkeleton";
import ExerciseNav from "@/components/Navigation/ExerciseNav";

export default {
  name: "ExerciseSubPage",
  metaInfo: {
    title: "View Exercise"
  },
  props: {
    exerciseHash: String,
    hideTitle: {
      type: Boolean,
      default: false
    },
    myProgram: {
      type: Boolean,
      default: false
    }
  },
  components: { ExerciseNav, ExerciseSkeleton, Exercise },
  data() {
    return {
      loading: true,
      fab: false,
      buttonLoading: false,
      highlightNext: false
    };
  },
  mounted() {
    this.fetchExercise();
  },
  computed: {
    ...mapState({ exercise: state => state.exercise.exercise }),
    ...mapGetters({ selectedProgramId: "auth/activeProgramId" })
  },
  watch: {
    exerciseHash: "fetchExercise"
  },
  methods: {
    async fetchExercise() {
      this.loading = true;
      await this.$store.dispatch("exercise/fetchExerciseByHash", this.exerciseHash);
      this.loading = false;
    },
    async completeExercise({ exerciseHash, form }) {
      this.buttonLoading = true;

      try {
        await this.$store.dispatch("exercise/completeExercise", {
          programId: this.selectedProgramId,
          exerciseHash: exerciseHash,
          form: form
        });
        this.highlightNext = true;
        await this.$vuetify.goTo("#exerciseNav");
        this.$emit("exercise-changed");
      } catch (error) {
        await this.$swal({
          icon: "error",
          title: "Error",
          text: error.message
        });
      }

      this.buttonLoading = false;
    },
    async restartExercise(exerciseHash) {
      const answer = await this.$swal({
        title: "Restart this Exercise?",
        text: "Any data you've entered for this exercise will be lost.",
        showCancelButton: true,
        confirmButtonColor: "#e15554",
        confirmButtonText: "Restart!"
      });
      if (answer.value) {
        try {
          await this.$store.dispatch("exercise/restartExercise", {
            programId: this.selectedProgramId,
            exerciseHash: exerciseHash
          });
          this.$emit("exercise-changed");
        } catch (error) {
          await this.$swal.fire({
            title: "Error!",
            text: error,
            icon: "error"
          });
        }
      }
    }
  }
};
</script>
