<template>
  <v-container class="text-center" fill-height :style="centerHeight">
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">Oh No!</h1>

        <p>We are encountering server errors at this time, please check back later.</p>

        <v-btn :to="{ name: 'My Program' }" color="primary" outlined rounded>
          Get me out of here!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo: {
    title: "Error"
  },
  computed: {
    centerHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          height: "calc(100vh - 87px)"
        };
      }
      return {
        height: "calc(100vh - 167px)"
      };
    }
  }
};
</script>

<style scoped></style>
