import baseAxios from "../axios-service";

export default {
  getExercise(id) {
    return baseAxios.get(`/exercises/${id}`);
  },
  resetExercise(programId, exerciseHash) {
    return baseAxios.post(`logbook/program/${programId}/${exerciseHash}/reset`);
  },
  completeExercise(programId, exerciseHash, form) {
    return baseAxios.post(`logbook/program/${programId}/${exerciseHash}/complete`, form);
  }
};
