<template>
  <exercise-card-skeleton v-if="loading" />
  <v-card
    v-else
    :color="styles.cardColor"
    :elevation="styles.cardElevation"
    :class="{ 'complete-border': completed }"
    @click="$emit('click', exercise.id)"
  >
    <v-list-item class="pa-3" justify="center">
      <v-list-item-avatar v-if="!builder" :color="styles.circleOutlineColor" size="64">
        <v-avatar :color="styles.circleInsideColor" size="56">
          <h2 :style="{ color: styles.circleTextColor }">{{ exercise.sort_order }}</h2>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-avatar v-if="builder" size="64">
        <picture-icon :round-style="exercise.style" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h6">{{ exercise.name }}</v-list-item-title>
        <v-list-item-subtitle class="text-wrap">{{ subtitle }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="align-self-start">
        <v-avatar v-if="!builder && !editable" :color="styles.checkCircleColor" size="24">
          <v-icon dark small :color="styles.checkColor">mdi-check</v-icon>
        </v-avatar>
        <template v-if="editable">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('edit')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Association</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('view')">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sub-object</span>
          </v-tooltip>
        </template>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import PictureIcon from "../Avatars/IconAvatar";
import ExerciseCardSkeleton from "@/components/SkeletonLoaders/Cards/ExerciseCardSkeleton";

export default {
  name: "ExerciseCard",
  components: {
    ExerciseCardSkeleton,
    PictureIcon
  },
  props: {
    exercise: {
      type: Object,
      required: true
    },
    builder: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return !!!this.exercise.id;
    },
    subtitle() {
      // Always show Superset Subtitle
      if (this.exercise.type === "Super Set") {
        return `Superset - ${this.exercise.child_count} Exercises`;
      }
      // Otherwise only show if not in builder mode
      if (!this.builder) {
        if (this.exercise.to_failure) {
          return `${this.exercise.sets} Sets to Failure`;
        }
        return `${this.exercise.sets} Sets x ${this.exercise.reps} Reps`;
      }
      return null;
    },
    completed() {
      return this.exercise.logbook !== undefined ? this.exercise.logbook.completion_status : null;
    },
    styles() {
      if (this.completed) {
        return {
          cardColor: "#f4f5f5",
          cardElevation: 0,
          circleOutlineColor: "white",
          circleInsideColor: "#f4f5f5",
          circleTextColor: "black",
          checkCircleColor: "primary",
          checkColor: "white"
        };
      }
      return {
        cardColor: "white",
        cardElevation: 3,
        circleOutlineColor: "#E7F0F6",
        circleInsideColor: "white",
        circleTextColor: "#4CABE9",
        checkCircleColor: "#EAEBEC",
        checkColor: "#AAADB2"
      };
    }
  }
};
</script>

<style scoped>
.complete-border {
  border: 4px solid white !important;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
</style>
