<template>
  <exercise-card :exercise="superset" :builder="builder" />
</template>

<script>
import ExerciseCard from "@/components/Cards/ExerciseCard";

export default {
  name: "SupersetCard",
  components: { ExerciseCard },
  props: {
    superset: Object,
    builder: Boolean
  }
};
</script>

<style scoped></style>
