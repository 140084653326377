import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import faq from "./modules/faq";
import program from "./modules/program";
import cycle from "./modules/cycle";
import week from "./modules/week";
import day from "./modules/day";
import round from "./modules/round";
import superset from "./modules/superset";
import exercise from "./modules/exercise";
import logbook from "./modules/logbook";
import builder from "./modules/builder";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    faq,
    logbook,
    program,
    cycle,
    week,
    day,
    round,
    superset,
    exercise,
    builder
  }
});
