<template>
  <program-logbook :program-id="pastProgramId" />
</template>

<script>
import ProgramLogbook from "../components/Objects/ProgramLogbook";
import PastProgramsList from "../components/Lists/PastProgramsList";

export default {
  name: "LogbookPast",
  metaInfo: {
    title: "Past Logbook"
  },
  components: { ProgramLogbook },
  props: {
    pastProgramId: {
      type: String,
      required: true
    }
  }
};
</script>
