<template>
  <v-chip small label dark class="px-3 py-2" color="#3bb273">
    <span v-show="prefix" class="mr-1">{{ prefix }}</span>
    <v-icon small class="mx-1">mdi-alarm-snooze</v-icon>
    {{ formattedTime }}
  </v-chip>
</template>

<script>
export default {
  name: "RestTimeBadge",
  props: {
    time: {
      type: [Number, String]
    },
    prefix: String
  },
  computed: {
    formattedTime() {
      const time = Number(this.time);
      const momentDuration = this.$moment.duration(time, "s");
      const hours = momentDuration.hours();
      const minutes = momentDuration.minutes();
      const seconds = momentDuration.seconds();
      let durationString = [
        hours ? `${hours} hr` : null,
        minutes ? `${minutes} m` : null,
        seconds ? `${seconds} s` : null
      ];

      return durationString.join(" ");
    }
  }
};
</script>

<style scoped></style>
