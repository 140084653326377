<template>
  <v-row justify="center" justify-md="space-between" justify-xl="center">
    <v-col cols="12" md="8" lg="7" xl="5">
      <v-form class="mb-10">
        <v-text-field
          v-model="searchValue"
          type="text"
          outlined
          rounded
          label="Search"
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-form>
      <v-expansion-panels flat tile accordion multiple class="transparent-expansion">
        <v-expansion-panel v-for="(faq, index) in filteredFaqs" :key="index">
          <v-expansion-panel-header color="#f4f5f5" class="text-grey font-weight-bold">
            {{ faq.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-2">
            <div class="trix-content text-body-2" v-html="faq.answer"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" sm="10" md="4" xl="3">
      <v-card>
        <v-card-text>
          <div class="trix-content text-body-2">
            <div class="text-subtitle-2 font-weight-bold">
              Your coach Matt Pincus is available to help! Each month he'll offer the following:
            </div>
            <v-divider class="my-2" />
            <div class="font-weight-bold">Office Hours - Every 3rd Monday of the Month</div>
            <div class="text--secondary">(9-11am Mountain Time)</div>
            <p class="mt-3">
              Office Hours will be 15-minute zoom calls with Matt. During those calls, you can
              discuss any questions you have about the program, any alterations you might want to
              make to it, and how to optimize it for your personal goals.
            </p>
            <v-btn
              color="primary"
              small
              rounded
              href="https://calendly.com/pincus/office-hours"
              target="_blank"
              class="mt-6"
              >Schedule Office Hours
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "faq",
  metaInfo: {
    title: "FAQs"
  },
  data() {
    return {
      loading: true,
      searchValue: ""
    };
  },
  mounted() {
    this.fetchFAQs();
  },
  computed: {
    filteredFaqs() {
      if (this.searchValue === "") {
        return this.faqs;
      }
      return this.faqs.filter(faq => {
        return Object.keys(faq).some(key => {
          return faq[key].toLowerCase().includes(this.searchValue.toLowerCase());
        });
      });
    },
    ...mapState({ faqs: state => state.faq.faqs })
  },
  methods: {
    async fetchFAQs() {
      try {
        await this.$store.dispatch("faq/fetchFaqs");
        this.loading = false;
      } catch (error) {
        await this.$router.push({ name: "All Programs" });
      }
    }
  }
};
</script>
