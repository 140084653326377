<template>
  <v-form>
    <!-- Notes -->
    <v-textarea outlined hide-details label="Notes" rows="3" v-model="form.notes"></v-textarea>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";

export default {
  name: "ClimbingForm2",
  components: { ContinueButton },
  props: {
    log: Object
  },
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.setForm();
  },
  watch: {
    log: "setForm"
  },
  methods: {
    setForm() {
      this.form = {
        type: "climbing_2",
        notes: this.log?.notes || null
      };
    },
    submit() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style scoped></style>
