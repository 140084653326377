import axios from "axios";
import router from "@/router";
import NProgress from "nprogress";

const baseAxios = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
});
baseAxios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
baseAxios.defaults.headers.common["Request-URL"] = window.location.href;

baseAxios.interceptors.response.use(
  response => response,
  async error => {
    NProgress.done();
    if (error.response && error.response.status === 401) {
      await router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

export default baseAxios;
