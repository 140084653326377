<template>
  <div>
    <v-dialog v-model="open" max-width="800">
      <v-card>
        <v-card-title class="headline pt-5">
          {{ title }}
          <v-tooltip v-if="object" left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small absolute right color="#e15554" v-on="on" @click="deleteObject">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <component
            :key="formKey"
            :is="formType"
            :program="object"
            :cycle="object"
            :week="object"
            :day="object"
            :round="object"
            :superset="object"
            :exercise="object"
            @submitForm="submitForm"
            @openSubModal="openSubModal"
          ></component>
        </v-card-text>
      </v-card>
    </v-dialog>
    <WorkoutBuilderSubModal
      :modalOpen="subModalOpen"
      :object-type="subObjectType"
      :object="subObject"
      @setSubModal="updateSubModal"
    ></WorkoutBuilderSubModal>
  </div>
</template>

<script>
import ProgramForm from "../Forms/WorkoutBuilder/ProgramForm";
import CycleForm from "../Forms/WorkoutBuilder/CycleForm";
import WeekForm from "../Forms/WorkoutBuilder/WeekForm";
import DayForm from "../Forms/WorkoutBuilder/DayForm";
import RoundForm from "../Forms/WorkoutBuilder/RoundForm";
import SupersetForm from "../Forms/WorkoutBuilder/SupersetForm";
import ExerciseForm from "../Forms/WorkoutBuilder/ExerciseForm";
import DefaultObjectForm from "../Forms/WorkoutBuilder/DefaultObjectForm";
import WorkoutBuilderSubModal from "./WorkoutBuilderSubModal";

export default {
  name: "WorkoutBuilderModal",
  components: {
    WorkoutBuilderSubModal,
    ProgramForm,
    CycleForm,
    WeekForm,
    RoundForm,
    SupersetForm,
    DayForm,
    ExerciseForm,
    DefaultObjectForm
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: true
    },
    objectType: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      subModalOpen: false,
      subObjectType: "",
      subObject: null
    };
  },
  computed: {
    open: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("setModal", value);
      }
    },
    formKey() {
      if (this.object) {
        return this.object.id;
      }
      return this.generateFakeUUID();
    },
    formattedType() {
      return (
        this.objectType[0].toUpperCase() + this.objectType.substr(1, this.objectType.length - 2)
      );
    },
    title() {
      if (this.object) {
        return `Edit ${this.formattedType}`;
      }
      return `Create a New ${this.formattedType}`;
    },
    formType() {
      switch (this.objectType) {
        case "programs":
          return "ProgramForm";
        case "cycles":
          return "CycleForm";
        case "weeks":
          return "WeekForm";
        case "days":
          return "DayForm";
        case "rounds":
          return "RoundForm";
        case "supersets":
          return "SupersetForm";
        case "exercises":
          return "ExerciseForm";
        default:
          return "DefaultObjectForm";
      }
    }
  },
  methods: {
    // Generate fake uuid to force rerender of form
    generateFakeUUID() {
      return (
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
      );
    },
    updateSubModal(value) {
      this.subModalOpen = value;
    },
    async openSubModal({ action, type, objectId }) {
      const objectType = type.split(" ").join("");
      this.subObjectType = type;
      if (objectId) {
        const action = `builder/fetch${objectType}`;
        this.subObject = await this.$store.dispatch(action, objectId);
      } else {
        this.subObject = null;
      }
      this.updateSubModal(true);
    },
    async submitForm(form) {
      if (this.object) {
        this.updateObject(form);
      } else {
        this.createObject(form);
      }
    },
    async createObject(form) {
      const singularType = this.objectType.substr(0, this.objectType.length - 1);
      try {
        const object = await this.$store.dispatch(
          `${singularType}/create${this.formattedType}`,
          form
        );
        await this.$router.push({
          name: "Workout Builder - View",
          params: { objectType: this.objectType, id: object.id }
        });
        this.open = false;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: `Error`,
          text: error
        });
      }
    },
    async updateObject(form) {
      const singularType = this.objectType.substr(0, this.objectType.length - 1);
      try {
        await this.$store.dispatch(`${singularType}/edit${this.formattedType}`, {
          id: this.object.id,
          form: form
        });
        this.open = false;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: `Error`,
          text: error
        });
      }
    },
    async deleteObject() {
      if (this.object.is_deleteable) {
        let result = await this.$swal({
          title: `Delete this ${this.formattedType}?`,
          text: "You cannot undo this.",
          showCancelButton: true,
          confirmButtonColor: "#e15554",
          confirmButtonText: "Delete"
        });

        if (result.value) {
          const singularType = this.objectType.substr(0, this.objectType.length - 1);
          try {
            await this.$store.dispatch(
              `${singularType}/delete${this.formattedType}`,
              this.object.id
            );
            this.open = false;
            await this.$router.push({ name: "Workout Builder", query: { type: this.objectType } });
          } catch (error) {
            this.$swal({
              icon: "error",
              title: `Error`,
              text: error
            });
          }
        }
      } else {
        const errorMessages = this.object.deleteable_messages.map(message => `<li>${message}</li>`);

        await this.$swal({
          icon: "error",
          title: `You Cannot Delete this ${this.formattedType}`,
          html: errorMessages.join("")
        });
      }
    }
  }
};
</script>

<style scoped></style>
