<template>
  <v-row id="navigation" align="center" class="mt-2">
    <v-col cols="auto">
      <v-btn v-if="showPrev" text large rounded class="text-none" @click="goToPrev">
        <v-avatar left color="#3d70b2" class="mr-4" size="35">
          <v-icon color="white" small>mdi-arrow-left</v-icon>
        </v-avatar>
        Previous
      </v-btn>
    </v-col>
    <v-col>
      <v-divider></v-divider>
    </v-col>
    <v-col cols="auto">
      <v-btn v-if="showNext" text large rounded class="text-none" @click="goToNext">
        Next
        <v-avatar left color="#3d70b2" class="ml-4" size="35">
          <v-icon color="white" small>mdi-arrow-right</v-icon>
        </v-avatar>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SupersetNav",
  computed: {
    routeTree() {
      return this.$route.name.includes("My Program") ? "my-program" : "programs";
    },
    showNext() {
      return !!this.nextSibling;
    },
    showPrev() {
      return !!this.prevSibling;
    },
    ...mapGetters({
      nextSibling: "superset/nextSibling",
      prevSibling: "superset/prevSibling"
    })
  },
  methods: {
    goToPrev() {
      this.$router.push(`/app/${this.routeTree}/${this.prevSibling.route}`);
    },
    goToNext() {
      this.$router.push(`/app/${this.routeTree}/${this.nextSibling.route}`);
    }
  }
};
</script>

<style scoped></style>
