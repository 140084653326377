<template>
  <v-form class="mt-3">
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="titleErrors"
      v-model.trim="$v.form.name.$model"
    ></v-text-field>
    <v-text-field
      label="Image URL"
      outlined
      type="text"
      :error-messages="imageErrors"
      v-model.trim="$v.form.image_url.$model"
    ></v-text-field>
    <vue-trix
      v-model="$v.form.description.$model"
      :class="descriptionErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Description"
    ></vue-trix>
    <trix-error-messages :error-messages="descriptionErrors"></trix-error-messages>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../../Buttons/ContinueButton";
import TrixErrorMessages from "../../FormComponents/TrixErrorMessages";
import { required } from "vuelidate/lib/validators";

export default {
  name: "GripTypeForm",
  components: { TrixErrorMessages, ContinueButton },
  props: {
    gripType: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {}
    };
  },
  validations: {
    form: {
      name: { required },
      image_url: { required },
      description: { required }
    }
  },
  mounted() {
    if (this.gripType) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    gripType: function() {
      if (this.gripType) {
        this.setForm();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    imageErrors() {
      const errors = [];
      if (!this.$v.form.image_url.$dirty) return errors;
      !this.$v.form.image_url.required && errors.push("Image URL is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required && errors.push("Description is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.gripType.name,
        image_url: this.gripType.image_url,
        description: this.gripType.description
      };
    },
    resetForm() {
      this.form = {
        name: null,
        image_url: null,
        description: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitSubForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
