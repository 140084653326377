<template>
  <day-skeleton v-if="loading" />
  <day v-else :day="day" @roundClicked="selectRound" />
</template>

<script>
import { mapState } from "vuex";
import Day from "../../components/Objects/Day";
import DaySkeleton from "../../components/SkeletonLoaders/Objects/DaySkeleton";

export default {
  name: "DayPage",
  metaInfo: {
    title: "View Day"
  },
  components: {
    DaySkeleton,
    Day
  },
  props: {
    dayHash: String,
    myProgram: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.fetchDay();
  },
  computed: {
    ...mapState({ day: state => state.day.day })
  },
  methods: {
    async fetchDay() {
      try {
        await this.$store.dispatch("day/fetchDayByHash", this.dayHash);
        this.loading = false;
      } catch (error) {
        if (error.response.status === 500) {
          await this.$router.push({ name: "App 500" });
        } else {
          await this.$router.push({ name: "App 404" });
        }
      }
    },
    async selectRound({ roundHash, itemType, itemHash }) {
      const routeName = this.myProgram ? "My Program - Round" : "Round";
      await this.$router.push({
        name: routeName,
        params: {
          roundHash,
          childType: itemType,
          childHash: itemHash
        }
      });
    }
  }
};
</script>
