<template>
  <program-skeleton v-if="loading"></program-skeleton>
  <program v-else :program="program" @dayClicked="routeToDay">
    <!-- Action Menu -->
    <template v-slot:menu>
      <program-menu :program-id="program.id" :active="isActiveProgram" />
    </template>
  </program>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Program from "@/components/Objects/Program";
import ProgramSkeleton from "@/components/SkeletonLoaders/Objects/ProgramSkeleton";
import ProgramMenu from "@/components/Menus/ProgramMenu";

export default {
  name: "ProgramPage",
  metaInfo: {
    title: "View Program"
  },
  components: { ProgramMenu, ProgramSkeleton, Program },
  props: {
    myProgram: { type: Boolean, default: false },
    programId: String
  },
  data: () => ({
    loading: true
  }),
  created() {
    this.fetchProgram();
  },
  computed: {
    isActiveProgram() {
      return this.selectedProgramId === this.program.id;
    },
    ...mapState({ program: state => state.program.program }),
    ...mapGetters({ selectedProgramId: "auth/activeProgramId" })
  },
  methods: {
    async fetchProgram() {
      const programId = this.myProgram ? undefined : this.programId;
      const action = this.myProgram ? "program/fetchMyProgram" : "program/fetchProgram";
      try {
        await this.$store.dispatch(action, programId);
        this.loading = false;
      } catch (error) {
        if (error.response.status === 500) await this.$router.push({ name: "App 500" });
        else await this.$router.push({ name: "App 404" });
      }
    },
    routeToDay({ cycleId, dayId, dayHash }) {
      if (this.myProgram) {
        this.$router.push({ name: "My Program - Day", params: { dayHash: dayHash } });
      } else {
        this.$router.push({
          name: "Day",
          params: { dayHash: dayHash }
        });
      }
    }
  }
};
</script>
