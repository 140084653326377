import baseAxios from "../axios-service";

/** Staff Role Protected Routes **/

export default {
  /** Program **/
  getPrograms({
    filterName = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/programs`, { params: query });
  },
  createProgram(program) {
    return baseAxios.post(`/staff/programs`, program);
  },
  getProgram(programId) {
    return baseAxios.get(`/staff/programs/${programId}`);
  },
  updateProgram(programId, program) {
    return baseAxios.put(`/staff/programs/${programId}`, program);
  },
  deleteProgram(programId) {
    return baseAxios.delete(`/staff/programs/${programId}`);
  },
  sortProgramCycles(programId, cycleIds) {
    return baseAxios.post(`/staff/programs/${programId}/sort-cycles`, cycleIds);
  },
  addCycleToProgram(programId, cycleId, association) {
    return baseAxios.post(`/staff/programs/${programId}/associate-cycle/${cycleId}`, association);
  },
  updateProgramCycle(cycleId, association) {
    return baseAxios.put(`/staff/programs/cycles/${cycleId}`, association);
  },
  removeProgramCycle(cycleId) {
    return baseAxios.delete(`/staff/programs/cycles/${cycleId}`);
  },

  /** MemberMouse Bundles **/
  getBundles() {
    return baseAxios.get(`/staff/mm/bundles`);
  },

  /** Program Categories **/
  getProgramCategories() {
    return baseAxios.get(`/staff/programs/categories`);
  },
  createProgramCategory(category) {
    return baseAxios.post(`/staff/programs/categories`, category);
  },
  getProgramCategory(categoryId) {
    return baseAxios.get(`/staff/programs/categories/${categoryId}`);
  },
  updateProgramCategory(categoryId, category) {
    return baseAxios.put(`/staff/programs/categories/${categoryId}`, category);
  },
  deleteProgramCategory(categoryId) {
    return baseAxios.delete(`/staff/programs/categories/${categoryId}`);
  },

  /** Cycles **/
  getCycles({
    filterName = undefined,
    filterIcon = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterRecommended = undefined,
    depth = 0,
    sort = undefined,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[icon_type]": filterIcon,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/cycles`, { params: query });
  },
  createCycle(cycle) {
    return baseAxios.post(`/staff/cycles`, cycle);
  },
  getCycle(cycleId) {
    return baseAxios.get(`/staff/cycles/${cycleId}`);
  },
  updateCycle(cycleId, cycle) {
    return baseAxios.put(`/staff/cycles/${cycleId}`, cycle);
  },
  deleteCycle(cycleId) {
    return baseAxios.delete(`/staff/cycles/${cycleId}`);
  },
  sortCycleDaysAndWeeks(cycleId, childIds) {
    return baseAxios.post(`/staff/cycles/${cycleId}/sort-days-and-weeks`, childIds);
  },
  addWeekToCycle(cycleId, weekId, association) {
    return baseAxios.post(`/staff/cycles/${cycleId}/associate-week/${weekId}`, association);
  },
  updateCycleWeek(weekId, association) {
    return baseAxios.put(`/staff/cycles/weeks/${weekId}`, association);
  },
  removeCycleWeek(weekId) {
    return baseAxios.delete(`/staff/cycles/weeks/${weekId}`);
  },
  addDayToCycle(cycleId, dayId, association) {
    return baseAxios.post(`/staff/cycles/${cycleId}/associate-day/${dayId}`, association);
  },
  updateCycleDay(dayId, association) {
    return baseAxios.put(`/staff/cycles/days/${dayId}`, association);
  },
  removeCycleDay(dayId) {
    return baseAxios.delete(`/staff/cycles/days/${dayId}`);
  },

  /** Weeks **/
  getWeeks({
    filterName = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/weeks`, { params: query });
  },
  createWeek(week) {
    return baseAxios.post(`/staff/weeks`, week);
  },
  getWeek(weekId) {
    return baseAxios.get(`/staff/weeks/${weekId}`);
  },
  updateWeek(weekId, week) {
    return baseAxios.put(`/staff/weeks/${weekId}`, week);
  },
  deleteWeek(weekId) {
    return baseAxios.delete(`/staff/weeks/${weekId}`);
  },
  sortWeekDays(weekId, dayIds) {
    return baseAxios.post(`/staff/weeks/${weekId}/sort-days`, dayIds);
  },
  addDayToWeek(weekId, dayId, association) {
    return baseAxios.post(`/staff/weeks/${weekId}/associate-day/${dayId}`, association);
  },
  updateWeekDay(dayId, association) {
    return baseAxios.put(`/staff/weeks/days/${dayId}`, association);
  },
  removeWeekDay(dayId) {
    return baseAxios.delete(`/staff/weeks/days/${dayId}`);
  },

  /** Days **/
  getDays({
    filterName = undefined,
    filterIcon = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterSubtitle = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[icon_type]": filterIcon,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[subtitle]": filterSubtitle,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/days`, { params: query });
  },
  createDay(day) {
    return baseAxios.post(`/staff/days`, day);
  },
  getDay(dayId) {
    return baseAxios.get(`/staff/days/${dayId}`);
  },
  updateDay(dayId, day) {
    return baseAxios.put(`/staff/days/${dayId}`, day);
  },
  deleteDay(dayId) {
    return baseAxios.delete(`/staff/days/${dayId}`);
  },
  sortDayRounds(dayId, roundIds) {
    return baseAxios.post(`/staff/days/${dayId}/sort-rounds`, roundIds);
  },
  addRoundToDay(dayId, roundId, association) {
    return baseAxios.post(`/staff/days/${dayId}/associate-round/${roundId}`, association);
  },
  updateDayRound(roundId, association) {
    return baseAxios.put(`/staff/days/rounds/${roundId}`, association);
  },
  removeDayRound(roundId) {
    return baseAxios.delete(`/staff/days/rounds/${roundId}`);
  },

  /** Rounds **/
  getRounds({
    filterName = undefined,
    filterIcon = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterSubtitle = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[icon_type]": filterIcon,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[subtitle]": filterSubtitle,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/rounds`, { params: query });
  },
  getRound(roundId) {
    return baseAxios.get(`/staff/rounds/${roundId}`);
  },
  createRound(round) {
    return baseAxios.post(`/staff/rounds`, round);
  },
  updateRound(roundId, day) {
    return baseAxios.put(`/staff/rounds/${roundId}`, day);
  },
  deleteRound(roundId) {
    return baseAxios.delete(`/staff/rounds/${roundId}`);
  },
  sortRoundExercisesAndSupersets(roundId, childIds) {
    return baseAxios.post(`/staff/rounds/${roundId}/sort-exercises-and-supersets`, childIds);
  },
  addSupersetToRound(roundId, supersetId, association) {
    return baseAxios.post(
      `/staff/rounds/${roundId}/associate-super-set/${supersetId}`,
      association
    );
  },
  updateRoundSuperset(supersetId, association) {
    return baseAxios.put(`/staff/rounds/super-sets/${supersetId}`, association);
  },
  removeRoundSuperset(supersetId) {
    return baseAxios.delete(`/staff/rounds/super-sets/${supersetId}`);
  },
  addExerciseToRound(roundId, exerciseId, association) {
    return baseAxios.post(`/staff/rounds/${roundId}/associate-exercise/${exerciseId}`, association);
  },
  updateRoundExercise(exerciseId, association) {
    return baseAxios.put(`/staff/rounds/exercises/${exerciseId}`, association);
  },
  removeRoundExercise(exerciseId) {
    return baseAxios.delete(`/staff/rounds/exercises/${exerciseId}`);
  },

  /** Supersets **/
  getSupersets({
    filterName = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterPublished = undefined,
    filterSubtitle = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[subtitle]": filterSubtitle,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/super-sets`, { params: query });
  },
  getSuperset(supersetId) {
    return baseAxios.get(`/staff/super-sets/${supersetId}`);
  },
  createSuperset(superset) {
    return baseAxios.post(`/staff/super-sets`, superset);
  },
  updateSuperset(supersetId, superset) {
    return baseAxios.put(`/staff/super-sets/${supersetId}`, superset);
  },
  deleteSuperset(supersetId) {
    return baseAxios.delete(`/staff/super-sets/${supersetId}`);
  },
  sortSupersetExercises(supersetId, exerciseIds) {
    return baseAxios.post(`/staff/super-sets/${supersetId}/sort-exercises`, exerciseIds);
  },
  addExerciseToSuperset(roundId, exerciseId, association) {
    return baseAxios.post(
      `/staff/super-sets/${roundId}/associate-exercise/${exerciseId}`,
      association
    );
  },
  updateSupersetExercise(exerciseId, association) {
    return baseAxios.put(`/staff/super-sets/exercises-details/${exerciseId}`, association);
  },
  removeSupersetExercise(exerciseId) {
    return baseAxios.delete(`/staff/super-sets/exercises-details/${exerciseId}`);
  },

  /** Exercises **/
  getExercises({
    filterName = undefined,
    filterIcon = undefined,
    filterLevel = undefined,
    filterCategory = undefined,
    filterDescription = undefined,
    filterInstructions = null,
    filterStyle = null,
    filterPublished = undefined,
    filterSubtitle = undefined,
    filterRecommended = undefined,
    sort = undefined,
    depth = 0,
    perPage = 10,
    page = 1
  } = {}) {
    const query = {
      "filter[name]": filterName,
      "filter[icon_type]": filterIcon,
      "filter[level]": filterLevel,
      "filter[description]": filterDescription,
      "filter[instructions]": filterInstructions,
      "filter[style]": filterStyle,
      "filter[published]": filterPublished,
      "filter[subtitle]": filterSubtitle,
      "filter[recommended]": filterRecommended,
      "filter[category.name]": filterCategory,
      depth,
      perPage,
      page,
      sort
    };
    return baseAxios.get(`/staff/exercises`, { params: query });
  },
  getExercise(exerciseId) {
    return baseAxios.get(`/staff/exercises/${exerciseId}`);
  },
  createExercise(exercise) {
    return baseAxios.post(`/staff/exercises`, exercise);
  },
  updateExercise(exerciseId, exercise) {
    return baseAxios.put(`/staff/exercises/${exerciseId}`, exercise);
  },
  deleteExercise(exerciseId) {
    return baseAxios.delete(`/staff/exercises/${exerciseId}`);
  },

  /** Body Position **/
  getBodyPositions() {
    return baseAxios.get(`/staff/body-positions`);
  },
  createBodyPosition(bodyPosition) {
    return baseAxios.post(`/staff/body-positions`, bodyPosition);
  },
  getBodyPosition(bodyPositionId) {
    return baseAxios.get(`/staff/body-positions/${bodyPositionId}`);
  },
  updateBodyPosition(bodyPositionId, bodyPosition) {
    return baseAxios.put(`/staff/body-positions/${bodyPositionId}`, bodyPosition);
  },
  deleteBodyPosition(bodyPositionId) {
    return baseAxios.delete(`/staff/body-positions/${bodyPositionId}`);
  },

  /** Grip Types **/
  getGripTypes() {
    return baseAxios.get(`/staff/grip-types`);
  },
  createGripType(gripType) {
    return baseAxios.post(`/staff/grip-types`, gripType);
  },
  getGripType(gripTypeId) {
    return baseAxios.get(`/staff/grip-types/${gripTypeId}`);
  },
  updateGripType(gripTypeId, gripType) {
    return baseAxios.put(`/staff/grip-types/${gripTypeId}`, gripType);
  },
  deleteGripType(gripTypeId) {
    return baseAxios.delete(`/staff/grip-types/${gripTypeId}`);
  }
};
