<template>
  <component
    :is="childType"
    v-bind="props"
    :my-program="myProgram"
    :key="childHash"
    @exercise-changed="$emit('exercise-changed')"
  />
</template>

<script>
import Exercise from "@/views/programs/nestedViews/Exercise";
import Superset from "@/views/programs/nestedViews/Superset";

export default {
  name: "RoundChildSubPage",
  metaInfo: () => ({
    title: "View Round Child"
  }),
  components: { Exercise, Superset },
  props: {
    childType: String,
    childHash: String,
    hideTitle: {
      type: Boolean,
      default: false
    },
    myProgram: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    props() {
      let props = {
        ...this.$props
      };
      props[`${this.childType}Hash`] = this.childHash;
      return props;
    }
  }
};
</script>
