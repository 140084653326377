<template>
  <div>
    <v-speed-dial v-if="active" v-model="fab" direction="bottom" transition="scale-transition">
      <template v-slot:activator>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn color="#3d70b2" dark fab v-on="on">
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </v-btn>
          </template>
          <span>{{ fab ? "Close" : "Program Menu" }}</span>
        </v-tooltip>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn fab dark small color="#3bb273" v-on="on" @click="restartProgram">
            <v-icon>mdi-restart</v-icon>
          </v-btn>
        </template>
        <span>Restart</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn fab dark small color="#e15554" v-on="on" @click="stopProgram">
            <v-icon>mdi-stop</v-icon>
          </v-btn>
        </template>
        <span>Stop</span>
      </v-tooltip>
    </v-speed-dial>
    <v-tooltip v-else left>
      <template v-slot:activator="{ on }">
        <v-btn fab dark color="#3d70b2" v-on="on" @click="selectProgram">
          <v-icon>mdi-bookmark</v-icon>
        </v-btn>
      </template>
      <span>Select Program</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ProgramMenu",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    programId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    fab: false
  }),
  methods: {
    async selectProgram() {
      const result = await this.$swal({
        title: "Set this program as active?",
        text: "You can only have one active program at a time.",
        showCancelButton: true,
        confirmButtonColor: "#3bb273",
        confirmButtonText: "Yes, make it active!"
      });
      if (result.value) {
        await this.$store.dispatch("auth/setActiveProgram", this.programId);
        await this.$router.push({ name: "My Program" });
      }
    },
    async restartProgram() {
      const result = await this.$swal({
        title: "Restart this Program?",
        text: "You cannot undo this.",
        showCancelButton: true,
        confirmButtonColor: "#e15554",
        confirmButtonText: "Restart!"
      });

      if (result.value) {
        await this.$store.dispatch("program/restartProgram", this.programId);
        await this.$router.push({ name: "Training Programs" });
      }
    },
    async stopProgram() {
      const result = await this.$swal({
        title: "Stop this Program?",
        text: "This will save your data and stop tracking your progress on this program.",
        showCancelButton: true,
        confirmButtonColor: "#e15554",
        confirmButtonText: "Stop!"
      });

      if (result.value) {
        await this.$store.dispatch("program/stopProgram", this.programId);
      }
    }
  }
};
</script>

<style scoped></style>
