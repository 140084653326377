import CycleService from "../../services/api/cycle-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    cycle: null,
    cycles: [{}, {}]
  },
  mutations: {
    SET_CYCLE(state, cycle) {
      state.cycle = cycle;
    },
    SET_CYCLES(state, cycles) {
      state.cycles = cycles;
    },
    SET_CYCLE_DAYS_AND_WEEKS(state, daysAndWeeks) {
      state.cycle.days_and_weeks = daysAndWeeks;
    }
  },
  actions: {
    async fetchCycle({ commit }, id) {
      const response = await CycleService.getCycle(id);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async fetchCycleByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateCycles({ commit, state }, query) {
      const response = await StaffService.getCycles(query);
      const cycles = query.page > 1 ? [...state.cycles, ...response.data.data] : response.data.data;

      commit("SET_CYCLES", cycles);
      return response.data;
    },
    async fetchTemplateCycle({ commit }, id) {
      const response = await StaffService.getCycle(id);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async createCycle({ commit }, form) {
      const response = await StaffService.createCycle(form);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async editCycle({ commit }, { id, form }) {
      const response = await StaffService.updateCycle(id, form);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async deleteCycle({ commit, dispatch }, id) {
      const response = await StaffService.deleteCycle(id);
      commit("SET_CYCLE", null);
      dispatch("fetchTemplateCycles");
      return response.data.data;
    },
    async sortDaysAndWeeks({ commit, dispatch }, { parentId, childList }) {
      commit("SET_CYCLE_DAYS_AND_WEEKS", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortCycleDaysAndWeeks(parentId, childIds);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async associateWeek({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addWeekToCycle(parentId, childId, association);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async updateAssociationWeek({ commit }, { childId, association }) {
      const response = await StaffService.updateCycleWeek(childId, association);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async removeAssociationWeek({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeCycleWeek(childId);
      dispatch("fetchTemplateCycle", parentId);
      return response.data.data;
    },
    async associateDay({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addDayToCycle(parentId, childId, association);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async updateAssociationDay({ commit }, { childId, association }) {
      const response = await StaffService.updateCycleDay(childId, association);
      commit("SET_CYCLE", response.data.data);
      return response.data.data;
    },
    async removeAssociationDay({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeCycleDay(childId);
      dispatch("fetchTemplateCycle", parentId);
      return response.data.data;
    }
  },
  getters: {}
};
