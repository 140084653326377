<template>
  <v-btn
    x-large
    rounded
    :color="style.buttonColor"
    :elevation="style.elevation"
    :disabled="disabled"
    class="pr-1 pl-5 white--text font-weight-black"
    :to="linkTo"
    @click="$emit('buttonClick')"
    :loading="loading"
  >
    {{ text }}
    <v-avatar right color="#508fdd" class="ml-2" size="45">
      <v-icon color="white" large>mdi-arrow-right</v-icon>
    </v-avatar>
  </v-btn>
</template>

<script>
export default {
  name: "ContinueButton",
  props: {
    text: {
      type: String,
      default: "Continue"
    },
    linkTo: null,
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      return {
        buttonColor: this.disabled ? "#A7C7EE" : "#2473d5",
        avatarColor: this.disabled ? "#D3E3F7" : "#508fdd",
        elevation: this.disabled ? 0 : 5
      };
    }
  }
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #a7c7ee !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: white !important;
}
</style>
