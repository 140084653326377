<template>
  <v-container fluid>
    <v-row align="center" class="flex-nowrap">
      <v-col cols="10" md="auto">
        <h1 :class="headerClass">{{ program.name }}</h1>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- If not in builder mode, display cycle expansion panels/tabs -->
    <div v-if="!builder">
      <!-- Cycles -->
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="openTab" flat tile accordion class="transparent-expansion">
              <!-- Description -->
              <v-expansion-panel v-if="program.description">
                <v-expansion-panel-header color="#f4f5f5" class="px-0">
                  <h2 class="title font-weight-bold">Program Description</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#f4f5f5">
                  <p class="trix-content" v-html="program.description"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-for="cycle in program.cycles" :key="cycle.id">
                <v-expansion-panel-header color="#f4f5f5" class="px-0">
                  <div class="d-inline-flex">
                    <letter-icon :type="cycle.icon_type" />
                    <h4 class="title font-weight-bold">{{ cycle.name }}</h4>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#f4f5f5" class="pa-0">
                  <template v-for="item in cycle.days_and_weeks">
                    <day-card
                      v-if="item.type === 'Day Association'"
                      :key="item.id"
                      :day="item"
                      class="my-3 mx-auto"
                      @cardClicked="selectDay(cycle.id, item.id, item.route_hash_key)"
                    ></day-card>
                    <week-card
                      v-if="item.type === 'Week Association'"
                      :key="item.id"
                      :week="item"
                      class="my-3 mx-auto"
                      @dayClicked="event => selectDay(cycle.id, event.id, event.route_hash_key)"
                    ></week-card>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <!-- Description -->
        <v-expansion-panels
          v-if="program.description"
          v-model="programDescriptionOpen"
          flat
          tile
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="#f4f5f5" class="px-0">
              <h2 class="title font-weight-bold">Program Description</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f4f5f5">
              <p class="trix-content" v-html="program.description"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-tabs background-color="transparent" v-model="openTab">
          <v-tab v-for="cycle in program.cycles" :key="cycle.id" :style="{ textTransform: 'none' }">
            <letter-icon :type="cycle.icon_type" />
            <h4 class="body-1 font-weight-bold">{{ cycle.name }}</h4>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="openTab">
          <v-tab-item v-for="cycle in program.cycles" :key="cycle.id">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels
                    v-if="cycle.description"
                    v-model="cycleDescriptionOpen"
                    flat
                    tile
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header color="#f4f5f5" class="pa-0">
                        <h2 class="title font-weight-bold mb-3">Cycle Description</h2>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content color="#f4f5f5">
                        <p class="trix-content" v-html="cycle.description"></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <template v-for="item in cycle.days_and_weeks">
                  <v-col
                    v-if="item.type === 'Day Association'"
                    md="6"
                    lg="4"
                    xl="3"
                    class="my-1"
                    :key="item.id"
                    align-self="center"
                  >
                    <day-card
                      :day="item"
                      class="mx-auto"
                      @cardClicked="selectDay(cycle.id, item.id, item.route_hash_key)"
                    ></day-card>
                  </v-col>
                  <v-col v-if="item.type === 'Week Association'" cols="12" :key="item.id">
                    <week-card
                      :week="item"
                      @dayClicked="event => selectDay(cycle.id, event.id, event.route_hash_key)"
                    ></week-card>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <!-- Description -->
      <template v-if="program.description">
        <v-expansion-panels v-model="programDescriptionOpen" flat tile accordion>
          <v-expansion-panel>
            <v-expansion-panel-header color="#f4f5f5" class="px-0">
              <h2 class="title font-weight-bold">Program Description</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f4f5f5">
              <p class="trix-content" v-html="program.description"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mb-6"></v-divider>
      </template>
      <h4 class="title font-weight-bold my-3">Associated Cycles</h4>
      <draggable
        class="draggable"
        :value="program.cycles"
        group="workoutBuilder"
        @change="associationChange"
      >
        <cycle-card
          v-for="cycle in program.cycles"
          :key="cycle.id"
          :cycle="cycle"
          editable
          class="my-2"
          @edit="editAssociation(cycle)"
          @view="$emit('view-object', cycle)"
        ></cycle-card>
      </draggable>
    </div>
  </v-container>
</template>

<script>
import LetterIcon from "../Avatars/LetterAvatar";
import DayCard from "../Cards/DayCard";
import draggable from "vuedraggable";
import CycleCard from "../Cards/CycleCard";
import WeekCard from "../Cards/WeekCard";

export default {
  name: "Program",
  components: {
    WeekCard,
    CycleCard,
    LetterIcon,
    DayCard,
    draggable
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    builder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      programDescriptionOpen: false,
      cycleDescriptionOpen: false,
      openTab: null,
      fab: false
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold mr-12 pr-3";
    }
  },
  methods: {
    selectDay(cycleId, dayId, dayHash) {
      this.$emit("dayClicked", {
        cycleId: cycleId,
        dayId: dayId,
        dayHash: dayHash
      });
    },
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const cycle = event.added.element;
        this.$emit("associateObject", {
          type: "cycle",
          object: {
            ...cycle,
            sort_order: sortOrder
          }
        });
      }
      if ("moved" in event) {
        const newChildList = this.move(
          this.program.cycles,
          event.moved.oldIndex,
          event.moved.newIndex
        );

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(cycle) {
      this.$emit("editAssociation", {
        type: "cycle",
        object: cycle
      });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>

<style scoped>
.v-tabs {
  border-bottom: #d5d6d8 solid 1px;
}

.theme--light.v-tabs-items {
  background-color: #f4f5f5;
}
</style>
