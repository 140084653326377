<template>
  <v-container fluid>
    <v-row align="center" dense>
      <v-col cols="auto">
        <letter-icon :type="cycle.icon_type" large class="mr-3" />
      </v-col>
      <v-col cols="auto">
        <h1 :class="headerClass">
          {{ cycle.name }}
        </h1>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- Description -->
    <v-expansion-panels v-if="cycle.description" v-model="descriptionOpen" flat tile accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="#f4f5f5" class="px-0">
          <h2 class="title font-weight-bold">Cycle Description</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f4f5f5">
          <p class="trix-content" v-html="cycle.description"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <v-divider class="my-6"></v-divider>
      <h4 class="title font-weight-bold mb-3">Associated Days and Weeks</h4>
      <draggable
        class="draggable"
        :value="cycle.days_and_weeks"
        group="workoutBuilder"
        @change="associationChange"
      >
        <component
          v-for="item in cycle.days_and_weeks"
          :is="item.type === 'Day Template' ? 'day-card' : 'week-card'"
          editable
          :day="item"
          :week="item"
          class="my-2 mx-auto"
          :key="item.id"
          hide-days
          @edit="editAssociation(item.type === 'Day Template' ? 'day' : 'week', item)"
          @view="$emit('view-object', item)"
        ></component>
      </draggable>
    </div>
  </v-container>
</template>

<script>
import LetterIcon from "../Avatars/LetterAvatar";
import draggable from "vuedraggable";
import DayCard from "../Cards/DayCard";
import WeekCard from "../Cards/WeekCard";

export default {
  name: "Cycle",
  components: { WeekCard, DayCard, LetterIcon, draggable },
  props: {
    cycle: {
      type: Object,
      required: true
    },
    // Only used for the builder currently
    builder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      descriptionOpen: false
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold mr-12 pr-3";
    }
  },
  methods: {
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const item = event.added.element;
        const type = item.type === "Day Template" ? "day" : "week";

        this.$emit("associateObject", { type: type, object: { ...item, sort_order: sortOrder } });
      }
      if ("moved" in event) {
        const newChildList = this.move(
          this.cycle.days_and_weeks,
          event.moved.oldIndex,
          event.moved.newIndex
        );

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(type, object) {
      this.$emit("editAssociation", { type: type, object: object });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>
