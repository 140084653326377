<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M18.1691 25.5568C17.8108 25.5568 17.4641 25.3688 17.2761 25.0354C16.9987 24.5423 17.1723 23.9177 17.6655 23.6404C21.2775 21.6053 24.1763 18.9811 26.5281 15.617C26.8515 15.1533 27.4899 15.0395 27.9536 15.3646C28.4174 15.6885 28.5305 16.3264 28.206 16.7901C25.71 20.3611 22.5023 23.266 18.6711 25.4242C18.5119 25.5141 18.3394 25.5568 18.1691 25.5568Z"
        fill="#E06437"
      />
      <path
        d="M29.8578 12.0332C29.7613 12.0332 29.6637 12.0199 29.5671 11.991C29.0241 11.8302 28.7152 11.2611 28.8755 10.7186C29.3824 9.00575 28.8621 6.74433 27.6646 5.45802C26.4132 4.11015 25.0343 3.45563 23.56 3.48947C21.5681 3.54605 19.3377 4.8917 17.4402 7.18252C17.0791 7.6185 16.434 7.67896 15.998 7.31786C15.562 6.95677 15.5016 6.31168 15.8627 5.8757C18.1363 3.13115 20.9208 1.51481 23.5017 1.44215C25.594 1.37004 27.4954 2.26474 29.1644 4.06356C30.8418 5.86571 31.5468 8.90924 30.8396 11.2994C30.707 11.7453 30.2999 12.0332 29.8578 12.0332Z"
        fill="#E06437"
      />
      <path
        d="M1.40072 11.9489C0.934237 11.9489 0.512126 11.6277 0.403409 11.1529C-0.235027 8.36457 0.327973 5.84632 1.98813 4.06247C3.76754 2.1455 6.03618 1.27022 8.5633 1.54756C9.12574 1.60746 9.53177 2.11333 9.47131 2.67522C9.4114 3.23767 8.91552 3.65035 8.34364 3.58323C6.46162 3.3841 4.8303 4.01089 3.48854 5.45694C2.30374 6.72937 1.91768 8.58921 2.40025 10.6959C2.52672 11.2472 2.18171 11.7964 1.63036 11.9228C1.55381 11.94 1.47671 11.9489 1.40072 11.9489Z"
        fill="#E06437"
      />
      <path
        d="M15.6887 26.8992C15.5356 26.8992 15.3803 26.8642 15.2344 26.7921C9.52066 23.9571 4.99392 19.7371 2.6626 16.733C2.31537 16.2859 2.39691 15.6431 2.84343 15.2964C3.2905 14.9486 3.93393 15.0307 4.28005 15.4772C6.47269 18.3027 10.7404 22.2765 16.1446 24.9578C16.6511 25.2091 16.858 25.8236 16.6067 26.3301C16.4275 26.6901 16.0653 26.8992 15.6887 26.8992Z"
        fill="#E06437"
      />
      <path
        d="M16.4401 20.7256C14.5426 20.7256 14.1099 17.996 13.3572 10.1944C13.1415 7.96076 12.8447 4.8845 12.4753 3.13948C12.1885 4.69148 12.0177 7.26907 11.9029 9.00577C11.5334 14.5836 11.3177 16.8523 9.67582 16.9959C8.0728 17.134 7.35671 15.6958 7.01115 14.6507H1.02394C0.458165 14.6513 0 14.1931 0 13.6274C0 13.0616 0.458165 12.6034 1.02394 12.6034H7.78326C8.24808 12.6034 8.65466 12.9163 8.77336 13.3661L8.78889 13.4255C8.97083 14.1194 9.12503 14.501 9.24373 14.7095C9.54936 13.5508 9.73185 10.7924 9.85887 8.86987C10.256 2.87934 10.5339 0 12.4869 0C14.3568 0 14.7095 2.886 15.3957 9.99754C15.6403 12.5385 15.9847 16.1046 16.4351 17.8546C16.7452 16.9094 17.0825 15.511 17.3343 14.4677C18.3211 10.3808 18.8619 8.4167 20.3628 8.4167C21.8061 8.4167 22.1605 10.1379 22.5716 12.1303C22.6958 12.7338 22.8833 13.6418 23.0741 14.1776C23.0885 14.1543 23.1029 14.1316 23.1151 14.111C23.4435 13.5841 24.0537 12.6034 25.508 12.6034H30.9755C31.5413 12.6034 31.9994 13.0616 31.9994 13.6274C31.9994 14.1931 31.5413 14.6513 30.9755 14.6513H25.508C25.2235 14.6513 25.1564 14.7073 24.8535 15.1938C24.5523 15.678 24.0476 16.4889 22.9221 16.4889C21.3784 16.4889 20.9835 14.5731 20.5653 12.5446C20.4937 12.1974 20.3994 11.7409 20.2979 11.3265C19.9529 12.3483 19.5802 13.8903 19.3245 14.9492C18.3582 18.9551 17.8701 20.7256 16.4401 20.7256Z"
        fill="#E06437"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="26.8992" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CardioIcon"
};
</script>

<style scoped></style>
