<template>
  <cycle-card-skeleton v-if="loading" />
  <v-card v-else @click="$emit('click', cycle.id)">
    <v-list-item class="pa-4">
      <letter-icon :type="cycle.icon_type" large class="mr-3" />
      <v-list-item-content justify="center">
        <v-list-item-title class="title font-weight-black">{{ cycle.name }}</v-list-item-title>
        <v-list-item-title class="heading text--secondary">{{ subtitle }}</v-list-item-title>
      </v-list-item-content>
      <div v-if="editable" class="d-flex flex-column">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('edit')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('view')">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View Sub-object</span>
        </v-tooltip>
      </div>
    </v-list-item>
  </v-card>
</template>

<script>
import LetterIcon from "../Avatars/LetterAvatar";
import CycleCardSkeleton from "@/components/SkeletonLoaders/Cards/CycleCardSkeleton";

export default {
  name: "CycleCard",
  components: { CycleCardSkeleton, LetterIcon },
  props: {
    cycle: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return !!!this.cycle.id;
    },
    subtitle() {
      return `${this.cycle.child_count} Sessions`;
    }
  }
};
</script>
