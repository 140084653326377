<template>
  <v-tabs align-with-title style="border-top: #EAEBEC solid 1px">
    <v-tab :to="{ name: 'Logbook', query: { tab: 'Current' } }" exact>Current Program</v-tab>
    <v-tab :to="{ name: 'Logbook', query: { tab: 'Past' } }" exact>Past Programs</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "LogbookTabs"
};
</script>

<style scoped></style>
