import AuthService from "../../services/api/auth-service";
import ProgramService from "../../services/api/program-service";

export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, user) {
      state.user = user;
    },
    SET_ACTIVE_PROGRAM(state, programId) {
      state.user.active_program = programId;
    },
    HIDE_INTRO_VIDEO(state) {
      state.user.hideIntroVideo = true;
    }
  },
  actions: {
    async initializeLogin() {
      return await AuthService.initialize();
    },
    async getMe({ commit }) {
      try {
        const { data } = await AuthService.getMe();
        commit("SET_USER_DATA", data.data);
      } catch (error) {
        return false;
      }
      return true;
    },
    async login({ commit }, { username, password, rememberMe }) {
      const { data } = await AuthService.login(username, password, rememberMe);
      commit("SET_USER_DATA", data.data.user);
      return data.data.user;
    },
    async logout() {
      return await AuthService.logout();
    },
    async setActiveProgram({ commit }, programId) {
      const response = await ProgramService.setActiveProgram(programId);
      commit("SET_ACTIVE_PROGRAM", programId);
      return response;
    },
    async hideIntroVideo({ commit }) {
      const response = await AuthService.hideIntroVideo();
      commit("HIDE_INTRO_VIDEO");
      return response;
    },
    async updateSettings({ dispatch }, settings) {
      const response = await AuthService.updateSettings(settings);
      dispatch("getMe");
      return response;
    }
  },
  getters: {
    loggedIn: state => !!state.user,
    isStaff: state => state.user?.isStaff,
    hasActiveProgram: state => !!state.user?.active_program,
    activeProgramId: state => state.user?.active_program,
    userInitials: state =>
      state.user?.name
        .split(" ")
        .map(item => item[0])
        .join(""),
    settings: state => ({
      showIntroVideo: !state.user?.hideIntroVideo,
      weightUnit: state.user?.weightUnit || "lbs"
    })
  }
};
