<template>
  <div>
    <div class="v-text-field__details">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div
            v-for="message in errorMessages"
            :key="message"
            class="v-messages__message ml-2 mt-2"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrixErrorMessages",
  props: {
    errorMessages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped></style>
