<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-skeleton-loader loading class="mb-6" type="heading"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="chip"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="paragraph"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-skeleton-loader loading class="mb-6" type="divider"></v-skeleton-loader>
    <v-row>
      <v-col>
        <v-skeleton-loader loading class="mb-6" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SupersetSkeleton"
};
</script>

<style scoped></style>
