import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "../store";

// Layouts
import AppLayout from "../layouts/AppLayout";

// Pages
import Login from "../views/Login.vue";
import Settings from "@/views/Settings";
import FAQs from "../views/FAQs.vue";

import Logbook from "../views/Logbook";
import LogbookPast from "../views/LogbookPast";

import AllPrograms from "../views/programs/AllPrograms";
import Program from "../views/programs/Program";
import Day from "../views/programs/Day";
import Round from "../views/programs/Round";
import Superset from "../views/programs/Superset";
import RoundChildSwitch from "@/views/programs/nestedViews/RoundChildSwitch";
import Exercise from "@/views/programs/nestedViews/Exercise";

import BuilderList from "@/views/workoutBuilder/BuilderList";
import BuilderObject from "@/views/workoutBuilder/BuilderObject";

import NotFound from "../views/errors/NotFound";
import ServerError from "@/views/errors/ServerError";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/app",
      component: AppLayout,
      meta: { requiresAuth: true },
      redirect: { name: "My Program" },
      children: [
        {
          path: "settings",
          name: "Settings",
          component: Settings
        },
        {
          path: "faqs",
          name: "FAQs",
          component: FAQs
        },
        {
          path: "coach-help",
          name: "Coach Help",
          redirect: { name: "FAQs" }
        },
        {
          path: "programs",
          name: "Training Programs",
          component: AllPrograms
        },
        {
          path: "logbook",
          name: "Logbook",
          component: Logbook,
          props: true
        },
        {
          path: "logbook/:pastProgramId",
          name: "Logbook - Past",
          component: LogbookPast,
          props: true
        },
        {
          path: "my-program",
          name: "My Program",
          component: Program,
          meta: { requiresActiveProgram: true },
          props: { myProgram: true }
        },
        {
          path: "my-program/day/:dayHash",
          name: "My Program - Day",
          component: Day,
          meta: { requiresActiveProgram: true },
          props: route => ({
            myProgram: true,
            ...route.params
          })
        },
        {
          path: "my-program/round/:roundHash",
          component: Round,
          meta: { requiresActiveProgram: true },
          props: route => ({
            myProgram: true,
            ...route.params
          }),
          children: [
            {
              path: ":childType-:childHash",
              name: "My Program - Round",
              component: RoundChildSwitch,
              props: route => ({
                myProgram: true,
                ...route.params
              })
            }
          ]
        },
        {
          path: "my-program/superset/:supersetHash",
          component: Superset,
          meta: { requiresActiveProgram: true },
          props: route => ({
            myProgram: true,
            ...route.params
          }),
          children: [
            {
              path: ":exerciseHash",
              name: "My Program - Superset",
              component: Exercise,
              props: route => ({
                myProgram: true,
                ...route.params
              })
            }
          ]
        },
        {
          path: "programs/:programId",
          name: "Program",
          component: Program,
          props: true
        },
        {
          path: "programs/day/:dayHash",
          name: "Day",
          component: Day,
          props: true
        },
        {
          path: "programs/round/:roundHash",
          component: Round,
          props: true,
          children: [
            {
              path: ":childType-:childHash",
              name: "Round",
              component: RoundChildSwitch,
              props: true
            }
          ]
        },
        {
          path: "programs/superset/:supersetHash",
          component: Superset,
          props: true,
          children: [
            {
              path: ":exerciseHash",
              name: "Superset",
              component: Exercise,
              props: true
            }
          ]
        },
        {
          path: "workout-builder/:objectType",
          name: "Workout Builder",
          meta: { requiresStaff: true },
          component: BuilderList,
          props: true
        },
        {
          path: "workout-builder/:objectType/:id",
          name: "Workout Builder - View",
          meta: { requiresStaff: true },
          component: BuilderObject,
          props: true
        },
        // Error Routes
        {
          path: "404",
          name: "App 404",
          component: NotFound
        },
        {
          path: "500",
          name: "App 500",
          component: ServerError
        },
        // Catch All Route
        {
          path: "*",
          redirect: { name: "App 404" }
        }
      ]
    },
    // Catch all path to redirect to "My Program" if logged in, otherwise "Login"
    {
      path: "*",
      redirect: { name: "My Program" }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // Start the App Progress Bar
  NProgress.start();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // If it requires auth check if we have auth
    let activeSession = store.getters["auth/loggedIn"];

    // If we dont have a user check if the server has an active session
    if (!activeSession) {
      // Try to get the user to verify if we have an active session.
      activeSession = await store.dispatch("auth/getMe");
    }

    // if no active session, redirect to login
    if (!activeSession) {
      next({ name: "Login" });
    } else {
      if (to.matched.some(record => record.meta.requiresStaff)) {
        const isStaff = store.getters["auth/isStaff"];

        // If not staff and trying to access staff routes, route back
        if (!isStaff) {
          next(false);
          Vue.swal({
            toast: true,
            icon: "danger",
            timer: 3000,
            position: "top-end",
            title: "You do not have permission.",
            showConfirmButton: false,
            background: "#fceeed"
          });
          NProgress.done();
        }
        // Otherwise proceed
        else {
          next();
        }
      } else if (to.matched.some(record => record.meta.requiresActiveProgram)) {
        const activeProgram = store.getters["auth/hasActiveProgram"];

        // If user doesnt have an active program route back to choose program
        if (!activeProgram) {
          next({ name: "Training Programs" });
          await Vue.swal({
            toast: true,
            icon: "warning",
            timer: 3000,
            position: "top-end",
            title: "No Active Program",
            text: "Please choose a program.",
            showConfirmButton: false,
            background: "#fcf8e9"
          });
          NProgress.done();
        }
        // Otherwise proceed
        else {
          next();
        }
      }
      // Otherwise proceed
      else {
        next();
      }
    }
    // Otherwise proceed
  } else {
    next();
  }
});

router.afterEach(() => {
  // Stop the App ProgressBars Bar
  NProgress.done();
});

export default router;
