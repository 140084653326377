<template>
  <v-dialog v-model="open" max-width="500" @close="$emit('close')">
    <v-card>
      <v-card-title class="headline pt-5">Please enter your data for this exercise</v-card-title>
      <v-card-subtitle>To see your data go to the Logbook</v-card-subtitle>
      <v-card-text>
        <component
          :is="formType"
          :sets="exercise.sets"
          :climbingType="climbingType"
          :log="logbookEntry"
          @submit="submitForm"
        ></component>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ContinueButton from "../Buttons/ContinueButton";
import FingersForm1 from "../Forms/Logbook/FingersForm1";
import FingersForm2 from "../Forms/Logbook/FingersForm2";
import FingersForm3 from "../Forms/Logbook/FingersForm3";
import LiftingForm1 from "../Forms/Logbook/LiftingForm1";
import LiftingForm2 from "../Forms/Logbook/LiftingForm2";
import ClimbingForm1 from "../Forms/Logbook/ClimbingForm1";
import ClimbingForm2 from "../Forms/Logbook/ClimbingForm2";
import DefaultForm from "../Forms/Logbook/DefaultForm";

export default {
  name: "ExerciseLogModal",
  components: {
    ContinueButton,
    FingersForm1,
    FingersForm2,
    FingersForm3,
    LiftingForm1,
    LiftingForm2,
    ClimbingForm1,
    ClimbingForm2,
    DefaultForm
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: true
    },
    exercise: {
      type: Object,
      required: true
    }
  },
  computed: {
    open: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("setModal", value);
      }
    },
    formType() {
      if (this.exercise.logbook.type) {
        const [type, number] = this.exercise.logbook.type.split("_");

        switch (type) {
          case "finger":
            return `FingersForm${number}`;
          case "lifting":
            return `LiftingForm${number}`;
          case "climbing":
            return `ClimbingForm${number}`;
          default:
            return `DefaultForm`;
        }
      }
      return null;
    },
    climbingType() {
      if (this.exercise.style === "Routes Difficulty" || this.exercise.style === "Routes Volume") {
        return "Route";
      }
      if (
        this.exercise.style === "Bouldering Difficulty" ||
        this.exercise.style === "Bouldering Volume"
      ) {
        return "Bouldering";
      }
      return null;
    },
    logbookEntry() {
      if (this.exercise.logbook.completion_status) {
        return this.exercise.logbook.completion_info;
      }
      return null;
    }
  },
  methods: {
    submitForm(form) {
      this.$emit("submitForm", form);
    }
  }
};
</script>

<style scoped></style>
