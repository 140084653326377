<template>
  <v-hover v-slot="{ hover }">
    <v-card>
      <v-card-title>
        <v-row align="center" dense>
          <v-col cols="auto">
            <icon-avatar small :round-style="logbookEntry.exercise_type" />
          </v-col>
          <v-col>
            <router-link
              :to="{ name: parentType, params: routeParams }"
              class="text-decoration-none"
            >
              <h4 class="subtitle-2 font-weight-bold">{{ logbookEntry.title }}</h4>
            </router-link>
          </v-col>
          <v-col cols="12" md="auto" class="d-flex align-center justify-end">
            <h5 class="caption text--secondary">{{ logbookEntry.created_at | moment("lll") }}</h5>
            <v-btn
              v-if="hover"
              fab
              absolute
              x-small
              color="primary"
              class="ml-2"
              @click="$emit('edit')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            v-if="logbookEntry.displayData.grip_size || logbookEntry.displayData.hardest_grade"
            cols="12"
          >
            <v-row>
              <v-col v-if="logbookEntry.displayData.grip_size" cols="6" md="3">
                <span class="text-caption font-weight-bold">Grip Size:</span>
                <span class="body-2 ml-2">{{ logbookEntry.displayData.grip_size }} cm</span>
              </v-col>
              <v-col v-if="logbookEntry.displayData.hardest_grade" cols="6" md="3">
                <span class="text-caption font-weight-bold">Hardest Grade:</span>
                <span class="body-2 ml-2">{{ logbookEntry.displayData.hardest_grade }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="logbookEntry.displayData.sets.length > 0" cols="12" md="8" lg="6">
            <v-simple-table dense>
              <thead>
                <tr v-for="set in logbookEntry.displayData.sets" :key="set.set">
                  <th v-for="key in Object.keys(set)" :key="key" class="text-capitalize">
                    {{ key }}
                    <span v-if="key === 'weight'" style="font-size: 10px"
                      >({{ settings.weightUnit }})</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="set in logbookEntry.displayData.sets" :key="set.set">
                  <template v-for="[key, value] in Object.entries(set)">
                    <td v-if="value" :key="key">{{ value }}</td>
                    <td v-else :key="key">--</td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col v-if="logbookEntry.displayData.notes" cols="12">
            <div class="text-caption font-weight-bold">Notes:</div>
            <p class="body-2">{{ logbookEntry.displayData.notes }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import IconAvatar from "@/components/Avatars/IconAvatar";
import { mapGetters } from "vuex";

export default {
  name: "LogbookEntryCard",
  components: {
    IconAvatar
  },
  props: {
    logbookEntry: Object
  },
  computed: {
    parentType() {
      const type = this.logbookEntry.parent.type.split("\\");
      return type[0] === "Round" ? "Round" : "Superset";
    },
    routeParams() {
      if (this.parentType === "Round") {
        return {
          roundHash: this.logbookEntry.parent.hash_key,
          childHash: this.logbookEntry.route_hash_key,
          childType: "exercise"
        };
      } else {
        return {
          supersetHash: this.logbookEntry.parent.hash_key,
          exerciseHash: this.logbookEntry.route_hash_key
        };
      }
    },
    ...mapGetters({
      settings: "auth/settings"
    })
  }
};
</script>

<style scoped></style>
