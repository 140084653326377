import FaqService from "../../services/api/faq-service";

export default {
  namespaced: true,
  state: {
    faqs: []
  },
  mutations: {
    SET_FAQS(state, faqs) {
      state.faqs = faqs;
    }
  },
  actions: {
    async fetchFaqs({ commit }, query) {
      const response = await FaqService.getFaqs(query);
      commit("SET_FAQS", response.data.data);
      return response.data.data;
    }
  },
  getters: {}
};
