<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-skeleton-loader loading class="mb-6" type="heading"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="chip"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="paragraph"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="4">
        <v-skeleton-loader loading class="mb-6" type="card-avatar"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="4">
        <v-skeleton-loader loading class="mb-6" type="card-avatar"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="4">
        <v-skeleton-loader loading class="mb-6" type="card-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DaySkeleton"
};
</script>

<style scoped></style>
