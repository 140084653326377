<template>
  <div>
    <v-card v-if="loading">
      <v-card-text>
        <v-skeleton-loader type="list-item-avatar" max-width="400" class="mb-3" />
        <v-skeleton-loader type="text" max-width="200" class="ml-3 mb-4" />
        <v-skeleton-loader type="image" />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="text-h6 text-md-h5 font-weight-bold pb-0">
        <picture-icon roundStyle="Superset" class="mr-3" />
        <span class="mr-3">{{ superset.name }}</span>
        <time-badge :time="superset.total_time" />
      </v-card-title>
      <v-card-text>
        <!-- Description -->
        <v-expansion-panels
          v-if="superset.description"
          v-model="descriptionOpen"
          flat
          tile
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2 class="title font-weight-bold">Superset Description</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="trix-content" v-html="superset.description"></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row class="blue-grey lighten-4 mt-2 mb-n4 mx-n4" align="center">
          <v-col
            md="6"
            lg="4"
            xl="3"
            class="my-1"
            v-for="exercise in superset.exercises"
            :key="exercise.id"
          >
            <superset-exercise-card
              :exercise="exercise"
              @click="selectExercise(exercise.route_hash_key)"
            ></superset-exercise-card>
          </v-col>
          <v-col v-if="superset.repetitions > 1" cols="auto">
            <reps-badge :reps="superset.repetitions"></reps-badge>
          </v-col>
          <v-col v-if="superset.rep_rest > 0" cols="auto">
            <rest-time-badge prefix="Rest Between Reps" :time="superset.rep_rest"></rest-time-badge>
          </v-col>
          <v-col v-if="superset.super_set_rest" cols="auto">
            <rest-time-badge
              prefix="Rest After Superset"
              :time="superset.super_set_rest"
            ></rest-time-badge>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <superset-nav />
  </div>
</template>

<script>
import SupersetExerciseCard from "../../../components/Cards/ExerciseCard";
import RepsBadge from "../../../components/Badges/RepsBadge";
import RestTimeBadge from "../../../components/Badges/RestTimeBadge";
import PictureIcon from "../../../components/Avatars/IconAvatar";
import TimeBadge from "../../../components/Badges/TimeBadge";
import { mapState } from "vuex";
import SupersetNav from "@/components/Navigation/SupersetNav";

export default {
  name: "SupersetSubPage",
  components: {
    SupersetNav,
    TimeBadge,
    PictureIcon,
    RestTimeBadge,
    RepsBadge,
    SupersetExerciseCard
  },
  props: {
    myProgram: {
      type: Boolean,
      default: false
    },
    supersetHash: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    descriptionOpen: false
  }),
  created() {
    this.fetchSuperset();
  },
  computed: {
    ...mapState({ superset: state => state.superset.superset })
  },
  methods: {
    async fetchSuperset() {
      this.loading = true;
      await this.$store.dispatch("superset/fetchSupersetByHash", this.supersetHash);
      this.loading = false;
    },
    selectExercise(hash) {
      const routeName = this.myProgram ? "My Program - Superset" : "Superset";
      this.$router.push({
        name: routeName,
        params: { supersetHash: this.superset.route_hash_key, exerciseHash: hash }
      });
    }
  }
};
</script>
