<template>
  <v-container fluid>
    <v-row align="center" dense>
      <v-col cols="auto">
        <h1 :class="headerClass">{{ week.name }}</h1>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp">
        <v-divider class="mb-2"></v-divider>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- Description -->
    <v-expansion-panels v-if="week.description" v-model="descriptionOpen" flat tile accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="#f4f5f5" class="px-0">
          <h2 class="title font-weight-bold">Week Description</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f4f5f5">
          <p class="trix-content" v-html="week.description"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <v-divider class="mb-6"></v-divider>
      <h4 class="title font-weight-bold mb-3">Associated Days</h4>
      <draggable
        class="draggable"
        :value="week.days"
        group="workoutBuilder"
        @change="associationChange"
      >
        <template v-for="day in week.days">
          <day-card
            :day="day"
            editable
            class="my-2 mx-auto"
            :key="day.id"
            @edit="editAssociation('day', day)"
          ></day-card>
        </template>
      </draggable>
    </div>
  </v-container>
</template>

<script>
import TimeBadge from "../Badges/TimeBadge";
import draggable from "vuedraggable";
import DayCard from "../Cards/DayCard";

export default {
  name: "Week",
  components: { DayCard, draggable },
  props: {
    week: {
      type: Object,
      required: true
    },
    // Only used for the builder currently
    builder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      descriptionOpen: false
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold mr-12 pr-3";
    }
  },
  methods: {
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const item = event.added.element;

        this.$emit("associateObject", { type: "Day", object: { ...item, sort_order: sortOrder } });
      }
      if ("moved" in event) {
        const newChildList = this.move(this.week.days, event.moved.oldIndex, event.moved.newIndex);

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(type, object) {
      this.$emit("editAssociation", { type: type, object: object });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>
