<template>
  <!-- Normal List -->
  <div v-if="!template" :key="objectType">
    <v-row>
      <v-col v-for="item in items" :key="item.id" cols="12" sm="6" lg="4" xl="3">
        <component
          :is="componentType"
          :[singularType]="item"
          v-bind="$props"
          builder
          @click.native="objectClicked(item.id)"
        ></component>
      </v-col>
    </v-row>
    <infinite-loading
      :identifier="infiniteId"
      direction="bottom"
      @infinite="fetchItems"
      spinner="waveDots"
    >
      <template #no-results>
        <div class="mt-6">No {{ objectType }}...</div>
      </template>
      <span slot="no-more" />
    </infinite-loading>
  </div>
  <!-- Draggable Template List -->
  <draggable
    v-else
    :value="items"
    group="workoutBuilder"
    class="responsive-draggable"
    :key="objectType"
  >
    <component
      :is="componentType"
      v-for="item in items"
      :key="item.id"
      v-bind="$props"
      builder
      hide-image
      :[singularType]="item"
      class="ma-1"
    ></component>
    <infinite-loading
      :identifier="infiniteId"
      direction="bottom"
      @infinite="fetchItems"
      spinner="waveDots"
    >
      <template #no-results>
        <div class="mt-6">No {{ objectType }}...</div>
      </template>
      <span slot="no-more" />
    </infinite-loading>
  </draggable>
</template>

<script>
import store from "@/store";
import { listActions, listMutations } from "@/utils/Constants";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import InfiniteLoading from "vue-infinite-loading";
import ProgramCard from "@/components/Cards/ProgramCard";
import CycleCard from "@/components/Cards/CycleCard";
import WeekCard from "@/components/Cards/WeekCard";
import DayCard from "@/components/Cards/DayCard";
import RoundCard from "@/components/Cards/RoundCard";
import SupersetCard from "@/components/Cards/SupersetCard";
import ExerciseCard from "@/components/Cards/ExerciseCard";

export default {
  name: "ItemList",
  components: {
    SupersetCard,
    ExerciseCard,
    RoundCard,
    draggable,
    ProgramCard,
    CycleCard,
    WeekCard,
    DayCard,
    InfiniteLoading
  },
  props: {
    template: {
      type: Boolean,
      default: false
    },
    objectType: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false
    },
    showTime: {
      type: String,
      required: true
    }
  },
  data: () => ({
    page: 1,
    infiniteId: +new Date()
  }),
  created() {
    this.fetchItems = _.debounce(this.fetchItems, 250);
  },
  computed: {
    perPage() {
      const breakpoint = this.$vuetify.breakpoint;
      return breakpoint.smAndUp ? (breakpoint.lgAndUp ? 20 : 10) : 5;
    },
    singularType() {
      return this.objectType.substr(0, this.objectType.length - 1);
    },
    capitalizedType() {
      return this.objectType[0].toUpperCase() + this.singularType.substr(1);
    },
    componentType() {
      return `${this.capitalizedType}Card`;
    },
    items() {
      return this[this.objectType];
    },
    ...mapState({
      programs: state => state.program.programs,
      cycles: state => state.cycle.cycles,
      weeks: state => state.week.weeks,
      days: state => state.day.days,
      rounds: state => state.round.rounds,
      supersets: state => state.superset.supersets,
      exercises: state => state.exercise.exercises
    })
  },
  watch: {
    query: {
      deep: true,
      handler: "updateQuery"
    },
    objectType: "updateQuery"
  },
  methods: {
    updateQuery() {
      this.page = 1;
      store.commit(listMutations[this.objectType], [{}, {}]);
      this.infiniteId += 1;
    },
    async fetchItems($state) {
      const { page, perPage } = this;
      const { data } = await store.dispatch(listActions[this.objectType], {
        ...this.query,
        page,
        perPage
      });

      if (data.length) {
        this.page += 1;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    objectClicked(id) {
      this.$router.push({
        name: "Workout Builder - View",
        params: {
          objectType: this.objectType,
          id: id
        }
      });
    }
  }
};
</script>

<style scoped></style>
