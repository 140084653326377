<template>
  <v-navigation-drawer v-model="open" temporary absolute color="#29304e" dark class="pa-2">
    <v-list nav>
      <v-list-item two-line>
        <v-list-item-avatar color="#ebf0f7" size="60">
          <h3 style="color: #3d70b2">{{ userInitials }}</h3>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="font-weight-black">{{ user.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn :to="{ name: 'Settings' }" icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item href="https://www.trainingbeta.com/my-account" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="{ name: item.linkTo }"
        :href="item.href"
        exact
        :disabled="item.disabled"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Staff Items -->
      <template v-if="user.isStaff">
        <v-divider />
        <v-list-item
          v-for="item in staffItems"
          :key="item.title"
          :to="{ name: item.linkTo, params: item.params }"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider />

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideNavBar",
  props: {
    sideMenuOpen: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      items: [
        {
          icon: "mdi-text-box-multiple",
          text: "All Programs",
          linkTo: "Training Programs"
        },
        {
          icon: "mdi-help-circle",
          text: "FAQ",
          linkTo: "FAQs"
        }
      ],
      staffItems: [
        {
          icon: "mdi-wrench",
          text: "Workout Builder",
          linkTo: "Workout Builder",
          params: { objectType: "programs" }
        }
      ]
    };
  },
  computed: {
    open: {
      get() {
        return this.sideMenuOpen;
      },
      set(value) {
        this.$emit("setSideMenu", value);
      }
    },
    userInitials() {
      return this.$store.getters["auth/userInitials"];
    }
  },
  methods: {
    async logout() {
      try {
        const response = await this.$store.dispatch("auth/logout");

        this.$swal({
          icon: "success",
          title: "Success",
          text: response.data.data.message
        });
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: error.response.data.message
        });
      }
    }
  }
};
</script>

<style scoped></style>
