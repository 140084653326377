<template>
  <div :key="round.id">
    <v-row align="center" dense class="flex-nowrap">
      <v-col cols="auto" class="d-flex">
        <picture-icon
          v-if="$vuetify.breakpoint.smAndUp"
          :roundStyle="round.icon_type"
          class="mr-3"
        />
        <div>
          <div class="text-h5 font-weight-bold">{{ round.name }}</div>
          <div v-if="round.subtitle" class="body-1">{{ round.subtitle }}</div>
        </div>
      </v-col>
      <v-col cols="auto">
        <TimeBadge :time="round[showTime]" class="ml-5" />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- Description -->
    <v-expansion-panels v-if="round.description" v-model="descriptionOpen" flat tile accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="lightBg" class="px-0">
          <div class="text-subtitle-1 font-weight-bold text--secondary">Round Description</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBg">
          <p class="trix-content text-body-2 mx-n6" v-html="round.description"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- If not in builder mode, display exercise/superset carousel -->
    <template v-if="!builder">
      <!-- Exercises and Supersets -->
      <round-stepper
        v-if="round.exercises_and_super_sets.length > 1"
        :steps="round.exercises_and_super_sets"
        :reps="round.repetitions"
        :rep-rest="round.rep_rest"
        class="ml-n3 ml-md-n6 my-n3"
      />

      <div class="mt-4">
        <slot name="exerciseOrSuperset">
          <div>Loading...</div>
        </slot>
      </div>
    </template>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <v-divider v-if="!$vuetify.breakpoint.smAndUp" class="mb-6"></v-divider>
      <h4 class="title font-weight-bold my-3">Associated Exercises and Supersets</h4>
      <draggable
        :value="round.exercises_and_super_sets"
        group="workoutBuilder"
        class="draggable"
        @change="associationChange"
      >
        <exercise-card
          v-for="item in round.exercises_and_super_sets"
          :key="item.id"
          :exercise="item"
          parent-type="round"
          class="ma-1"
          editable
          @edit="editAssociation(item.type, item)"
          @view="$emit('view-object', item)"
        ></exercise-card>
      </draggable>
    </div>
  </div>
</template>

<script>
import ExerciseCard from "../Cards/ExerciseCard";
import PictureIcon from "../Avatars/IconAvatar";
import TimeBadge from "../Badges/TimeBadge";
import draggable from "vuedraggable";
import RoundStepper from "@/components/Navigation/RoundStepper";

export default {
  name: "Round",
  components: {
    RoundStepper,
    draggable,
    PictureIcon,
    TimeBadge,
    ExerciseCard
  },
  props: {
    round: {
      type: Object,
      required: true
    },
    isToday: {
      type: Boolean,
      default: false
    },
    builder: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: String,
      default: "total_time"
    }
  },
  data() {
    return {
      fab: false,
      descriptionOpen: false,
      highlightNext: false
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold";
    },
    buttonStyle() {
      return {
        textTransform: "none",
        maxWidth: "25vw",
        textOverflow: "ellipsis",
        overflow: "hidden"
      };
    }
  },
  methods: {
    completeExercise(formObject) {
      this.$emit("completeExercise", formObject);
      if (this.showNavigation) {
        this.$vuetify.goTo("#navigation");
        this.highlightNext = true;
      }
    },
    restartExercise(exerciseId) {
      this.$emit("restartExercise", exerciseId);
    },
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const item = event.added.element;
        const type = item.type === "Super Set" ? "superset" : "exercise";

        this.$emit("associateObject", { type: type, object: { ...item, sort_order: sortOrder } });
      }
      if ("moved" in event) {
        const newChildList = this.move(
          this.round.exercises_and_super_sets,
          event.moved.oldIndex,
          event.moved.newIndex
        );

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(type, object) {
      const formattedType = type === "Super Set" ? "superset" : "exercise";
      this.$emit("editAssociation", { type: formattedType, object: object });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
