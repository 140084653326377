export const objectSubTypes = [
  {
    text: "Cycles",
    value: "cycles",
    parentTypes: ["programs"]
  },
  {
    text: "Days",
    value: "days",
    parentTypes: ["cycles", "weeks"]
  },
  {
    text: "Weeks",
    value: "weeks",
    parentTypes: ["cycles"]
  },
  {
    text: "Rounds",
    value: "rounds",
    parentTypes: ["days"]
  },
  {
    text: "Supersets",
    value: "supersets",
    parentTypes: ["rounds"]
  },
  {
    text: "Exercises",
    value: "exercises",
    parentTypes: ["rounds", "supersets"]
  }
];

export const objectTypes = [
  {
    text: "Programs",
    value: "programs"
  },
  {
    text: "Cycles",
    value: "cycles"
  },
  {
    text: "Weeks",
    value: "weeks"
  },
  {
    text: "Days",
    value: "days"
  },
  {
    text: "Rounds",
    value: "rounds"
  },
  {
    text: "Supersets",
    value: "supersets"
  },
  {
    text: "Exercises",
    value: "exercises"
  }
];

export const listActions = {
  programs: "program/fetchTemplatePrograms",
  cycles: "cycle/fetchTemplateCycles",
  weeks: "week/fetchTemplateWeeks",
  days: "day/fetchTemplateDays",
  rounds: "round/fetchTemplateRounds",
  supersets: "superset/fetchTemplateSupersets",
  exercises: "exercise/fetchTemplateExercises"
};

export const listMutations = {
  programs: "program/SET_PROGRAMS",
  cycles: "cycle/SET_CYCLES",
  weeks: "week/SET_WEEKS",
  days: "day/SET_DAYS",
  rounds: "round/SET_ROUNDS",
  supersets: "superset/SET_SUPERSETS",
  exercises: "exercise/SET_EXERCISES"
};
