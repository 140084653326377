<template>
  <v-form>
    <v-switch v-model="form.hideIntroVideo" label="Hide Intro Video on login." hide-details />
    <v-radio-group v-model="form.weightUnit" label="Logbook Measurement System" mandatory>
      <v-radio label="Pounds (lbs)" value="lbs" />
      <v-radio label="Kilograms (kg)" value="kg" />
    </v-radio-group>
    <done-button :loading="loading" :complete="formComplete" @buttonClick="submit" class="mt-6">
      Submit
    </done-button>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import DoneButton from "@/components/Buttons/DoneButton";

export default {
  name: "SettingsForm",
  components: { DoneButton },
  data: () => ({
    loading: false,
    formComplete: true,
    form: {}
  }),
  mounted() {
    this.setForm();
  },
  computed: {
    ...mapState({ user: state => state.auth.user })
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.formComplete = false;
      }
    }
  },
  methods: {
    setForm() {
      this.form = {
        weightUnit: this.user.weightUnit || "lbs",
        hideIntroVideo: this.user.hideIntroVideo
      };
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch("auth/updateSettings", this.form);
        this.formComplete = true;

        this.loading = false;
        await this.$swal({
          icon: "success",
          title: "Settings updated!",
          showConfirmButton: false,
          toast: true,
          timer: 1000,
          timerProgressBar: true,
          position: "top-end"
        });
      } catch (error) {
        this.loading = false;
        await this.$swal({
          icon: "error",
          title: error.message || "Unable to update settings.",
          showConfirmButton: false,
          toast: true,
          timer: 1500,
          timerProgressBar: true,
          position: "top-end"
        });
      }
    }
  }
};
</script>

<style scoped></style>
