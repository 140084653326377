<template>
  <v-dialog :value="value" persistent dark width="1000">
    <v-card class="secondary">
      <v-card-title class="d-block py-4">
        <div class="text-h5 text-md-h4" style="opacity: .9">Welcome to TrainingBeta!</div>
        <div class="text-subtitle-1" style="opacity: .8">
          To get started please watch the video below.
        </div>
      </v-card-title>

      <v-responsive :aspect-ratio="16 / 9">
        <iframe
          src="https://www.youtube.com/embed/CfGn0CjBuRk"
          width="100%"
          height="100%"
          allowfullscreen
          style="border: 0"
        ></iframe>
      </v-responsive>

      <v-card-actions class="py-3">
        <v-checkbox
          label="Don't show again"
          v-model="checkbox"
          class="mt-0"
          dark
          hide-details
          dense
          @click="dontShowAgain"
        />
        <v-spacer />
        <v-btn text @click="$emit('input', false)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IntroVideoModal",
  props: {
    // v-model
    value: Boolean
  },
  data: () => ({
    checkbox: false
  }),
  methods: {
    dontShowAgain() {
      this.$store.dispatch("auth/hideIntroVideo");
    }
  }
};
</script>

<style scoped></style>
