<template>
  <LineGraph
    v-if="!loading"
    class="pa-3"
    :chartdata="dataCollection"
    :y-label="yAxisLabel"
  ></LineGraph>
</template>

<script>
import LineGraph from "./LineGraph";
import { mapState } from "vuex";

export default {
  name: "ProgramDataChart",
  components: { LineGraph },
  props: {
    programId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      defaultDataset: {
        backgroundColor: "transparent",
        borderWidth: 4,
        lineTension: 0,
        pointRadius: 4
      },
      dataStyles: [
        {
          borderColor: "#FCB128",
          pointBorderColor: "#FEE6BA",
          pointBackgroundColor: "#FEE6BA"
        },
        {
          borderColor: "#3d70b2",
          pointBorderColor: "#ebf0f7",
          pointBackgroundColor: "#ebf0f7"
        },
        {
          borderColor: "#3bb273",
          pointBorderColor: "#ebf7f1",
          pointBackgroundColor: "#ebf7f1"
        },
        {
          borderColor: "#e15554",
          pointBorderColor: "#fceeed",
          pointBackgroundColor: "#fceeed"
        }
      ]
    };
  },
  mounted() {
    this.getProgramData();
  },
  computed: {
    yAxisLabel() {
      switch (this.type) {
        case "finger_1":
        case "lifting_1":
          return `Weight (${this.user.weightUnit})`;
        case "finger_3":
        case "lifting_2":
          return `Reps / Weight (${this.user.weightUnit})`;
        default:
          return undefined;
      }
    },
    dataSets() {
      if (!this.loading) {
        return this.chartData.datasets.map((dataset, index) => {
          return {
            ...dataset,
            ...this.dataStyles[index],
            ...this.defaultDataset
          };
        });
      }
      return [];
    },
    dataCollection() {
      return {
        labels: this.chartData ? this.chartData.labels : [],
        datasets: this.dataSets
      };
    },
    ...mapState({ chartData: state => state.logbook.chartData, user: state => state.auth.user })
  },
  methods: {
    async getProgramData() {
      const chart = {
        programId: this.programId,
        exerciseType: this.type
      };
      await this.$store.dispatch("logbook/fetchChartData", chart);
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
