<template>
  <v-form class="mt-3">
    <v-row dense>
      <v-col cols="auto">
        <picture-icon :round-style="form.icon_type" small class="mr-3" />
      </v-col>
      <v-col lg="5">
        <v-select
          label="Round Type"
          outlined
          v-model="$v.form.icon_type.$model"
          :error-messages="iconErrors"
          :items="iconTypes"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="nameErrors"
      v-model="$v.form.name.$model"
    ></v-text-field>
    <v-text-field
      label="Subtitle"
      outlined
      type="text"
      :error-messages="subtitleErrors"
      v-model="$v.form.subtitle.$model"
    ></v-text-field>
    <v-text-field
      label="Image URL"
      outlined
      type="text"
      :error-messages="imageErrors"
      v-model="$v.form.image_url.$model"
    ></v-text-field>
    <vue-trix
      v-model="$v.form.description.$model"
      :class="descriptionErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Description"
    ></vue-trix>
    <trix-error-messages :error-messages="descriptionErrors"></trix-error-messages>
    <div v-if="association">
      <v-divider class="my-4"></v-divider>
      <h2 class="headline">Association Details</h2>
      <time-badge prefix="Total Calculated Time" :time="form.total_time" class="mb-3"></time-badge>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Round Reps"
            outlined
            type="number"
            v-model.number="form.repetitions"
            min="1"
            @input="updateTotalTime"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Rest Between Reps"
            outlined
            type="number"
            v-model.number="form.rep_rest"
            suffix="sec"
            min="0"
            @input="updateTotalTime"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import TrixErrorMessages from "../FormComponents/TrixErrorMessages";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import PictureIcon from "../../Avatars/IconAvatar";
import TimeBadge from "../../Badges/TimeBadge";
import TimeCalculations from "@/utils/TimeCalculations";

export default {
  name: "RoundForm",
  components: { TimeBadge, PictureIcon, ContinueButton, TrixErrorMessages },
  props: {
    round: {
      type: Object,
      required: false
    },
    association: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        icon_type: "Fingers",
        duration: null,
        subtitle: null,
        image_url: null,
        description: null
      },
      iconTypes: ["Fingers", "Lifting", "Climbing", "Cardio"]
    };
  },
  validations: {
    form: {
      name: { required },
      icon_type: { required },
      duration: { required: false },
      subtitle: { required: false },
      image_url: { required, url },
      description: { required: false }
    }
  },
  mounted() {
    if (this.round) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    round: function() {
      if (this.round) {
        this.setForm();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    /** Validation Errors **/
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    iconErrors() {
      const errors = [];
      if (!this.$v.form.icon_type.$dirty) return errors;
      !this.$v.form.icon_type.required && errors.push("Icon Type is required");
      return errors;
    },
    subtitleErrors() {
      const errors = [];
      if (!this.$v.form.subtitle.$dirty) return errors;
      !this.$v.form.subtitle.required && errors.push("Subtitle is required");
      return errors;
    },
    imageErrors() {
      const errors = [];
      if (!this.$v.form.image_url.$dirty) return errors;
      !this.$v.form.image_url.required && errors.push("Image URL is required");
      !this.$v.form.image_url.url && errors.push("Image URL must be a valid URL");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required && errors.push("Description is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.round.name,
        icon_type: this.round.icon_type,
        duration: this.round.duration,
        subtitle: this.round.subtitle,
        image_url: this.round.image_url,
        description: this.round.description,
        repetitions: this.round.repetitions || 1,
        rep_rest: this.round.rep_rest || 0,
        sort_order: this.round.sort_order
      };

      this.updateDuration();
      this.updateTotalTime();
    },
    updateDuration() {
      this.form.duration = TimeCalculations.getRoundDuration(this.round);
    },
    updateTotalTime() {
      this.form.total_time = TimeCalculations.getRoundTotalTime(this.form);
    },
    resetForm() {
      this.form = {
        name: null,
        icon_type: "Fingers",
        duration: null,
        subtitle: null,
        image_url: null,
        description: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
