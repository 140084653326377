<template>
  <div :key="`${objectType}-${id}`">
    <component v-if="loading" :is="skeletonLoader" builder></component>
    <component
      v-if="!loading"
      :is="formattedType"
      v-bind:[singularType]="object"
      builder
      :show-time="showTime"
      @associateObject="createAssociation"
      @editAssociation="editAssociation"
      @sortAssociations="sortAssociations"
      @view-object="routeToObject"
    >
      <template v-slot:menu>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn fab small color="#3d70b2" v-on="on" dark @click="editObject">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </component>
    <WorkoutBuilderAssociationModal
      :modalOpen="associationModalOpen"
      :parent-type="singularType"
      :child-type="associationChild"
      :association-object="associationObject"
      :editing="associationEditing"
      @setModal="updateModal"
    ></WorkoutBuilderAssociationModal>
  </div>
</template>

<script>
import Program from "./Program";
import Cycle from "./Cycle";
import Week from "./Week";
import Day from "./Day";
import Round from "./Round";
import Superset from "./Superset";
import Exercise from "./Exercise";

import ProgramSkeleton from "../SkeletonLoaders/Objects/ProgramSkeleton";
import CycleSkeleton from "../SkeletonLoaders/Objects/CycleSkeleton";
import WeekSkeleton from "../SkeletonLoaders/Objects/WeekSkeleton";
import DaySkeleton from "../SkeletonLoaders/Objects/DaySkeleton";
import RoundSkeleton from "../SkeletonLoaders/Objects/RoundSkeleton";
import SupersetSkeleton from "../SkeletonLoaders/Objects/SupersetSkeleton";
import ExerciseSkeleton from "../SkeletonLoaders/Objects/ExerciseSkeleton";
import WorkoutBuilderAssociationModal from "../Modals/WorkoutBuilderAssociationModal";

/**
 * This Component is a wrapper for other Objects
 * it is used in the Workout Builder
 **/
export default {
  name: "ObjectView",
  components: {
    WorkoutBuilderAssociationModal,
    // Objects
    Program,
    Cycle,
    Week,
    Day,
    Round,
    Superset,
    Exercise,
    // Skeleton Loaders
    ProgramSkeleton,
    CycleSkeleton,
    WeekSkeleton,
    DaySkeleton,
    RoundSkeleton,
    SupersetSkeleton,
    ExerciseSkeleton
  },
  props: {
    objectType: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    showTime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      associationModalOpen: false,
      associationChild: "",
      associationObject: {},
      associationEditing: false
    };
  },
  mounted() {
    this.fetchObject();
  },
  computed: {
    singularType() {
      return this.objectType.substring(0, this.objectType.length - 1);
    },
    formattedType() {
      return (
        this.objectType[0].toUpperCase() + this.objectType.substr(1, this.objectType.length - 2)
      );
    },
    object() {
      switch (this.objectType) {
        case "programs":
          return this.$store.state.program.program;
        case "cycles":
          return this.$store.state.cycle.cycle;
        case "weeks":
          return this.$store.state.week.week;
        case "days":
          return this.$store.state.day.day;
        case "rounds":
          return this.$store.state.round.round;
        case "supersets":
          return this.$store.state.superset.superset;
        case "exercises":
          return this.$store.state.exercise.exercise;
        default:
          return null;
      }
    },
    skeletonLoader() {
      return `${this.formattedType}Skeleton`;
    }
  },
  watch: {
    objectType: "fetchObject",
    id: "fetchObject"
  },
  methods: {
    routeToObject(object) {
      const type = `${object.type.split(" ")[0].toLowerCase()}s`;
      this.$router.push({
        name: "Workout Builder - View",
        params: {
          objectType: type,
          id: object.id
        }
      });
    },
    updateModal(value) {
      this.associationModalOpen = value;
    },
    async fetchObject() {
      this.loading = true;
      const action = `${this.singularType}/fetchTemplate${this.formattedType}`;
      await this.$store.dispatch(action, this.id);
      this.loading = false;
    },
    editObject() {
      this.$emit("open-edit-modal", this.object);
    },
    createAssociation({ type, object }) {
      this.associationChild = type;
      this.associationObject = object;
      this.associationEditing = false;
      this.updateModal(true);
    },
    editAssociation({ type, object }) {
      this.associationChild = type;
      this.associationObject = object;
      this.associationEditing = true;
      this.updateModal(true);
    },
    sortAssociations(newChildList) {
      const action = {
        program: `program/sortCycles`,
        cycle: `cycle/sortDaysAndWeeks`,
        week: `week/sortDays`,
        day: `day/sortRounds`,
        round: `round/sortExercisesAndSupersets`,
        superset: `superset/sortExercises`
      };
      try {
        this.$store.dispatch(action[this.singularType], {
          parentId: this.id,
          childList: newChildList
        });
      } catch (error) {
        this.$swal.fire({
          title: "Error!",
          text: error,
          icon: "error"
        });
      }
    }
  }
};
</script>

<style scoped></style>
