<template>
  <v-container fluid>
    <v-row align="center" dense class="mb-3">
      <v-col cols="auto">
        <h1 :class="headerClass">{{ day.name }}</h1>
        <h2 class="body-1 mt-2">{{ day.subtitle }}</h2>
      </v-col>
      <v-col cols="auto">
        <time-badge v-if="day.duration" :time="parseInt(day.duration)" class="ml-5" />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndUp">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- Description -->
    <v-expansion-panels v-if="day.description" v-model="descriptionOpen" flat tile accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="#f4f5f5" class="px-0">
          <div class="d-flex align-items-center">
            <h2 class="title font-weight-bold">Day Description</h2>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f4f5f5">
          <p class="trix-content" v-html="day.description"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider v-if="!$vuetify.breakpoint.smAndUp" class="mb-6"></v-divider>
    <!-- Rounds -->
    <v-row v-if="!builder">
      <v-col cols="12" md="6" lg="4" xl="4" v-for="round in day.rounds" :key="round.id">
        <RoundCard
          :round="round"
          @click="
            selectRound(
              round.route_hash_key,
              round.exercises_and_super_sets[0].type,
              round.exercises_and_super_sets[0].route_hash_key
            )
          "
        />
      </v-col>
    </v-row>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <h4 class="title font-weight-bold mb-3">Associated Rounds</h4>
      <draggable
        class="draggable"
        :value="day.rounds"
        group="workoutBuilder"
        @change="associationChange"
      >
        <round-card
          v-for="round in day.rounds"
          :key="round.id"
          :round="round"
          editable
          hide-image
          show-time="total_time"
          class="mx-3 mx-lg-auto my-2"
          @edit="editAssociation(round)"
          @view="$emit('view-object', round)"
        ></round-card>
      </draggable>
    </div>
  </v-container>
</template>

<script>
import RoundCard from "../Cards/RoundCard";

import draggable from "vuedraggable";
import TimeBadge from "../Badges/TimeBadge";

export default {
  name: "Day",
  components: { TimeBadge, draggable, RoundCard },
  props: {
    day: {
      type: Object,
      required: true
    },
    builder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      descriptionOpen: false
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold mr-12 pr-3";
    }
  },
  methods: {
    selectRound(roundHash, type, itemHash) {
      const itemType = type.includes("Exercise") ? "exercise" : "superset";
      this.$emit("roundClicked", { roundHash, itemType, itemHash });
    },
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const round = event.added.element;

        this.$emit("associateObject", {
          type: "round",
          object: { ...round, sort_order: sortOrder }
        });
      }
      if ("moved" in event) {
        const newChildList = this.move(this.day.rounds, event.moved.oldIndex, event.moved.newIndex);

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(round) {
      this.$emit("editAssociation", { type: "round", object: round });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>
