<template>
  <v-bottom-navigation app grow background-color="#29304e">
    <v-btn v-for="item in items" :key="item.text" :to="{ name: item.linkTo }" exact>
      <v-icon color="white">{{ item.icon }}</v-icon>
      <span class="white--text">{{ item.text }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "BottomNavBar",
  props: {},
  data() {
    return {
      items: [
        {
          icon: "mdi-book-variant-multiple",
          text: "My Program",
          linkTo: "My Program"
        },
        {
          icon: "mdi-poll",
          text: "Logbook",
          linkTo: "Logbook"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
