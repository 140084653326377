import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    week: null,
    weeks: [{}, {}]
  },
  mutations: {
    SET_WEEK(state, week) {
      state.week = week;
    },
    SET_WEEKS(state, weeks) {
      state.weeks = weeks;
    }
  },
  actions: {
    async fetchWeekByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateWeeks({ commit, state }, query) {
      const response = await StaffService.getWeeks(query);
      const weeks = query.page > 1 ? [...state.weeks, ...response.data.data] : response.data.data;

      commit("SET_WEEKS", weeks);
      return response.data;
    },
    async fetchTemplateWeek({ commit }, id) {
      const response = await StaffService.getWeek(id);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async createWeek({ commit }, form) {
      const response = await StaffService.createWeek(form);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async editWeek({ commit }, { id, form }) {
      const response = await StaffService.updateWeek(id, form);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async deleteWeek({ commit, dispatch }, id) {
      const response = await StaffService.deleteWeek(id);
      commit("SET_WEEK", null);
      dispatch("fetchTemplateWeek");
      return response.data.data;
    },
    async sortDaysAndWeeks({ commit }, { parentId, childList }) {
      commit("SET_WEEK_DAYS", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortWeekDays(parentId, childIds);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async associateDay({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addDayToWeek(parentId, childId, association);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async updateAssociationDay({ commit }, { childId, association }) {
      const response = await StaffService.updateWeekDay(childId, association);
      commit("SET_WEEK", response.data.data);
      return response.data.data;
    },
    async removeAssociationDay({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeWeekDay(childId);
      dispatch("fetchTemplateWeek", parentId);
      return response.data.data;
    }
  },
  getters: {}
};
