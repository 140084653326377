<template>
  <v-row id="navigation" align="center" class="mt-2">
    <v-col cols="auto">
      <v-btn v-if="showPrev" text large rounded class="text-none" @click="goToPrev">
        <v-avatar left color="#3d70b2" class="mr-4" size="35">
          <v-icon color="white" small>mdi-arrow-left</v-icon>
        </v-avatar>
        Previous
      </v-btn>
    </v-col>
    <v-col>
      <v-divider></v-divider>
    </v-col>
    <v-col cols="auto">
      <v-btn
        v-if="showNext"
        text
        large
        rounded
        class="text-none"
        :class="{ 'highlight-button': highlightNext }"
        @click="goToNext"
      >
        Next
        <v-avatar left color="#3d70b2" class="ml-4" size="35">
          <v-icon color="white" small>mdi-arrow-right</v-icon>
        </v-avatar>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExerciseNav",
  props: {
    highlightNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    routeTree() {
      return this.$route.name.includes("My Program") ? "my-program" : "programs";
    },
    showNext() {
      return !!this.nextExercise;
    },
    showPrev() {
      return !!this.prevExercise;
    },
    ...mapGetters({
      nextExercise: "exercise/nextExercise",
      prevExercise: "exercise/prevExercise"
    })
  },
  watch: {
    highlightNext: "checkIfLast"
  },
  methods: {
    goToPrev() {
      this.$emit("prev-clicked");
      this.$router.push(`/app/${this.routeTree}/${this.prevExercise.route}`);
    },
    goToNext() {
      this.$emit("next-clicked");
      this.$router.push(`/app/${this.routeTree}/${this.nextExercise.route}`);
    },
    async checkIfLast() {
      if (this.highlightNext && !this.showNext) {
        const result = await this.$swal({
          title: "You have completed this session!",
          text: "Go back to your program to see your progress or start the next session.",
          confirmButtonText: "Back to My Program"
        });
        if (result.value) {
          await this.$router.push({ name: "My Program" });
        }
      }
    }
  }
};
</script>

<style scoped></style>
