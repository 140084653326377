<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M27.2521 19.5088C26.3744 19.5088 25.4968 19.1746 24.8291 18.5068L13.4957 7.17224C12.159 5.83559 12.159 3.66118 13.4957 2.32452L13.6287 2.19144C14.9648 0.855375 17.1386 0.855375 18.4747 2.19144L21.0378 4.75454C21.4605 5.17781 21.4605 5.86314 21.0372 6.28524C20.6145 6.70852 19.9292 6.70735 19.5071 6.28524L16.9446 3.72215C16.4715 3.24787 15.6325 3.24787 15.1594 3.72215L15.0264 3.85523C14.7889 4.09207 14.6588 4.40924 14.6588 4.74868C14.6588 5.08812 14.7889 5.40528 15.0258 5.64154L26.3598 16.9761C26.8522 17.4692 27.6531 17.4692 28.1455 16.9756L28.2786 16.8425C28.5149 16.6062 28.6456 16.2891 28.6456 15.9502C28.6456 15.6108 28.5149 15.2936 28.2786 15.0573L21.7343 8.513C21.3116 8.08973 21.3116 7.4044 21.7343 6.9823C22.1575 6.55961 22.8429 6.55961 23.2644 6.9823L29.8081 13.526C31.1442 14.8627 31.1448 17.0371 29.8081 18.3738L29.675 18.5068C29.0073 19.1746 28.1297 19.5088 27.2521 19.5088Z"
        fill="#4CABE9"
      />
      <path
        d="M28.9175 15.2479C28.6402 15.2479 28.3635 15.1423 28.1519 14.9313C27.7292 14.508 27.7292 13.8233 28.1519 13.4006L29.607 11.9455C29.981 11.5709 29.8878 10.8457 29.4041 10.362L21.6392 2.59594C21.1555 2.1117 20.4292 2.01966 20.0557 2.3931L18.6001 3.84818C18.1774 4.27145 17.492 4.27086 17.0699 3.84759C16.6473 3.4249 16.6473 2.73957 17.0699 2.31747L18.5256 0.862393C19.7509 -0.361701 21.835 -0.272005 23.1705 1.06524L30.9354 8.83074C32.2715 10.1668 32.3629 12.2503 31.1388 13.475L29.6832 14.9313C29.4715 15.1423 29.1948 15.2479 28.9175 15.2479Z"
        fill="#4CABE9"
      />
      <path
        d="M25.2936 5.80153C25.0163 5.80153 24.7396 5.69601 24.528 5.48496C24.1053 5.06168 24.1053 4.37694 24.528 3.95425L26.6015 1.88068C27.0242 1.45799 27.7095 1.45799 28.1316 1.88068L30.1185 3.86749C30.5417 4.29076 30.5417 4.9755 30.1185 5.39819C29.6958 5.82088 29.0104 5.82088 28.5883 5.39819L27.3672 4.17644L26.0587 5.48496C25.847 5.69601 25.5703 5.80153 25.2936 5.80153Z"
        fill="#4CABE9"
      />
      <path
        d="M15.9501 30.8116C15.0724 30.8116 14.1948 30.4781 13.5265 29.8097L6.98275 23.266C6.56006 22.8433 6.56006 22.158 6.98275 21.7353C7.40544 21.3126 8.09077 21.3126 8.51287 21.7353L15.0572 28.279C15.5309 28.7533 16.3698 28.7533 16.8435 28.279L16.9766 28.146C17.2129 27.9097 17.3436 27.5925 17.3436 27.2537C17.3436 26.9148 17.2129 26.5977 16.9766 26.3614L5.64199 15.0262C5.16889 14.5519 4.32937 14.5519 3.85627 15.0262L3.72319 15.1587C3.48693 15.395 3.35619 15.7121 3.35619 16.051C3.35619 16.3898 3.48693 16.707 3.72319 16.9433L6.28628 19.5058C6.70897 19.9285 6.70897 20.6138 6.28628 21.0365C5.86301 21.4592 5.17827 21.4592 4.75558 21.0365L2.19248 18.474C0.856412 17.1379 0.856412 14.9635 2.19248 13.6268L2.32556 13.4938C3.66163 12.1577 5.83604 12.1577 7.1727 13.4938L18.5067 24.8284C19.8428 26.1644 19.8428 28.3388 18.5067 29.6755L18.3736 29.8086C17.7053 30.4775 16.8277 30.8116 15.9501 30.8116Z"
        fill="#4CABE9"
      />
      <path
        d="M11.3389 32.0011C10.4519 32.0011 9.53681 31.6429 8.83037 30.9365L1.06487 23.1704C-0.271197 21.8344 -0.362652 19.7508 0.862029 18.5261L2.31769 17.0699C2.74038 16.6472 3.42571 16.6472 3.84781 17.0699C4.2705 17.4932 4.2705 18.1779 3.84781 18.6006L2.39273 20.0557C2.0187 20.4303 2.11133 21.1555 2.59499 21.6391L10.3605 29.4046C10.8447 29.8889 11.5699 29.9803 11.9445 29.6075L13.4002 28.1524C13.8229 27.7297 14.5088 27.7291 14.9309 28.153C15.3536 28.5757 15.3536 29.261 14.9309 29.6831L13.4752 31.1382C12.8978 31.7162 12.1298 32.0011 11.3389 32.0011Z"
        fill="#4CABE9"
      />
      <path
        d="M4.63236 30.4364C4.35506 30.4364 4.07835 30.3309 3.8673 30.1199L1.88049 28.133C1.4578 27.7104 1.4578 27.025 1.88049 26.6023L3.95348 24.5288C4.37617 24.1067 5.0615 24.1061 5.48419 24.5288C5.90687 24.9515 5.90687 25.6368 5.48419 26.0595L4.17626 27.3674L5.39742 28.5886C5.82011 29.0118 5.82011 29.6966 5.39742 30.1193C5.18637 30.3309 4.90966 30.4364 4.63236 30.4364Z"
        fill="#4CABE9"
      />
      <path
        d="M10.7061 19.6424C10.4294 19.6424 10.1521 19.5369 9.94104 19.3258C9.51835 18.9031 9.51835 18.2178 9.94104 17.7951L15.6892 12.0457C16.1125 11.6236 16.7973 11.6231 17.2194 12.0457C17.6426 12.4684 17.6426 13.1538 17.2199 13.5764L11.4717 19.3258C11.2601 19.5369 10.9828 19.6424 10.7061 19.6424Z"
        fill="#4CABE9"
      />
      <path
        d="M13.4415 22.379C13.1642 22.379 12.8874 22.2735 12.6764 22.0624C12.2537 21.6392 12.2537 20.9544 12.6764 20.5317L18.4252 14.7823C18.8479 14.3597 19.5332 14.3597 19.9553 14.7823C20.3786 15.2056 20.3786 15.8904 19.9553 16.3131L14.2065 22.0624C13.9955 22.2735 13.7182 22.379 13.4415 22.379Z"
        fill="#4CABE9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32.0012" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "DumbbellIcon"
};
</script>

<style scoped></style>
