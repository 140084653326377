<template>
  <div>
    <program-skeleton v-if="loading"></program-skeleton>
    <v-container v-if="!loading" fluid>
      <v-row align="center" class="flex-nowrap">
        <v-col cols="auto">
          <program-progress-circle :progress="programCompletion" />
        </v-col>
        <v-col cols="auto">
          <h1 class="display-1 font-weight-bold headline font-weight-bold">
            {{ program.name }}
          </h1>
          <h2 class="body-1 text--secondary">
            {{ completedDayCount }} sessions of {{ totalDayCount }} completed
          </h2>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="fill-height">
        <v-col cols="12" lg="8" xl="6" class="d-flex flex-column">
          <v-sheet
            id="timeline-container"
            color="transparent"
            :max-height="
              $vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xlOnly ? '70vh' : '60vh') : '50vh'
            "
            class="overflow-x-auto mt-6"
          >
            <v-timeline dense>
              <v-timeline-item
                v-for="note in formattedNotes"
                :key="note.date"
                small
                fill-dot
                color="#508fdd"
                icon="mdi-check"
              >
                <logbook-entry-card :logbook-entry="note" @edit="editExercise(note)" />
              </v-timeline-item>
            </v-timeline>
            <infinite-loading
              :identifier="infiniteId"
              direction="bottom"
              @infinite="fetchNotes"
              spinner="waveDots"
            >
              <template #no-results>
                <div class="mt-6">
                  No notes found, complete exercises to see a log of entries here.
                </div>
              </template>
              <span slot="no-more" />
            </infinite-loading>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="4">
          <v-form>
            <v-select
              label="Graph Type"
              v-model="chartForm.exerciseType"
              outlined
              hide-details
              class="mt-4 mb-3"
              :items="exerciseTypes"
            ></v-select>
          </v-form>
          <v-card min-height="400px" max-height="70vh">
            <v-card-text class="px-2">
              <program-data-chart
                :key="chartKey"
                :program-id="program.id"
                :type="chartForm.exerciseType"
              ></program-data-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <exercise-log-modal
      v-if="exerciseToEdit"
      :modalOpen="isModalOpen"
      :exercise="exerciseToEdit"
      @setModal="updateModal(false)"
      @submitForm="updateExercise"
    />
  </div>
</template>

<script>
import ProgramSkeleton from "../SkeletonLoaders/Objects/ProgramSkeleton";
import ProgramProgressCircle from "../ProgressBars/ProgramProgressCircle";
import { mapGetters, mapState } from "vuex";
import ProgramDataChart from "../Charts/ProgramDataChart";
import ExerciseLogModal from "@/components/Modals/ExerciseLogModal";
import LogbookEntryCard from "@/components/Cards/LogbookEntryCard";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "ProgramLogbook",
  components: {
    LogbookEntryCard,
    ExerciseLogModal,
    ProgramDataChart,
    ProgramProgressCircle,
    ProgramSkeleton,
    InfiniteLoading
  },
  props: {
    currentProgram: {
      type: Boolean,
      default: false
    },
    programId: String
  },
  mounted() {
    this.fetchProgramData();
  },
  data() {
    return {
      loading: true,
      // Edit modal
      isModalOpen: false,
      exerciseToEdit: undefined,
      // Note pagination
      page: 1,
      perPage: 10,
      infiniteId: +new Date(),
      // charts
      chartForm: {
        exerciseType: "finger_1"
      },
      exerciseTypes: [
        {
          text: "Average Finger Weight",
          value: "finger_1"
        },
        {
          text: "Average Finger Reps & Weight",
          value: "finger_3"
        },
        {
          text: "Average Lifting Weight",
          value: "lifting_1"
        },
        {
          text: "Average Lifting Reps & Weight",
          value: "lifting_2"
        }
      ]
    };
  },
  computed: {
    headerClass() {
      return this.$vuetify.breakpoint.mdAndUp
        ? "display-1 font-weight-bold"
        : "headline font-weight-bold";
    },
    programCompletion() {
      return Math.round((this.completedDayCount / this.totalDayCount) * 100);
    },
    internalProgramId() {
      return this.programId ?? this.activeProgramId;
    },
    formattedNotes() {
      return this.notes?.map(completion => ({
        ...completion,
        displayData: {
          grip_size: completion.grip_size,
          hardest_grade: completion.hardest_grade,
          notes: completion.notes,
          sets: completion.sets
        }
      }));
    },
    chartKey() {
      return `${this.program.id}-${this.chartForm.exerciseType}`;
    },
    ...mapState({
      program: state => state.logbook.program,
      notes: state => state.logbook.notes
    }),
    ...mapGetters({
      totalDayCount: "logbook/totalDayCount",
      completedDayCount: "logbook/completedDayCount",
      activeProgramId: "auth/activeProgramId"
    })
  },
  methods: {
    updateModal(val) {
      this.isModalOpen = val;
    },
    async fetchProgramData() {
      // Fetch program
      if (!!this.internalProgramId) {
        await this.$store.dispatch("logbook/fetchProgram", this.internalProgramId);
      } else {
        await this.$router.push({ name: "Training Programs" });
      }

      this.loading = false;
    },
    async fetchNotes($state) {
      const { page, perPage } = this;
      const notes = await this.$store.dispatch("logbook/fetchNotes", {
        programId: this.internalProgramId,
        query: {
          page,
          perPage
        }
      });

      if (notes.length > 0) {
        this.page += 1;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    editExercise(completion) {
      this.exerciseToEdit = {
        sets: completion.sets.length,
        logbook: {
          type: completion.type,
          completion_status: true,
          completion_info: completion
        }
      };
      this.updateModal(true);
    },
    async updateExercise(form) {
      const exerciseLogbook = this.exerciseToEdit.logbook.completion_info;
      try {
        await this.$store.dispatch("exercise/completeExercise", {
          programId: this.internalProgramId,
          exerciseHash: exerciseLogbook.route_hash_key,
          form: {
            ...form,
            route_hash_key: exerciseLogbook.route_hash_key,
            route_array: exerciseLogbook.route_array
          }
        });
        this.page = 1;
        this.infiniteId = +new Date();
      } catch (error) {
        await this.$swal({
          icon: "error",
          title: "Error",
          text: error.message
        });
      }
      this.updateModal(false);
    }
  }
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
#timeline-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#timeline-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
