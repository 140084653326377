<template>
  <v-skeleton-loader type="image" elevation="3" loading />
</template>

<script>
export default {
  name: "ProgramCardSkeleton"
};
</script>

<style scoped></style>
