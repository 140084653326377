<template>
  <round-skeleton v-if="loading"></round-skeleton>
  <round v-else :round="currentRound" :is-today="myProgram">
    <template #exerciseOrSuperset>
      <router-view :hide-title="!showTitle" @exercise-changed="fetchRound" />
    </template>
  </round>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import store from "../../store";
import NProgress from "nprogress";
import Round from "../../components/Objects/Round";
import RoundSkeleton from "../../components/SkeletonLoaders/Objects/RoundSkeleton";

export default {
  name: "RoundPage",
  metaInfo: {
    title: "View Round"
  },
  props: {
    roundHash: String,
    childHash: String,
    myProgram: {
      type: Boolean,
      default: false
    }
  },
  components: { RoundSkeleton, Round },
  data() {
    return {
      loading: true,
      fab: false
    };
  },
  created() {
    this.fetchRound();
  },
  computed: {
    showTitle() {
      return this.currentRound.exercises_and_super_sets.length > 1;
    },
    ...mapState({ currentRound: state => state.round.round })
  },
  watch: {
    roundHash: "fetchRound"
  },
  methods: {
    async fetchRound() {
      await this.$store.dispatch("round/fetchRoundByHash", this.roundHash);
      if (!this.childHash) {
        // Update the route to include the first child hash
        const routeName = this.myProgram ? "My Program - Round" : "Round";
        const child = this.currentRound.exercises_and_super_sets[0];
        const childType = child.type.includes("Exercise") ? "exercise" : "superset";
        await this.$router.push({
          name: routeName,
          params: { childHash: child.route_hash_key, childType }
        });
      }
      this.loading = false;
    }
  }
};
</script>
