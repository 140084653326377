<template>
  <div>
    <v-alert type="info" dense prominent dismissible outlined>
      <h3 class="text-subtitle-1 font-weight-bold">
        Please ensure that all items have sub-items and that everything ends with an exercise.
      </h3>
      <h5 class="text-subtitle-2">
        This ensures that everything displays properly and that durations are calculated correctly.
      </h5>
    </v-alert>
    <v-row>
      <v-col>
        <object-view
          :id="id"
          ref="object"
          :objectType="objectType"
          show-time="duration"
          @open-edit-modal="setEditObject"
        />
      </v-col>
      <v-col cols="12" md="4" lg="4" v-if="objectType !== 'exercises'">
        <v-row align="center">
          <v-col v-if="subTypeOptions.length > 1">
            <v-select
              label="Sub-type"
              :value="subType"
              :items="subTypeOptions"
              outlined
              hide-details
              @change="updateSubType"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="Title"
              v-model.trim="titleSearch"
              placeholder="Search by title"
              append-icon="mdi-magnify"
              outlined
              hide-details
              @input="updateQuery"
            />
          </v-col>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <div class="pa-2">
          <p class="text--secondary">
            Drag item from this list into the list on the left to add it
          </p>
        </div>
        <item-list
          template
          :object-type="subType"
          :query="subObjectQuery"
          show-time="duration"
          :style="{ maxHeight: '75vh' }"
          class="overflow-y-auto hide-scroll-bars"
        />
      </v-col>
    </v-row>
    <workout-builder-modal
      :modalOpen="modalOpen"
      :object-type="objectType"
      :object="editObject"
      @setModal="updateModal"
    ></workout-builder-modal>
  </div>
</template>

<script>
import ObjectView from "@/components/Objects/ObjectView";
import WorkoutBuilderModal from "@/components/Modals/WorkoutBuilderModal";
import { objectSubTypes } from "@/utils/Constants";
import ItemList from "@/components/Lists/ItemList";

export default {
  name: "BuilderObject",
  components: {
    ItemList,
    WorkoutBuilderModal,
    ObjectView
  },
  props: {
    id: String,
    objectType: String
  },
  data: () => ({
    modalOpen: false,
    editObject: undefined,
    titleSearch: undefined,
    subObjectQuery: {
      filterName: undefined,
      sort: "name"
    }
  }),
  computed: {
    subType() {
      if (this.subTypeOptions.length > 0) {
        return this.$route.query.subtype ?? this.subTypeOptions[0].value;
      }
      return undefined;
    },
    subTypeOptions() {
      return objectSubTypes.filter(subType => subType.parentTypes.includes(this.objectType));
    }
  },
  methods: {
    updateModal(value) {
      this.modalOpen = value;
    },
    updateSubType(event) {
      this.$router.push({
        path: this.$route.path,
        query: { subtype: event }
      });
    },
    updateQuery() {
      this.subObjectQuery.filterName = this.titleSearch;
    },
    setEditObject(object) {
      this.editObject = object;
      this.updateModal(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .hide-scroll-bars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
