<template>
  <program-card-skeleton v-if="loading" />
  <v-card v-else @click="$emit('click', program.id)" :height="cardHeight">
    <v-img
      :src="program.image_url"
      :gradient="showTitle ? `to bottom, rgba(38,50,56,0) 40%, rgba(38,50,56,100)` : null"
      :height="cardHeight"
      :class="isBookmarked ? 'active-border white--text align-end' : 'white--text align-end'"
      :alt="program.name"
    >
      <div class="float-top">
        <v-chip v-if="program.new" color="#4CABE9" dark class="ma-3 font-weight-black" small
          >NEW
        </v-chip>
        <v-chip v-if="program.recommended" color="#D9374C" dark class="ma-3 font-weight-black" small
          >RECOMMENDED
        </v-chip>
        <v-tooltip v-if="isBookmarked" bottom>
          <template v-slot:activator="{ on }">
            <v-chip color="white" v-on="on" label class="ma-3 float-right">
              <v-icon color="blue">mdi-bookmark</v-icon>
            </v-chip>
          </template>
          <span>Current Program</span>
        </v-tooltip>
      </div>
      <v-card-title v-if="showTitle" v-text="program.name" class="font-weight-bold"></v-card-title>
    </v-img>
  </v-card>
</template>

<script>
import ProgramCardSkeleton from "@/components/SkeletonLoaders/Cards/ProgramCardSkeleton";

export default {
  name: "ProgramCard",
  components: { ProgramCardSkeleton },
  props: {
    program: {
      type: Object
    },
    isBookmarked: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return !!!this.program.id;
    },
    cardHeight() {
      return this.$vuetify.breakpoint.xl ? 400 : 275;
    }
  }
};
</script>

<style scoped>
.active-border {
  border: 4px solid rgba(36, 115, 213, 100);
}

.float-top {
  position: absolute;
  width: 100%;
  top: 0;
}

.v-skeleton-loader__image {
  height: 300px;
}

.v-card__title {
  word-break: break-word;
}
</style>
