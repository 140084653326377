<template>
  <div>
    <p class="text-grey-80">Please choose one Training Program to continue.</p>
    <programs-list :query="programsQuery" @programClicked="programClicked" class="mt-3" />
    <div v-if="selectedProgramId" class="d-flex justify-center mt-6">
      <ContinueButton @buttonClick="selectProgram" />
    </div>
  </div>
</template>

<script>
import ContinueButton from "../../components/Buttons/ContinueButton";
import { mapGetters, mapState } from "vuex";
import store from "../../store";
import ProgramsList from "../../components/Lists/ProgramsList";

export default {
  name: "allPrograms",
  metaInfo: {
    title: "All Programs"
  },
  components: { ProgramsList, ContinueButton },
  computed: {
    programsQuery() {
      return {
        filterCategory: this.$route.query.type || "Bouldering",
        filterPublished: true,
        sort: "sort_order"
      };
    },
    ...mapState({ programs: state => state.program.programs }),
    ...mapGetters({ selectedProgramId: "auth/activeProgramId" })
  },
  methods: {
    programClicked(id) {
      this.$router.push({ name: "Program", params: { programId: id } });
    },
    selectProgram() {
      this.$router.push({ name: "My Program" });
    }
  }
};
</script>

<style scoped></style>
