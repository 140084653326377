<template>
  <div :key="superset.id">
    <v-row align="center" dense>
      <v-col cols="auto">
        <div class="text-h5 font-weight-bold">
          <picture-icon roundStyle="Superset" class="mr-3" />
          <span>{{ superset.name }}</span>
          <TimeBadge :time="supersetTime" class="ml-4" />
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <slot name="menu"></slot>
      </v-col>
    </v-row>
    <!-- Description -->
    <v-expansion-panels v-if="superset.description" v-model="descriptionOpen" flat tile accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="lightBg" class="px-0">
          <div class="text-subtitle-1 font-weight-bold text--secondary">Superset Description</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBg">
          <p class="trix-content text-body-2 mx-n6" v-html="superset.description"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- If not in builder mode, display exercise carousel -->
    <template v-if="!builder">
      <superset-stepper
        :key="superset.id"
        :steps="superset.exercises"
        :reps="superset.repetitions"
        :rep-rest="superset.rep_rest"
        :superset-rest="superset.super_set_rest"
        class="ml-n3 ml-md-n6 mt-n3"
      ></superset-stepper>
      <!-- Exercises -->
      <slot name="exercise">
        <div>Loading...</div>
      </slot>
    </template>
    <!-- If in builder mode, display draggable areas -->
    <div v-if="builder">
      <v-divider class="mb-6"></v-divider>
      <h4 class="title font-weight-bold mb-3">Associated Exercises</h4>
      <draggable
        :value="superset.exercises"
        group="workoutBuilder"
        class="draggable"
        @change="associationChange"
      >
        <exercise-card
          v-for="exercise in superset.exercises"
          :key="exercise.id"
          :exercise="exercise"
          class="ma-1"
          editable
          @edit="editAssociation('exercise', exercise)"
          @view="$emit('view-object', exercise)"
        ></exercise-card>
      </draggable>
    </div>
  </div>
</template>

<script>
import PictureIcon from "../Avatars/IconAvatar";
import TimeBadge from "../Badges/TimeBadge";
import draggable from "vuedraggable";
import ExerciseCard from "../Cards/ExerciseCard";
import SupersetStepper from "@/components/Navigation/SupersetStepper";

export default {
  name: "Superset",
  components: {
    SupersetStepper,
    ExerciseCard,
    draggable,
    PictureIcon,
    TimeBadge
  },
  props: {
    superset: {
      type: Object,
      required: true
    },
    isToday: {
      type: Boolean,
      default: false
    },
    builder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fab: false,
      descriptionOpen: false,
      highlightNext: false
    };
  },
  computed: {
    supersetTime() {
      return this.superset.total_time ? this.superset.total_time : this.superset.duration;
    }
  },
  methods: {
    completeExercise(formObject) {
      this.$emit("completeExercise", formObject);
      this.$vuetify.goTo("#navigation");
      this.highlightNext = true;
    },
    restartExercise(exerciseId) {
      this.$emit("restartExercise", exerciseId);
    },
    /* Builder Functions */
    associationChange(event) {
      if ("added" in event) {
        const sortOrder = event.added.newIndex + 1;
        const item = event.added.element;
        this.$emit("associateObject", {
          type: "exercise",
          object: {
            ...item,
            sort_order: sortOrder
          }
        });
      }
      if ("moved" in event) {
        const newChildList = this.move(
          this.superset.exercises,
          event.moved.oldIndex,
          event.moved.newIndex
        );

        this.$emit("sortAssociations", newChildList);
      }
    },
    editAssociation(type, object) {
      this.$emit("editAssociation", {
        type: type,
        object: object
      });
    },
    move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while (k-- + 1) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  }
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
