<template>
  <v-progress-circular
    :rotate="270"
    :size="circle.size"
    :width="circle.width"
    :value="progress"
    color="#E57373"
  >
    <div class="text-h6 text-md-h5 font-weight-black">{{ progress }}%</div>
  </v-progress-circular>
</template>

<script>
export default {
  name: "ProgramProgressCircle",
  props: {
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    circle() {
      if (this.$vuetify.breakpoint.mlgAndUp) {
        return { size: 112, width: 8 };
      }
      return { size: 75, width: 6 };
    }
  }
};
</script>

<style scoped>
.v-progress-circular__underlay {
  stroke: #f5c7c7 !important;
}
</style>
