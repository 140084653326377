<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height py-0">
        <v-row class="fill-height" align="center">
          <v-col cols="12" md="7" lg="8" order-md="2" class="login-splash">
            <div v-if="$vuetify.breakpoint.smAndDown" class="splash-padding text-center">
              <h1 class="display-1 font-weight-black text-grey">Welcome to</h1>
              <v-img
                contain
                height="250"
                class="mx-auto my-10"
                src="https://ik.imagekit.io/illuminationfoundry/training-beta/logos/2019-logo-full-vertical-grey_ro4HdBWUw.png"
              ></v-img>
              <h2 class="title text-grey">Climb harder with our training programs</h2>
            </div>
            <v-img
              v-if="$vuetify.breakpoint.mdAndUp"
              lazy-src="https://ik.imagekit.io/illuminationfoundry/training-beta/login-hero_f9MPCITNk.png?tr=w-270,h-337"
              src="https://ik.imagekit.io/illuminationfoundry/training-beta/login-hero_f9MPCITNk.png?tr=w-1080,h-1350"
              srcset="https://ik.imagekit.io/illuminationfoundry/training-beta/login-hero_f9MPCITNk.png?tr=w-540,h-675 700w, https://ik.imagekit.io/illuminationfoundry/training-beta/login-hero_f9MPCITNk.png?tr=w-1080,h-1350 1100w, https://ik.imagekit.io/illuminationfoundry/training-beta/login-hero_f9MPCITNk.png 2500w"
              sizes="40vw"
              height="100vh"
              position="center bottom"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="5" lg="4" order-md="1" class="login-padding" justify="center">
            <div class="d-flex flex-column justify-center">
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-img
                  contain
                  height="40"
                  width="207.82"
                  class="mb-12"
                  src="https://ik.imagekit.io/illuminationfoundry/training-beta/logos/2019-logo-full-horizontal-blue_iRqlF-2SKm.png"
                ></v-img>
              </div>
              <v-form class="my-5 my-sm-10 my-md-12" @submit.prevent="submitForm">
                <v-text-field
                  v-model="loginForm.username"
                  type="text"
                  label="Username"
                  outlined
                  required
                  :error-messages="usernameErrors"
                  @change="$v.loginForm.username.$touch()"
                  @blur="$v.loginForm.username.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="loginForm.password"
                  type="password"
                  label="Password"
                  hide-details="auto"
                  outlined
                  required
                  :error-messages="passwordErrors"
                  @change="$v.loginForm.password.$touch()"
                  @blur="$v.loginForm.password.$touch()"
                  class="mb-2"
                ></v-text-field>
                <div class="d-flex flex-wrap align-center justify-space-between">
                  <v-checkbox
                    v-model="loginForm.rememberMe"
                    label="Remember Me"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                  <a class="black--text" href="https://www.trainingbeta.com/forgot-password/"
                    >Forgot Password?</a
                  >
                </div>
                <div class="text-center text-md-left mt-10">
                  <v-btn
                    type="submit"
                    color="primary"
                    rounded
                    :x-large="$vuetify.breakpoint.smAndDown"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :width="$vuetify.breakpoint.smAndDown ? 200 : 160"
                    :disabled="loading"
                    class="mx-auto"
                    >Sign In</v-btn
                  >
                </div>
              </v-form>
              <p class="grey--text text--darken-2">
                Don't have an account?
                <v-btn text href="https://www.trainingbeta.com/">Sign Up</v-btn>
              </p>
            </div>
            <div class="footer">
              <div class="text--secondary text-caption">
                Created By
                <a href="https://illuminationfoundry.com/" target="_blank">Illumination Foundry</a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NProgress from "nprogress";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "login",
  metaInfo: {
    title: "Login"
  },
  data: () => ({
    loading: false,
    loginForm: {
      username: null,
      password: null,
      rememberMe: false
    }
  }),
  validations: {
    loginForm: {
      username: { required },
      password: { required }
    }
  },
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.loginForm.username.$dirty) return errors;
      !this.$v.loginForm.username.required && errors.push("Username is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.loginForm.password.$dirty) return errors;
      !this.$v.loginForm.password.required && errors.push("Password is required");
      return errors;
    }
  },
  mounted() {
    this.$store.dispatch("auth/initializeLogin");
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        try {
          this.loading = true;
          await this.$store.dispatch("auth/login", this.loginForm);
        } catch (error) {
          await this.$swal({
            icon: "error",
            title: error.response ? "Error" : "Network Error",
            text: error.response
              ? error.response.data.data.message
              : "We are experiencing network issues. Please try again later."
          });
        }

        await this.$router.push("/app");
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fill-page {
  height: 100vh;
}
.login-splash {
  position: relative;
  padding: 0;
  min-height: 300px;
  .splash-padding {
    padding: 4rem 2rem 0 2rem;
  }
}
.login-padding {
  padding: 0.5rem 3rem 2rem;
  @media (min-width: 960px) {
    padding: 0 4rem;
  }
}
.text-grey {
  color: #797b7d;
}
.footer {
  position: absolute;
  bottom: 0;
  @media (min-width: 960px) {
    bottom: 0.5rem;
  }
}
</style>
