<template>
  <superset-skeleton v-if="loading"></superset-skeleton>
  <superset v-else :superset="currentSuperset" :is-today="myProgram">
    <template #exercise>
      <router-view @exercise-changed="fetchSuperset" />
    </template>
  </superset>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import NProgress from "nprogress";
import Superset from "../../components/Objects/Superset";
import SupersetSkeleton from "../../components/SkeletonLoaders/Objects/SupersetSkeleton";

export default {
  name: "SupersetPage",
  metaInfo: {
    title: "View Superset"
  },
  props: {
    supersetHash: String,
    exerciseHash: String,
    myProgram: {
      type: Boolean,
      default: false
    }
  },
  components: { SupersetSkeleton, Superset },
  data() {
    return {
      loading: true,
      fab: false
    };
  },
  mounted() {
    this.fetchSuperset();
  },
  computed: {
    ...mapState({ currentSuperset: state => state.superset.superset })
  },
  watch: {
    supersetHash: "fetchSuperset"
  },
  methods: {
    async fetchSuperset() {
      const routeName = this.myProgram ? "My Program - Superset" : "Superset";
      try {
        await this.$store.dispatch("superset/fetchSupersetByHash", this.supersetHash);
        if (!this.exerciseHash) {
          // Update the route to include the first exercise hash
          await this.$router.push({
            name: routeName,
            params: { exerciseHash: this.currentSuperset.exercises[0].route_hash_key }
          });
        }
        this.loading = false;
      } catch (error) {
        if (error.response.status === 500) this.$router.push({ name: "App 500" });
        else this.$router.push({ name: "App 404" });
      }
    }
  }
};
</script>
