import baseAxios from "../axios-service";

export default {
  getProgramChartData(programId, type) {
    return baseAxios.get(`/logbook/programs/${programId}/chart/${type}`);
  },
  getProgramNotes(
    programId,
    query = {
      perPage: 10,
      page: 1
    }
  ) {
    return baseAxios.get(`/logbook/programs/${programId}/notes`, { params: query });
  },
  getPastPrograms() {
    return baseAxios.get(`/logbook/programs/inactive`);
  }
};
