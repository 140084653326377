import baseAxios from "../axios-service";

export default {
  getPrograms({
    perPage = null,
    filterName = null,
    filterType = null,
    filterLevel = null,
    filterCategory = null,
    filterDescription = null,
    filterPublished = null,
    filterRecommended = null,
    sort = null
  } = {}) {
    const query = {
      per_page: perPage,
      "filter[name]": filterName,
      "filter[type]": filterType,
      "filter[level]": filterLevel,
      "filter[category.name]": filterCategory,
      "filter[description]": filterDescription,
      "filter[published]": filterPublished,
      "filter[recommended]": filterRecommended,
      sort: sort
    };
    return baseAxios.get(`/programs`, { params: query });
  },
  getProgram(id) {
    return baseAxios.get(`/programs/${id}`);
  },
  getMyProgram() {
    return baseAxios.get(`/programs/my-program?depth=3`);
  },
  setActiveProgram(id) {
    return baseAxios.post(`/programs/${id}/select-program`);
  },
  restartProgram(id) {
    return baseAxios.post(`/programs/${id}/restart`);
  },
  stopProgram(id) {
    return baseAxios.post(`/programs/${id}/stop`);
  },
  getProgramCategories() {
    return baseAxios.get(`/programs/categories`);
  }
};
