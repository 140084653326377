<template>
  <day-card-skeleton v-if="loading" />
  <v-card
    v-else
    :color="styles.cardColor"
    :elevation="styles.cardElevation"
    class="complete-border"
    @click="$emit('cardClicked', day.id)"
  >
    <v-row dense align="center" class="flex-nowrap pa-2 pa-md-3" style="height: 100%">
      <v-col cols="auto">
        <div class="avatar-container mr-md-3">
          <v-avatar :color="styles.circleOutlineColor" :size="day.sort_order ? 48 : 60">
            <v-avatar :color="styles.circleInsideColor" :size="day.sort_order ? 42 : 54">
              <template v-if="day.sort_order">
                {{ day.sort_order }}
              </template>
              <div v-else>
                <div>{{ day.child_count }}</div>
                <div class="text-caption">Rounds</div>
              </div>
            </v-avatar>
          </v-avatar>
          <v-avatar v-if="completed" class="avatar-check" color="primary" size="16">
            <v-icon dark x-small>mdi-check</v-icon>
          </v-avatar>
        </div>
      </v-col>
      <v-col xs="8" :class="$vuetify.breakpoint.smAndDown ? 'text-truncate' : null">
        <div class="text-subtitle-1 text-md-h6">{{ day.name }}</div>
        <div class="text-caption text-md-subtitle-2">{{ day.subtitle }}</div>
      </v-col>
      <v-col cols="auto">
        <template v-if="editable">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('edit')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('view')">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sub-object</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DayCardSkeleton from "@/components/SkeletonLoaders/Cards/DayCardSkeleton";
export default {
  name: "DayCard",
  components: { DayCardSkeleton },
  props: {
    day: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return !!!this.day.id;
    },
    completed() {
      return typeof this.day.logbook !== "undefined" ? this.day.logbook.completion_status : null;
    },
    styles() {
      if (this.completed) {
        return {
          cardColor: "#f4f5f5",
          cardElevation: 0,
          circleOutlineColor: "white",
          circleInsideColor: "#f4f5f5"
        };
      }
      return {
        cardColor: "white",
        cardElevation: 3,
        circleOutlineColor: "#f4f5f5",
        circleInsideColor: "white"
      };
    }
  }
};
</script>

<style scoped>
.complete-border {
  border: 4px solid white !important;
}
.avatar-container {
  position: relative;
}

.avatar-container .avatar-check {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
