<template>
  <svg
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    xml:space="preserve"
  >
    <g id="Layer_1_1_"></g>
    <line class="st0" x1="27" y1="20" x2="13" y2="20" />
    <line class="st0" x1="47" y1="20" x2="33" y2="20" />
    <line class="st0" x1="27" y1="26" x2="13" y2="26" />
    <line class="st0" x1="47" y1="26" x2="33" y2="26" />
    <g>
      <path
        class="st1"
        d="M27,33.5H13c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h14c0.8,0,1.5,0.7,1.5,1.5S27.8,33.5,27,33.5z"
      />
    </g>
    <line class="st0" x1="47" y1="32" x2="33" y2="32" />
    <line class="st0" x1="37" y1="40" x2="23" y2="40" />
    <line class="st0" x1="17" y1="40" x2="13" y2="40" />
    <line class="st0" x1="47" y1="40" x2="43" y2="40" />
    <line class="st0" x1="53.5" y1="26" x2="52.5" y2="26" />
    <line class="st0" x1="7.5" y1="26" x2="6.5" y2="26" />
    <g>
      <path
        class="st1"
        d="M38.5,47H37H14.7c-1.1,0-2-0.6-2.6-1.5L3.4,30.7c-0.3-0.5-0.4-1-0.4-1.5v-7.6c0-1,0.5-1.9,1.3-2.5l7.9-5.6
		c0.5-0.4,1.1-0.5,1.7-0.5h1.6c0.8,0,1.5-0.7,1.5-1.5v0c0-0.8-0.7-1.5-1.5-1.5h-1.6c-1.2,0-2.4,0.4-3.4,1.1l-7.9,5.6
		C1,17.8,0,19.6,0,21.6v7.6c0,1.1,0.3,2.1,0.8,3L9.5,47c1.1,1.8,3,3,5.2,3h23.8c0.8,0,1.5-0.7,1.5-1.5S39.3,47,38.5,47z"
      />
      <path
        class="st1"
        d="M57.4,16.6l-7.9-5.6c-1-0.7-2.2-1.1-3.4-1.1L30,10l-8.5,0c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5
		l8.5,0l16.1,0c0.6,0,1.2,0.2,1.7,0.5l7.9,5.6c0.8,0.6,1.3,1.5,1.3,2.5v7.6c0,0.5-0.1,1.1-0.4,1.5l-8.7,14.8
		c-0.5,0.9-1.5,1.5-2.6,1.5h-0.8c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h0.8c2.1,0,4.1-1.1,5.2-3l8.7-14.8c0.5-0.9,0.8-2,0.8-3
		v-7.6C60,19.6,59,17.8,57.4,16.6z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FingerBoardIcon"
};
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: #454545;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 30;
}
.st1 {
  fill: #454545;
}
</style>
