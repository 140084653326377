<template>
  <v-card>
    <v-skeleton-loader loading type="image" />
    <v-card-text>
      <v-skeleton-loader loading class="my-2" type="list-item-avatar-two-line" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RoundCardSkeleton"
};
</script>

<style scoped></style>
