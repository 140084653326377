import RoundService from "../../services/api/round-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    round: null,
    rounds: [{}, {}]
  },
  mutations: {
    SET_ROUND(state, round) {
      state.round = round;
    },
    SET_ROUNDS(state, rounds) {
      state.rounds = rounds;
    },
    SET_ROUND_EXERCISES_AND_SUPERSETS(state, exercisesAndSupersets) {
      state.round.exercises_and_supersets = exercisesAndSupersets;
    }
  },
  actions: {
    async fetchRound({ commit }, { programId, cycleId, timeId, roundId }) {
      const response = await RoundService.getRound(programId, cycleId, timeId, roundId);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async fetchRoundByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateRounds({ commit, state }, query) {
      const response = await StaffService.getRounds(query);
      const rounds = query.page > 1 ? [...state.rounds, ...response.data.data] : response.data.data;

      commit("SET_ROUNDS", rounds);
      return response.data;
    },
    async fetchTemplateRound({ commit }, id) {
      const response = await StaffService.getRound(id);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async createRound({ commit }, form) {
      const response = await StaffService.createRound(form);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async editRound({ commit }, { id, form }) {
      const response = await StaffService.updateRound(id, form);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async deleteRound({ commit, dispatch }, id) {
      const response = await StaffService.deleteRound(id);
      commit("SET_ROUND", null);
      dispatch("fetchTemplateRounds");
      return response.data.data;
    },
    async sortExercisesAndSupersets({ commit }, { parentId, childList }) {
      commit("SET_ROUND_EXERCISES_AND_SUPERSETS", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortRoundExercisesAndSupersets(parentId, childIds);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async associateSuperset({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addSupersetToRound(parentId, childId, association);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async updateAssociationSuperset({ commit }, { childId, association }) {
      const response = await StaffService.updateRoundSuperset(childId, association);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async removeAssociationSuperset({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeRoundSuperset(childId);
      dispatch("fetchTemplateRound", parentId);
      return response.data.data;
    },
    async associateExercise({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addExerciseToRound(parentId, childId, association);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async updateAssociationExercise({ commit }, { childId, association }) {
      const response = await StaffService.updateRoundExercise(childId, association);
      commit("SET_ROUND", response.data.data);
      return response.data.data;
    },
    async removeAssociationExercise({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeRoundExercise(childId);
      dispatch("fetchTemplateRound", parentId);
      return response.data.data;
    }
  },
  getters: {
    parentInfo: state => state.round?.parent
  }
};
