<template>
  <v-avatar :color="color" :size="size" class="mr-2">
    <h5 :class="fontSize">{{ text }}</h5>
  </v-avatar>
</template>

<script>
export default {
  name: "LetterAvatar",
  props: {
    type: String,
    large: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      if (this.type === "Strength") {
        return "#8865D6";
      }
      if (this.type === "Skill") {
        return "#FCB128";
      }
      if (this.type === "Power") {
        return "#5FB058";
      }
      if (this.type === "Maintenance") {
        return "#4CABE9";
      }
      return "grey lighten-2";
    },
    text() {
      if (this.type === "Strength") {
        return "St";
      }
      if (this.type === "Skill") {
        return "Sk";
      }
      if (this.type === "Power") {
        return "Pw";
      }
      if (this.type === "Maintenance") {
        return "Mt";
      }
      return "";
    },
    size() {
      return this.large ? 48 : 32;
    },
    fontSize() {
      return this.large ? "title" : "body-2";
    }
  }
};
</script>

<style scoped>
h5 {
  color: white;
  font-weight: bold !important;
}
</style>
