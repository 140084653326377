<template>
  <div :key="exercise.id">
    <v-card>
      <v-card-text class="pa-6">
        <div class="top-right">
          <slot name="menu">
            <v-speed-dial
              v-if="showMenu"
              v-model="fab"
              direction="bottom"
              transition="scale-transition"
            >
              <template v-slot:activator>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn color="#3d70b2" dark fab v-on="on">
                      <v-icon v-if="fab">mdi-close</v-icon>
                      <v-icon v-else>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ fab ? "Close" : "Exercise Menu" }}</span>
                </v-tooltip>
              </template>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn fab dark small color="#3bb273" v-on="on" @click="isModalOpen = true">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn fab dark small color="#e15554" v-on="on" @click="restartExercise">
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Restart</span>
              </v-tooltip>
            </v-speed-dial>
          </slot>
        </div>
        <v-row no-gutters>
          <v-col cols="12" lg="6" order-lg="1" align-self="center">
            <v-img
              v-if="exercise.image_url"
              :src="exercise.image_url"
              aspect-ratio="1.5555555556"
              align-self="center"
              class="rounded"
            ></v-img>
            <v-responsive v-if="exercise.video_url" :aspect-ratio="16 / 9">
              <iframe
                v-if="isVideoEmbed"
                :src="exercise.video_url"
                width="100%"
                height="100%"
                allowfullscreen
                class="border-0 rounded"
              ></iframe>
              <video v-else width="100%" height="auto" controls>
                <source :src="exercise.video_url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </v-responsive>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            order-lg="0"
            class="d-flex flex-column justify-space-between pr-lg-4"
          >
            <div>
              <v-row v-if="!hideTitle" align="baseline">
                <v-col cols="auto">
                  <h2 class="text-h6 font-weight-bold text-break">{{ exercise.name }}</h2>
                  <div v-if="!builder" class="text-subtitle-2 text--secondary">
                    {{ subtitle }}
                  </div>
                </v-col>
                <v-col cols="auto">
                  <time-badge
                    v-if="exercise.reps"
                    prefix="Set Time"
                    :time="exercise.duration * exercise.reps"
                    class="mr-2"
                  ></time-badge>
                  <time-badge
                    v-else
                    prefix="Rep Time"
                    :time="exercise.duration"
                    class="mr-2"
                  ></time-badge>
                </v-col>
              </v-row>
              <v-row v-if="showTimeRow" dense class="my-3">
                <v-col v-if="exercise.time_on || exercise.time_off" cols="12">
                  <time-badge
                    v-show="exercise.time_on"
                    prefix="Time On"
                    :time="exercise.time_on"
                    class="mr-2"
                  ></time-badge>
                  <time-badge
                    v-show="exercise.time_off"
                    prefix="Time Off"
                    :time="exercise.time_off"
                    class="mr-2"
                  ></time-badge>
                </v-col>
                <v-col v-if="exercise.rest_exercise || exercise.rest_sets" cols="12">
                  <rest-time-badge
                    v-show="exercise.rest_exercise"
                    prefix="Rest Between Reps"
                    :time="exercise.rest_exercise"
                    class="mr-2"
                  ></rest-time-badge>
                  <rest-time-badge
                    v-show="exercise.rest_sets"
                    prefix="Rest Between Sets"
                    :time="exercise.rest_sets"
                    class="mr-2"
                  ></rest-time-badge>
                </v-col>
              </v-row>
              <div class="text-subtitle-1 font-weight-bold text--secondary">Instructions:</div>
              <div class="trix-content text-body-2" v-html="exercise.instructions"></div>
            </div>
            <div class="mx-auto ml-md-0 mt-6">
              <DoneButton
                v-if="activeDay"
                :loading="buttonLoading && !exercise.logbook.completion_status"
                :complete="exercise.logbook.completion_status"
                @buttonClick="isModalOpen = true"
              />
              <div v-if="showMenu" class="text-caption text-center mt-3 text--secondary">
                Completed - {{ exercise.logbook.completion_info.created_at | moment("lll") }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ExerciseLogModal
      v-if="activeDay"
      :modalOpen="isModalOpen"
      :exercise="exercise"
      @setModal="updateModal"
      @submitForm="completeExercise"
    ></ExerciseLogModal>
  </div>
</template>

<script>
import DoneButton from "../Buttons/DoneButton";
import ExerciseLogModal from "../Modals/ExerciseLogModal";
import TimeBadge from "../Badges/TimeBadge";
import RestTimeBadge from "../Badges/RestTimeBadge";
import { mapState } from "vuex";

export default {
  name: "Exercise",
  components: { RestTimeBadge, TimeBadge, DoneButton, ExerciseLogModal },
  props: {
    activeDay: {
      type: Boolean,
      default: false
    },
    builder: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    buttonLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fab: false,
      isModalOpen: false
    };
  },
  computed: {
    showMenu() {
      return this.activeDay ? this.exercise.logbook.completion_status : false;
    },
    showTimeRow() {
      return (
        this.exercise.time_on ||
        this.exercise.time_off ||
        this.exercise.rest_exercise ||
        this.exercise.rest_sets
      );
    },
    subtitle() {
      if (this.exercise.to_failure) {
        return `${this.exercise.sets} Sets to Failure`;
      }
      return `${this.exercise.sets} Sets x ${this.exercise.reps} Reps`;
    },
    isVideoEmbed() {
      if (this.exercise.video_url) {
        const fileName = this.exercise.video_url;
        const extension =
          fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
        return extension !== "mp4";
      }
      return false;
    },
    ...mapState({ exercise: state => state.exercise.exercise })
  },
  methods: {
    updateModal(value) {
      this.isModalOpen = value;
    },
    completeExercise(form) {
      this.$emit("completeExercise", {
        exerciseHash: this.exercise.route_hash_key,
        form: {
          ...form,
          route_hash_key: this.exercise.route_hash_key,
          route_array: this.exercise.route_array
        }
      });
      this.updateModal(false);
    },
    restartExercise() {
      this.$emit("restartExercise", this.exercise.route_hash_key);
    }
  }
};
</script>

<style scoped>
.border-0 {
  border: none;
}
.top-right {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
