<template>
  <v-form class="mt-3">
    <v-row dense>
      <v-col lg="6">
        <v-autocomplete
          label="Program Category"
          v-model="$v.form.category_id.$model"
          :items="programCategories"
          item-text="name"
          item-value="id"
          :loading="categoriesLoading"
          :error-messages="programCategoryErrors"
          clearable
          outlined
        >
          <template v-slot:append>
            <v-btn
              fab
              x-small
              class="white--text"
              :color="programCategoryButton.color"
              @click="openSubModal(programCategoryButton.action)"
            >
              <v-icon>{{ programCategoryButton.icon }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="6">
        <v-autocomplete
          label="MemberMouse Bundle"
          v-model="form.bundle_id"
          :items="bundles"
          item-text="name"
          item-value="id"
          :loading="bundlesLoading"
          clearable
          outlined
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="nameErrors"
      v-model="$v.form.name.$model"
    ></v-text-field>
    <v-text-field
      label="Image URL"
      outlined
      type="text"
      :error-messages="imageErrors"
      v-model="$v.form.image_url.$model"
    ></v-text-field>
    <vue-trix
      v-model="$v.form.description.$model"
      :class="descriptionErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Description"
    ></vue-trix>
    <trix-error-messages :error-messages="descriptionErrors"></trix-error-messages>
    <v-row>
      <v-col cols="auto">
        <v-switch label="Recommended" v-model="form.recommended" inset hide-details></v-switch>
      </v-col>
      <v-col cols="auto">
        <v-switch label="Published" v-model="form.published" inset hide-details></v-switch>
      </v-col>
    </v-row>
    <v-card-actions>
      <ContinueButton :loading="formLoading" @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import TrixErrorMessages from "../FormComponents/TrixErrorMessages";
import { required, url } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "ProgramForm",
  components: { ContinueButton, TrixErrorMessages },
  props: {
    program: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      formLoading: false,
      categoriesLoading: true,
      bundlesLoading: true,
      form: {
        name: null,
        category_id: null,
        bundle_id: null,
        image_url: null,
        description: null,
        recommended: false,
        published: false
      }
    };
  },
  validations: {
    form: {
      name: { required },
      category_id: { required },
      image_url: { required, url },
      description: { required }
    }
  },
  mounted() {
    this.getProgramCategories();
    this.getBundles();
    if (this.program) {
      this.setForm();
    } else {
      this.resetForm();
    }
    this.formLoading = false;
  },
  watch: {
    program: function() {
      if (this.program) {
        this.setForm();
      } else {
        this.resetForm();
      }
      this.formLoading = false;
    }
  },
  computed: {
    programCategoryButton() {
      if (this.form.category_id) {
        return {
          color: "#3d70b2",
          icon: "mdi-pencil",
          action: "Edit"
        };
      }
      return {
        color: "#3bb273",
        icon: "mdi-plus",
        action: "Create"
      };
    },
    ...mapState({
      programCategories: state => state.builder.programCategories,
      bundles: state => state.builder.bundles
    }),
    /** Validation Errors **/
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    programCategoryErrors() {
      const errors = [];
      if (!this.$v.form.category_id.$dirty) return errors;
      !this.$v.form.category_id.required && errors.push("Program Category is required");
      return errors;
    },
    imageErrors() {
      const errors = [];
      if (!this.$v.form.image_url.$dirty) return errors;
      !this.$v.form.image_url.required && errors.push("Image URL is required");
      !this.$v.form.image_url.url && errors.push("Image URL must be a valid URL");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required && errors.push("Description is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.program.name,
        category_id: this.program.category_id,
        bundle_id: this.program.bundle_id,
        image_url: this.program.image_url,
        description: this.program.description,
        recommended: this.program.recommended,
        published: this.program.published
      };
    },
    getProgramCategories() {
      this.$store
        .dispatch("builder/fetchProgramCategories")
        .then(() => (this.categoriesLoading = false));
    },
    getBundles() {
      this.$store.dispatch("builder/fetchBundles").then(() => (this.bundlesLoading = false));
    },
    resetForm() {
      this.form = {
        name: null,
        category_id: null,
        membership_level_id: null,
        image_url: null,
        description: null
      };
      this.$v.$reset();
    },
    openSubModal(actionType) {
      let subObject = {
        action: actionType,
        type: "Program Category",
        objectId: this.form.category_id || null
      };

      this.$emit("openSubModal", subObject);
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.formLoading = true;
        this.$emit("submitForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
