export default {
  /** Days */
  getDayDuration(day) {
    return day.rounds.reduce((duration, round) => duration + round.total_time, 0);
  },
  /** Rounds */
  getRoundDuration(round) {
    return round.exercises_and_super_sets.reduce((duration, item) => duration + item.total_time, 0);
  },
  getRoundTotalTime(round) {
    const totalRepRestSec = round.repetitions * round.rep_rest;
    return round.duration * round.repetitions + totalRepRestSec;
  },
  /** Supersets */
  getSupersetDuration(superset) {
    return superset.exercises.reduce((duration, exercise) => duration + exercise.total_time, 0);
  },
  getSupersetTotalTime(superset) {
    const totalRepRestSec = superset.repetitions * superset.rep_rest;
    return superset.duration * superset.repetitions + totalRepRestSec + superset.super_set_rest;
  },
  /** Exercises */
  getExerciseTotalTime(exercise) {
    const totalSetRestSec = exercise.sets * exercise.rest_sets;
    const totalRepRestSec = exercise.reps * exercise.rest_exercise;
    return exercise.duration * exercise.sets * exercise.reps + totalSetRestSec + totalRepRestSec;
  }
};
