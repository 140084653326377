import ProgramService from "../../services/api/program-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    program: {},
    programs: [{}, {}],
    categories: []
  },
  mutations: {
    SET_PROGRAM(state, program) {
      state.program = program;
    },
    SET_PROGRAMS(state, programs) {
      state.programs = programs;
    },
    SET_PROGRAM_CYCLES(state, cycles) {
      state.program.cycles = cycles;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    }
  },
  actions: {
    async fetchCategories({ commit }) {
      const response = await ProgramService.getProgramCategories();
      commit("SET_CATEGORIES", response.data.data);
      return response.data.data;
    },
    async fetchPrograms({ commit, state }, query) {
      const response = await ProgramService.getPrograms(query);
      const programs =
        query.page > 1 ? [...state.programs, ...response.data.data] : response.data.data;

      commit("SET_PROGRAMS", programs);
      return response.data;
    },
    async fetchProgram({ commit }, id) {
      const response = await ProgramService.getProgram(id);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async fetchProgramByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async restartProgram({ dispatch }, id) {
      const response = await ProgramService.restartProgram(id);
      dispatch("fetchProgram", id);
      return response.data.data;
    },
    async stopProgram({ commit }, id) {
      const response = await ProgramService.stopProgram(id);
      commit("auth/SET_ACTIVE_PROGRAM", null, { root: true });
      return response.data.data;
    },
    async fetchMyProgram({ commit }) {
      const response = await ProgramService.getMyProgram();
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplatePrograms({ commit, state }, query) {
      const response = await StaffService.getPrograms(query);
      const programs =
        query.page > 1 ? [...state.programs, ...response.data.data] : response.data.data;

      commit("SET_PROGRAMS", programs);
      return response.data;
    },
    async fetchTemplateProgram({ commit }, id) {
      const response = await StaffService.getProgram(id);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async createProgram({ commit }, form) {
      const response = await StaffService.createProgram(form);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async editProgram({ commit }, { id, form }) {
      const response = await StaffService.updateProgram(id, form);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async deleteProgram({ commit, dispatch }, id) {
      const response = await StaffService.deleteProgram(id);
      commit("SET_PROGRAM", null);
      dispatch("fetchTemplatePrograms");
      return response.data.data;
    },
    async sortCycles({ commit }, { parentId, childList }) {
      commit("SET_PROGRAM_CYCLES", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortProgramCycles(parentId, childIds);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async associateCycle({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addCycleToProgram(parentId, childId, association);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async updateAssociationCycle({ commit }, { parentId, childId, association }) {
      const response = await StaffService.updateProgramCycle(childId, association);
      commit("SET_PROGRAM", response.data.data);
      return response.data.data;
    },
    async removeAssociationCycle({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeProgramCycle(childId);
      dispatch("fetchTemplateProgram", parentId);
      return response.data.data;
    }
  },
  getters: {}
};
