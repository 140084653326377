import baseAxios from "../axios-service";

export default {
  initialize() {
    return baseAxios.get(`/sanctum/csrf-cookie`);
  },
  login(username, password, rememberMe) {
    return baseAxios.post(`/auth/login`, {
      username: username,
      password: password,
      rememberMe: rememberMe
    });
  },
  logout() {
    return baseAxios.get(`/auth/logout`);
  },
  getMe() {
    return baseAxios.get(`/auth/me`);
  },
  hideIntroVideo() {
    return baseAxios.get(`/auth/hide-intro`);
  },
  updateSettings(settings) {
    return baseAxios.post(`/auth/settings`, settings);
  }
};
