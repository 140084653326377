<template>
  <v-app-bar color="white" :height="$vuetify.breakpoint.mdAndUp ? 87 : 65" elevation="3" app>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.smAndDown && !showBackButton"
      @click="$emit('menuClick')"
    >
    </v-app-bar-nav-icon>
    <!-- Back Button -->
    <v-btn v-if="showBackButton" absolute text large rounded @click.stop="goBack">
      <v-avatar left color="grey lighten-3" class="mr-md-4" size="35">
        <v-icon color="black" small>mdi-arrow-left</v-icon>
      </v-avatar>
      <span v-if="$vuetify.breakpoint.mdAndUp" class="text-none">{{ backButtonText }}</span>
    </v-btn>
    <v-spacer />
    <v-toolbar-title class="font-weight-black">{{ $route.name }}</v-toolbar-title>
    <v-spacer />
    <template v-if="showTabs" #extension>
      <component :is="tabsList[currentRouteName]" />
    </template>
  </v-app-bar>
</template>

<script>
import ProgramCategoryTabs from "@/components/Navigation/ProgramCategoryTabs";
import LogbookTabs from "@/components/Navigation/LogbookTabs";
import { mapGetters, mapState } from "vuex";

export default {
  name: "TopNavBar",
  components: { LogbookTabs, ProgramCategoryTabs },
  data: () => ({
    tabsList: {
      "Training Programs": "program-category-tabs",
      Logbook: "logbook-tabs"
    },
    backList: [
      "Logbook - Past",
      "Workout Builder - View",
      "Program",
      "Day",
      "Round",
      "Superset",
      "My Program - Day",
      "My Program - Round",
      "My Program - Superset"
    ]
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    routeTree() {
      if (this.currentRouteName.includes("My Program")) return "my-program";
      else if (this.currentRouteName.includes("Builder")) return "builder";
      else if (this.currentRouteName.includes("Logbook")) return "logbook";
      else return "programs";
    },
    showTabs() {
      return Object.keys(this.tabsList).includes(this.currentRouteName);
    },
    showBackButton() {
      return this.backList.includes(this.currentRouteName);
    },
    backButtonText() {
      return this.parent ? `Back to ${this.parent.type}` : "Back";
    },
    parent() {
      if (this.currentRouteName.includes("Day"))
        return { type: "Program", hash_key: this.day?.logbook.program_id };
      if (this.currentRouteName.includes("Round")) return this.roundParent;
      if (this.currentRouteName.includes("Superset")) return this.supersetParent;
      return undefined;
    },
    ...mapState({
      day: state => state.day.day,
      round: state => state.round.round,
      superset: state => state.superset.superset
    }),
    ...mapGetters({
      dayParent: `day/parentInfo`,
      roundParent: `round/parentInfo`,
      supersetParent: `superset/parentInfo`
    })
  },
  methods: {
    async goBack() {
      if (this.routeTree.includes("program")) {
        let route;
        if (this.currentRouteName === "Program") {
          route = `/app/programs`;
        } else if (this.currentRouteName.includes("Day")) {
          route =
            this.routeTree === "my-program"
              ? `/app/${this.routeTree}`
              : `/app/${this.routeTree}/${this.parent.hash_key}`;
        } else {
          route = `/app/${this.routeTree}/${this.parent.type.toLowerCase()}/${
            this.parent.hash_key
          }`;
        }

        await this.$router.push(route);
      } else {
        this.$router.back();
      }
    }
  }
};
</script>

<style scoped></style>
