<template>
  <v-form class="mt-3">
    <v-text-field
      label="Title"
      outlined
      type="text"
      :error-messages="nameErrors"
      v-model.trim="$v.form.name.$model"
    ></v-text-field>
    <v-text-field
      label="Subtitle"
      outlined
      type="text"
      :error-messages="subtitleErrors"
      v-model.trim="$v.form.subtitle.$model"
    ></v-text-field>
    <vue-trix
      v-model="$v.form.description.$model"
      :class="descriptionErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Description"
    ></vue-trix>
    <trix-error-messages :error-messages="descriptionErrors"></trix-error-messages>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import TrixErrorMessages from "../FormComponents/TrixErrorMessages";
import { required } from "vuelidate/lib/validators";
import TimeCalculations from "@/utils/TimeCalculations";

export default {
  name: "DayForm",
  components: { TrixErrorMessages, ContinueButton },
  props: {
    day: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        subtitle: null,
        duration: null,
        description: null
      }
    };
  },
  validations: {
    form: {
      name: { required },
      subtitle: { required },
      duration: { required: false },
      description: { required: false }
    }
  },
  mounted() {
    if (this.day) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    day: function() {
      if (this.day) {
        this.setForm();
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    subtitleErrors() {
      const errors = [];
      if (!this.$v.form.subtitle.$dirty) return errors;
      !this.$v.form.subtitle.required && errors.push("Subtitle is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required && errors.push("Description is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.day.name,
        subtitle: this.day.subtitle,
        duration: this.day.duration,
        description: this.day.description,
        sort_order: this.day.sort_order
      };

      this.updateDuration();
    },
    updateDuration() {
      this.form.duration = TimeCalculations.getDayDuration(this.day);
    },
    resetForm() {
      this.form = {
        name: null,
        subtitle: null,
        description: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitForm", this.form);
      }
    }
  }
};
</script>

<style scoped></style>
