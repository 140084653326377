<template>
  <div>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="auto">
          <h1 class="display-1 font-weight-bold">Platform Settings</h1>
        </v-col>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-alert type="info" class="elevation-2 mt-3" dense text outlined>
        These settings affect the whole platform and may change logbook tracking.
      </v-alert>
      <settings-form class="mt-6" />
    </v-container>
  </div>
</template>

<script>
import SettingsForm from "@/components/Forms/SettingsForm";
export default {
  name: "Settings",
  components: { SettingsForm }
};
</script>

<style scoped></style>
