<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: title => (title ? `${title} | TrainingBeta` : "TrainingBeta")
  }
};
</script>
