<template>
  <v-form>
    <!-- Climbing Difficulty -->
    <v-select
      label="Hardest Grade"
      outlined
      dense
      :items="grade_options"
      v-model="form.hardest_grade"
    ></v-select>
    <!-- Notes -->
    <v-textarea outlined hide-details label="Notes" rows="3" v-model="form.notes"></v-textarea>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";

export default {
  name: "ClimbingForm1",
  components: { ContinueButton },
  props: {
    climbingType: {
      type: String,
      required: true
    },
    log: Object
  },
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.setForm();
  },
  computed: {
    grade_options() {
      if (this.climbingType === "Bouldering") {
        return ["VB", "V0", "V1", "V2", "V3", "V4", "V5", "V6", "V7", "V8", "V9", "V10"];
      } else {
        return [
          "5.0",
          "5.1",
          "5.2",
          "5.3",
          "5.4",
          "5.5",
          "5.6",
          "5.7",
          "5.8",
          "5.9",
          "5.10",
          "5.11",
          "5.12",
          "5.13",
          "5.14",
          "5.15",
          "6.0"
        ];
      }
    }
  },
  watch: {
    log: "setForm"
  },
  methods: {
    setForm() {
      this.form = {
        type: "climbing_1",
        hardest_grade: this.log?.hardest_grade || null,
        notes: this.log?.notes || null
      };
    },
    submit() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style scoped></style>
