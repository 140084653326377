<template>
  <v-btn
    x-large
    rounded
    :color="style.buttonColor"
    :elevation="style.elevation"
    :disabled="complete"
    :loading="loading"
    class="pl-1 pr-5 white--text font-weight-black"
    @click="$emit('buttonClick')"
  >
    <v-avatar left :color="style.avatarColor" class="mr-2" size="45">
      <v-icon color="white" large>mdi-check</v-icon>
    </v-avatar>
    <slot>{{ text }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: "DoneButton",
  props: {
    text: {
      type: String,
      default: "MARK AS DONE"
    },
    complete: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      return {
        buttonColor: this.complete ? "#A7C7EE" : "#2473d5",
        avatarColor: this.complete ? "#D3E3F7" : "#508fdd",
        elevation: this.complete ? 0 : 5
      };
    }
  }
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #a7c7ee !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: white !important;
}
</style>
