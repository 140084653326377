import LogbookService from "../../services/api/logbook-service";
import ProgramService from "../../services/api/program-service";

export default {
  namespaced: true,
  state: {
    program: null,
    chartData: null,
    notes: null,
    pastPrograms: []
  },
  mutations: {
    SET_PROGRAM(state, program) {
      state.program = program;
    },
    SET_PAST_PROGRAMS(state, programs) {
      state.pastPrograms = programs;
    },
    SET_CHART_DATA(state, data) {
      state.chartData = data;
    },
    SET_NOTES(state, notes) {
      state.notes = notes;
    }
  },
  actions: {
    async fetchMyProgram({ commit }) {
      const response = await ProgramService.getMyProgram();
      const program = response.data.data;
      commit("SET_PROGRAM", program);
      return program;
    },
    async fetchProgram({ commit }, id) {
      const response = await ProgramService.getProgram(id);
      const program = response.data.data;
      commit("SET_PROGRAM", program);
      return program;
    },
    async fetchPastPrograms({ commit }) {
      const response = await LogbookService.getPastPrograms();
      commit("SET_PAST_PROGRAMS", response.data.data);
      return response.data.data;
    },
    async fetchNotes(
      { commit, state },
      {
        programId,
        query = {
          page: 1,
          perPage: 100 // keep large so we get all by default
        }
      }
    ) {
      const response = await LogbookService.getProgramNotes(programId, query);
      const notes = query.page > 1 ? [...state.notes, ...response.data.data] : response.data.data;
      commit("SET_NOTES", notes);
      return response.data.data;
    },
    async fetchChartData({ commit }, { programId, exerciseType }) {
      const response = await LogbookService.getProgramChartData(programId, exerciseType);
      commit("SET_CHART_DATA", response.data);
      return response.data.data;
    }
  },
  getters: {
    totalDayCount: state => {
      let count = 0;

      if (state.program) {
        state.program.cycles.map(cycle => {
          count += cycle.child_count;
        });
      }

      return count;
    },
    completedDayCount: state => {
      let count = 0;

      if (state.program) {
        state.program.cycles.map(cycle => {
          cycle.days_and_weeks.map(item => {
            if (item.logbook.completion_status) {
              count += 1;
            }
          });
        });
      }

      return count;
    }
  }
};
