<template>
  <v-stepper
    v-if="steps.length > 0"
    :value="currentStep"
    :alt-labels="steps.length > 2"
    non-linear
    class="lightBg elevation-0"
  >
    <v-stepper-header class="justify-center">
      <template v-for="(step, index) in steps">
        <v-stepper-step
          :key="step.id"
          :step="index + 1"
          :complete="step.logbook.completion_status"
          @click="goToChild(step.route_hash_key, step.type)"
          style="cursor: pointer"
        >
          <span class="text-center">{{ step.name }}</span>
        </v-stepper-step>
        <v-divider v-if="index < steps.length - 1" :key="index"></v-divider>
      </template>
      <template v-if="reps > 1">
        <v-divider />
        <div class="justify-start align-content-center pa-5">
          <reps-badge :reps="reps" />
        </div>
      </template>
      <template v-if="repRest > 0">
        <v-divider />
        <div class="justify-start align-content-center pa-5">
          <rest-time-badge prefix="Rest Between Reps" :time="repRest"></rest-time-badge>
        </div>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import RestTimeBadge from "@/components/Badges/RestTimeBadge";
import RepsBadge from "@/components/Badges/RepsBadge";

export default {
  name: "RoundStepper",
  components: { RepsBadge, RestTimeBadge },
  props: {
    steps: Array,
    reps: Number,
    repRest: Number
  },
  computed: {
    currentStep() {
      const childHash = this.$route.params.childHash;
      return this.steps.findIndex(step => step.route_hash_key === childHash) + 1;
    }
  },
  methods: {
    goToChild(childHash, type) {
      const routeName = this.$route.name;
      const childType = type.includes("Exercise") ? "exercise" : "superset";
      this.$router.push({ name: routeName, params: { childType, childHash } });
    }
  }
};
</script>

<style scoped></style>
