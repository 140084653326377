<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M4.1003 28.6622C3.70911 28.6622 3.33296 28.4469 3.14547 28.0736C2.88043 27.5465 3.09223 26.9041 3.61941 26.6391L24.2055 16.2795C26.7523 14.9983 28.3703 13.8751 27.6776 10.0876C27.5711 9.50717 27.9559 8.95048 28.5363 8.84458C29.1196 8.73637 29.6734 9.12293 29.7793 9.70335C30.8111 15.3484 27.2771 17.1261 25.1655 18.1886L4.58003 28.5482C4.42552 28.6257 4.26175 28.6622 4.1003 28.6622Z"
        fill="#5FB058"
      />
      <path
        d="M4.50505 31.4328C3.05487 31.4328 1.7511 30.8617 0.893494 29.7616C-0.259242 28.283 -0.667214 25.4706 1.97795 22.4123C7.80586 15.673 10.5772 11.1847 9.55175 5.35678C9.50256 5.07439 9.56795 4.78447 9.73403 4.55068C10.2838 3.77987 10.9226 3.33545 11.6321 3.23186C12.764 3.06404 13.694 3.7874 14.5145 4.42511C15.4485 5.14962 16.0608 5.57032 16.6967 5.33537C18.3581 4.71329 21.7018 1.50681 22.8505 0.323981C23.0906 0.0751473 23.4361 -0.0388532 23.7787 0.0114922C24.1207 0.0641524 24.4164 0.278844 24.5709 0.587282L26.7456 4.90715C27.0112 5.43433 26.7988 6.07667 26.2722 6.3417C25.7456 6.60616 25.1027 6.39436 24.8377 5.86776L23.3256 2.86382C21.847 4.29027 19.2036 6.67908 17.4461 7.33646C15.6493 8.01178 14.2368 6.91402 13.2055 6.1137C12.7947 5.79485 12.1819 5.30702 11.9429 5.34695C11.907 5.35216 11.8358 5.38919 11.7369 5.48236C12.6674 11.8797 9.57084 16.8992 3.59536 23.8098C2.11567 25.5216 1.71696 27.3421 2.57978 28.4491C3.35116 29.4387 4.92228 29.5723 6.48936 28.7842L27.076 18.424C28.7264 17.5936 29.5944 16.7337 29.808 15.7164C30.1401 14.1366 28.9752 11.7211 26.3445 8.53838C25.969 8.08354 26.0326 7.41053 26.4875 7.03439C26.9417 6.65882 27.6153 6.7219 27.9915 7.17732C31.143 10.9908 32.3854 13.8437 31.8993 16.1556C31.4288 18.3939 29.4706 19.6121 28.0372 20.3331L7.44939 30.6921C6.46042 31.1898 5.4512 31.4328 4.50505 31.4328Z"
        fill="#5FB058"
      />
      <path
        d="M7.74605 22.6097C7.56956 22.6097 7.39074 22.5657 7.22582 22.4737L4.73575 21.0826C4.22072 20.795 4.0367 20.144 4.3243 19.6289C4.61191 19.1127 5.26408 18.9299 5.77795 19.2175L8.26803 20.6086C8.78305 20.8962 8.96707 21.5473 8.67947 22.0623C8.48387 22.4124 8.12046 22.6097 7.74605 22.6097Z"
        fill="#5FB058"
      />
      <path
        d="M9.96512 19.8737C9.78862 19.8737 9.60923 19.8297 9.44431 19.7377L6.8223 18.2708C6.30727 17.9826 6.12325 17.3316 6.41201 16.8165C6.70019 16.3009 7.35121 16.1186 7.86566 16.4057L10.4877 17.8726C11.0027 18.1608 11.1867 18.8118 10.898 19.3269C10.7029 19.6764 10.3395 19.8737 9.96512 19.8737Z"
        fill="#5FB058"
      />
      <path
        d="M12.1865 17.136C12.01 17.136 11.8312 17.092 11.6663 17L8.60098 15.2877C8.08595 15.0001 7.90193 14.3496 8.18954 13.834C8.47772 13.3178 9.12816 13.135 9.64319 13.4226L12.7085 15.1349C13.2235 15.4225 13.4075 16.0729 13.1199 16.5885C12.9243 16.9386 12.5609 17.136 12.1865 17.136Z"
        fill="#5FB058"
      />
      <path
        d="M14.4063 14.3988C14.2298 14.3988 14.051 14.3548 13.8861 14.2628L9.86252 12.0134C9.34749 11.7258 9.16347 11.0748 9.45165 10.5598C9.73984 10.0442 10.3903 9.86073 10.9053 10.1483L14.9289 12.3977C15.4439 12.6853 15.6279 13.3363 15.3397 13.8513C15.1442 14.2014 14.7807 14.3988 14.4063 14.3988Z"
        fill="#5FB058"
      />
      <path
        d="M22.3322 31.4329H1.52333C0.93307 31.4329 0.455078 30.9549 0.455078 30.3646C0.455078 29.7744 0.933649 29.2964 1.52333 29.2964H22.3322C22.9224 29.2964 23.4004 29.7744 23.4004 30.3646C23.4004 30.9549 22.9219 31.4329 22.3322 31.4329Z"
        fill="#5FB058"
      />
      <path
        d="M27.2971 31.4329H25.4354C24.8452 31.4329 24.3672 30.9549 24.3672 30.3646C24.3672 29.7744 24.8452 29.2964 25.4354 29.2964H27.2971C27.8873 29.2964 28.3653 29.7744 28.3653 30.3646C28.3653 30.9549 27.8867 31.4329 27.2971 31.4329Z"
        fill="#5FB058"
      />
      <path
        d="M20.8942 15.6644C20.1165 15.6644 19.3549 15.4404 18.6877 15.007C17.388 14.165 16.6716 12.6268 16.8626 11.0887C16.9361 10.5031 17.4719 10.0864 18.0552 10.1605C18.6409 10.2334 19.0558 10.767 18.9834 11.3526C18.892 12.0945 19.2236 12.8086 19.8503 13.2148C20.2843 13.4955 20.8017 13.5932 21.3063 13.4839C21.8115 13.3762 22.2455 13.0782 22.5267 12.6436C23.1065 11.7496 22.8502 10.55 21.9555 9.96954C21.3022 9.54652 20.4631 9.55462 19.8179 9.99153C19.3301 10.3237 18.6657 10.1958 18.3347 9.70739C18.0037 9.21898 18.1305 8.55524 18.6189 8.22423C19.9765 7.30354 21.742 7.28503 23.1181 8.17736C25.0012 9.39838 25.5405 11.9243 24.3195 13.8068C23.7275 14.7199 22.8155 15.3478 21.7524 15.5747C21.4666 15.6348 21.1795 15.6644 20.8942 15.6644Z"
        fill="#5FB058"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ShoesIcon"
};
</script>

<style scoped></style>
