<template>
  <v-card>
    <v-card-text>
      <v-skeleton-loader loading class="mb-2" type="list-item-avatar"></v-skeleton-loader>
      <v-skeleton-loader loading type="list-item-two-line"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DayCardSkeleton"
};
</script>

<style scoped></style>
