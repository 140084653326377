<template>
  <round-card-skeleton v-if="loading" />
  <v-card v-else @click="$emit('click')">
    <v-img v-if="!hideImage" :src="round.image_url" :aspect-ratio="1.8">
      <v-chip v-show="cardDisabled" color="#4CABE9" dark class="ma-3 font-weight-black" small
        >COMPLETE</v-chip
      >
    </v-img>
    <v-list-item class="pa-4">
      <picture-icon :roundStyle="round.icon_type" class="mr-3"></picture-icon>
      <v-list-item-content justify="center">
        <v-list-item-title class="title font-weight-black">{{ round.name }}</v-list-item-title>
        <v-list-item-title class="heading text--secondary">{{ round.subtitle }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <time-badge :time="round[showTime]" />
        <div v-if="editable" class="d-flex">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('edit')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn text fab small color="#3d70b2" v-on="on" @click.stop="$emit('view')">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Sub-object</span>
          </v-tooltip>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import PictureIcon from "../Avatars/IconAvatar";
import TimeBadge from "@/components/Badges/TimeBadge";
import RoundCardSkeleton from "@/components/SkeletonLoaders/Cards/RoundCardSkeleton";

export default {
  name: "RoundCard",
  components: { RoundCardSkeleton, TimeBadge, PictureIcon },
  props: {
    round: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: String,
      default: "total_time"
    }
  },
  computed: {
    loading() {
      return !!!this.round.id;
    },
    cardDisabled() {
      return this.round.logbook?.completion_status;
    }
  }
};
</script>

<style scoped></style>
