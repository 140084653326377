<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" order-md="0">
        <v-skeleton-loader loading class="mb-2" type="heading"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-12" type="text" width="10rem"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-3" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-3" type="paragraph"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6" order-md="1">
        <v-responsive :aspect-ratio="16 / 9">
          <v-skeleton-loader loading type="image"></v-skeleton-loader>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ExerciseSkeleton"
};
</script>

<style scoped></style>
