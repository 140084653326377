import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/variables.scss"],
  theme: {
    themes: {
      light: {
        primary: "#2473d5",
        secondary: "#29304e",
        lightBg: "#F4F5F5"
      }
    }
  }
});
