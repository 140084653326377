<template>
  <v-chip label class="px-2" dark color="#3d70b2"> Repeat {{ formattedReps }}x </v-chip>
</template>

<script>
export default {
  name: "RepsBadge",
  props: {
    reps: {
      type: [Number, String]
    }
  },
  computed: {
    formattedReps() {
      return Number(this.reps);
    }
  }
};
</script>

<style scoped></style>
