<template>
  <v-dialog v-model="open" max-width="600">
    <v-card>
      <v-card-title class="headline pt-5">
        {{ title }}
        <v-tooltip v-if="object" left>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              fab
              small
              dark
              absolute
              right
              color="#e15554"
              v-on="on"
              @click="deleteObject"
            >
              <v-icon class="float-right">mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <component
          :key="formKey"
          :is="formType"
          :programCategory="object"
          :bodyPosition="object"
          :gripType="object"
          @submitSubForm="submitSubForm"
        ></component>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProgramCategoryForm from "../Forms/WorkoutBuilder/SubForms/ProgramCategoryForm";
import BodyPositionForm from "../Forms/WorkoutBuilder/SubForms/BodyPositionForm";
import GripTypeForm from "../Forms/WorkoutBuilder/SubForms/GripTypeForm";

/** Modal used to create sub component elements within the Workout Builder Modal  **/
export default {
  name: "WorkoutBuilderSubModal",
  components: { BodyPositionForm, GripTypeForm, ProgramCategoryForm },
  props: {
    modalOpen: {
      type: Boolean,
      default: true
    },
    objectType: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      required: false
    }
  },
  computed: {
    open: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("setSubModal", value);
      }
    },
    title() {
      if (this.object) {
        return `Edit ${this.objectType}`;
      }
      return `Create a New ${this.objectType}`;
    },
    formKey() {
      if (this.object) {
        return this.object.id;
      }
      return this.generateFakeUUID();
    },
    formType() {
      if (this.objectType) {
        switch (this.objectType) {
          case "Body Position":
            return "BodyPositionForm";
          case "Grip Type":
            return "GripTypeForm";
          case "Program Category":
            return "ProgramCategoryForm";
          default:
            return null;
        }
      }
      return null;
    }
  },
  methods: {
    // Generate fake uuid to force rerender of form
    generateFakeUUID() {
      return (
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
      );
    },
    async submitSubForm(form) {
      if (this.object) {
        await this.updateObject(form);
      } else {
        await this.createObject(form);
      }
      this.open = false;
    },
    async createObject(form) {
      const objectType = this.objectType.split(" ").join("");
      await this.$store.dispatch(`builder/create${objectType}`, form);
    },
    async updateObject(form) {
      const objectType = this.objectType.split(" ").join("");
      await this.$store.dispatch(`builder/edit${objectType}`, {
        id: this.object.id,
        form: form
      });
    },
    async deleteObject() {
      if (this.object.is_deleteable) {
        let result = await this.$swal({
          title: `Delete this ${this.objectType}?`,
          text: "You cannot undo this.",
          showCancelButton: true,
          confirmButtonColor: "#e15554",
          confirmButtonText: "Delete"
        });

        if (result.value) {
          const objectType = this.objectType.split(" ").join("");
          try {
            await this.$store.dispatch(`builder/delete${objectType}`, this.object.id);
            this.$emit("objectDeleted");
            this.open = false;
          } catch (error) {
            this.$swal({
              icon: "error",
              title: `Error`,
              text: error
            });
          }
        }
      } else {
        const errorMessages = this.object.deleteable_messages.map(message => `<li>${message}</li>`);

        await this.$swal({
          icon: "error",
          title: `You Cannot Delete this ${this.objectType}`,
          html: errorMessages.join("")
        });
      }
    }
  }
};
</script>

<style scoped></style>
