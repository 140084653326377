import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate/src";
import VueMoment from "vue-moment";
import VueTrix from "vue-trix";
import VueMeta from "vue-meta";
import _ from "lodash";

import "nprogress/nprogress.css";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueTrix);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMoment);
Vue.use(VueMeta);
Vue.use(_);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
