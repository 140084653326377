<script>
import { Line } from "vue-chartjs";

export default {
  name: "LineGraph",
  extends: Line,
  props: {
    chartdata: Object,
    yLabel: String
  },
  computed: {
    options() {
      return {
        legend: {
          display: true
        },
        scales: {
          yAxes: [
            {
              ticks: { beginAtZero: true },
              gridLines: {
                display: true,
                drawBorder: false
              },
              scaleLabel: {
                display: true,
                labelString: this.yLabel
              }
            }
          ],
          xAxes: [{ gridLines: { display: false } }]
        },
        responsive: true,
        maintainAspectRatio: false
      };
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  }
};
</script>

<style scoped></style>
