<template>
  <div>
    <v-dialog v-model="open" max-width="800">
      <v-card>
        <v-card-title class="headline pt-5">
          {{ title }}
          <v-tooltip v-if="editing" left>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                fab
                small
                absolute
                right
                color="#e15554"
                v-on="on"
                @click="deleteAssociation"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Remove {{ formattedChildType }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <component
            :key="formKey"
            :is="formType"
            :cycle="associationObject"
            :week="associationObject"
            :day="associationObject"
            :round="associationObject"
            :superset="associationObject"
            :exercise="associationObject"
            association
            @submitForm="submitForm"
          ></component>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CycleForm from "../Forms/WorkoutBuilder/CycleForm";
import WeekForm from "../Forms/WorkoutBuilder/WeekForm";
import DayForm from "../Forms/WorkoutBuilder/DayForm";
import RoundForm from "../Forms/WorkoutBuilder/RoundForm";
import SupersetForm from "../Forms/WorkoutBuilder/SupersetForm";
import ExerciseForm from "../Forms/WorkoutBuilder/ExerciseForm";

export default {
  name: "WorkoutBuilderAssociationModal",
  components: { CycleForm, WeekForm, DayForm, RoundForm, SupersetForm, ExerciseForm },
  props: {
    modalOpen: {
      type: Boolean,
      default: true
    },
    parentType: {
      type: String,
      required: true
    },
    childType: {
      type: String,
      required: true
    },
    associationObject: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    open: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("setModal", value);
      }
    },
    title() {
      if (this.editing) {
        return `Update ${this.formattedChildType} in ${this.formattedParentType}`;
      }
      return `Add ${this.formattedChildType} to ${this.formattedParentType}`;
    },
    formKey() {
      if (this.associationObject) {
        return this.associationObject.id;
      }
      return this.generateFakeUUID();
    },
    formType() {
      switch (this.parentType) {
        case "program":
          return "CycleForm";
        case "cycle":
          return this.childType === "day" ? "DayForm" : "WeekForm";
        case "week":
          return "DayForm";
        case "day":
          return "RoundForm";
        case "round":
          return this.childType === "exercise" ? "ExerciseForm" : "SupersetForm";
        case "superset":
          return "ExerciseForm";
        default:
          return null;
      }
    },
    formattedParentType() {
      return this.parentType.charAt(0).toUpperCase() + this.parentType.slice(1);
    },
    formattedChildType() {
      return this.childType.charAt(0).toUpperCase() + this.childType.slice(1);
    }
  },
  methods: {
    // Generate fake uuid to force rerender of form
    generateFakeUUID() {
      return (
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
      );
    },
    async submitForm(form) {
      if (this.editing) {
        await this.updateAssociation(form);
      } else {
        await this.createAssociation(form);
      }
    },
    async createAssociation(form) {
      try {
        await this.$store.dispatch(`${this.parentType}/associate${this.formattedChildType}`, {
          parentId: this.$route.params.id,
          childId: this.associationObject.id,
          association: form
        });
        this.open = false;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: `Error`,
          text: error
        });
      }
    },
    async updateAssociation(form) {
      try {
        await this.$store.dispatch(
          `${this.parentType}/updateAssociation${this.formattedChildType}`,
          {
            childId: this.associationObject.id,
            association: form
          }
        );
        this.open = false;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: `Error`,
          text: error
        });
      }
    },
    async deleteAssociation() {
      let result = await this.$swal({
        title: `Delete this Association?`,
        text: "You cannot undo this.",
        showCancelButton: true,
        confirmButtonColor: "#e15554",
        confirmButtonText: "Delete"
      });

      if (result.value) {
        try {
          this.open = false;
          await this.$store.dispatch(
            `${this.parentType}/removeAssociation${this.formattedChildType}`,
            {
              parentId: this.$route.params.id,
              childId: this.associationObject.id
            }
          );
        } catch (error) {
          this.$swal({
            icon: "error",
            title: `Error`,
            text: error
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
