import SupersetService from "../../services/api/superset-service";
import StaffService from "../../services/api/staff-service";
import SharedService from "@/services/api/shared-service";

export default {
  namespaced: true,
  state: {
    superset: null,
    supersets: [{}, {}]
  },
  mutations: {
    SET_SUPERSET(state, superset) {
      state.superset = superset;
    },
    SET_SUPERSETS(state, supersets) {
      state.supersets = supersets;
    },
    SET_SUPERSET_EXERCISES(state, exercises) {
      state.superset.exercises = exercises;
    }
  },
  actions: {
    async fetchSuperset({ commit }, { programId, cycleId, timeId, roundId, supersetId }) {
      const response = await SupersetService.getSuperset(
        programId,
        cycleId,
        timeId,
        roundId,
        supersetId
      );
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async fetchSupersetByHash({ commit }, hashId) {
      const response = await SharedService.getItemByHash(hashId);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    /** Staff Routes used for the Builder **/
    async fetchTemplateSupersets({ commit, state }, query) {
      const response = await StaffService.getSupersets(query);
      const supersets =
        query.page > 1 ? [...state.supersets, ...response.data.data] : response.data.data;

      commit("SET_SUPERSETS", supersets);
      return response.data;
    },
    async fetchTemplateSuperset({ commit }, id) {
      const response = await StaffService.getSuperset(id);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async createSuperset({ commit }, form) {
      const response = await StaffService.createSuperset(form);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async editSuperset({ commit }, { id, form }) {
      const response = await StaffService.updateSuperset(id, form);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async deleteSuperset({ commit, dispatch }, id) {
      const response = await StaffService.deleteSuperset(id);
      commit("SET_SUPERSET", null);
      dispatch("fetchTemplateSupersets");
      return response.data.data;
    },
    async sortExercises({ commit, dispatch }, { parentId, childList }) {
      commit("SET_SUPERSET_EXERCISES", childList);
      const childIds = { ids: childList.map(child => child.id) };
      const response = await StaffService.sortSupersetExercises(parentId, childIds);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async associateExercise({ commit }, { parentId, childId, association }) {
      const response = await StaffService.addExerciseToSuperset(parentId, childId, association);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async updateAssociationExercise({ commit }, { childId, association }) {
      const response = await StaffService.updateSupersetExercise(childId, association);
      commit("SET_SUPERSET", response.data.data);
      return response.data.data;
    },
    async removeAssociationExercise({ dispatch }, { parentId, childId }) {
      const response = await StaffService.removeSupersetExercise(childId);
      dispatch("fetchTemplateSuperset", parentId);
      return response.data.data;
    }
  },
  getters: {
    parentInfo: state => state.superset?.parent,
    nextSibling: state => {
      if (state.superset?.next_sibling) {
        const nextSibling = state.superset.next_sibling;
        const parentType = nextSibling.parent.type.toLowerCase();
        const parentHash = nextSibling.parent.hash_key;
        const nextHash = nextSibling.hash_key;
        const nextType = nextSibling.type.toLowerCase();
        let route;

        if (parentType === "round") {
          route = `${parentType}/${parentHash}/${nextType}-${nextHash}`;
        } else {
          route = `${parentType}/${parentHash}/${nextHash}`;
        }

        return {
          ...state.superset.next_sibling,
          route
        };
      }
      return undefined;
    },
    prevSibling: state => {
      if (state.superset?.previous_sibling) {
        const prevSibling = state.superset.previous_sibling;
        const parentType = prevSibling.parent.type.toLowerCase();
        const parentHash = prevSibling.parent.hash_key;
        const prevHash = prevSibling.hash_key;
        const prevType = prevSibling.type.toLowerCase();
        let route;

        if (parentType === "round") {
          route = `${parentType}/${parentHash}/${prevType}-${prevHash}`;
        } else {
          route = `${parentType}/${parentHash}/${prevHash}`;
        }

        return {
          ...state.superset.previous_sibling,
          route
        };
      }
      return undefined;
    }
  }
};
