import baseAxios from "../axios-service";

export default {
  getDay(program_id, cycle_id, day_id) {
    return baseAxios.get(`/programs/${program_id}/cycles/${cycle_id}/days-and-weeks/${day_id}`);
  },
  getToday() {
    return baseAxios.get(`/programs/today`);
  },
  startNextDay() {
    return baseAxios.post(`/programs/today/next`);
  }
};
