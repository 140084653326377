<template>
  <v-avatar :size="size" :color="color" :class="[padding]">
    <component :is="icon"></component>
  </v-avatar>
</template>

<script>
import CardioIcon from "@/components/Icons/CardioIcon";
import ShoesIcon from "@/components/Icons/ShoesIcon";
import DumbbellIcon from "@/components/Icons/DumbbellIcon";
import FingerBoardIcon from "@/components/Icons/FingerBoardIcon";

export default {
  name: "IconAvatar",
  components: { CardioIcon, DumbbellIcon, ShoesIcon, FingerBoardIcon },
  props: {
    roundStyle: String,
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      if (this.roundStyle === "Fingers") {
        return "grey lighten-2";
      }
      if (this.roundStyle === "Lifting") {
        return "#E7F0F6";
      }
      if (
        this.roundStyle === "Bouldering Volume" ||
        this.roundStyle === "Bouldering Difficulty" ||
        this.roundStyle === "Routes Volume" ||
        this.roundStyle === "Routes Difficulty" ||
        this.roundStyle === "Climbing"
      ) {
        return "#ECF6EB";
      }
      return "#FBECE7";
    },
    icon() {
      if (this.roundStyle === "Fingers") {
        return FingerBoardIcon;
      }
      if (this.roundStyle === "Lifting") {
        return DumbbellIcon;
      }
      if (
        this.roundStyle === "Bouldering Volume" ||
        this.roundStyle === "Bouldering Difficulty" ||
        this.roundStyle === "Routes Volume" ||
        this.roundStyle === "Routes Difficulty" ||
        this.roundStyle === "Climbing"
      ) {
        return ShoesIcon;
      }
      return CardioIcon;
    },
    size() {
      return this.small ? 32 : 60;
    },
    padding() {
      return this.small ? "pa-1" : "pa-3";
    }
  }
};
</script>

<style scoped></style>
