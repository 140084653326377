<template>
  <v-stepper
    v-if="steps.length > 0"
    :value="currentStep"
    :alt-labels="steps.length > 2"
    non-linear
    class="lightBg elevation-0"
  >
    <v-stepper-header class="justify-center">
      <template v-for="(step, index) in steps">
        <v-stepper-step
          :key="step.id"
          :step="index + 1"
          :complete="step.logbook.completion_status"
          @click="goToExercise(step.route_hash_key)"
          style="cursor: pointer"
        >
          <span class="text-center">{{ step.name }}</span>
        </v-stepper-step>
        <v-divider v-if="index < steps.length - 1" :key="index"></v-divider>
      </template>
      <template v-if="reps > 1">
        <v-divider />
        <div class="v-stepper__step pa-5">
          <reps-badge :reps="reps"></reps-badge>
        </div>
      </template>
      <template v-if="repRest > 0">
        <v-divider v-if="$vuetify.breakpoint.smAndUp" />
        <div class="v-stepper__step pa-5">
          <rest-time-badge prefix="Rest Between Reps" :time="repRest"></rest-time-badge>
        </div>
      </template>
      <div v-if="supersetRest > 0" class="v-stepper__step pa-5">
        <rest-time-badge
          prefix="Rest After Superset"
          :time="supersetRest"
          class="align-self-baseline"
        ></rest-time-badge>
      </div>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import RestTimeBadge from "@/components/Badges/RestTimeBadge";
import RepsBadge from "@/components/Badges/RepsBadge";

export default {
  name: "SupersetStepper",
  components: { RepsBadge, RestTimeBadge },
  props: {
    steps: Array,
    reps: Number,
    repRest: Number,
    supersetRest: Number
  },
  computed: {
    currentStep() {
      const exerciseHash = this.$route.params.exerciseHash;
      return this.steps.findIndex(step => step.route_hash_key === exerciseHash) + 1;
    }
  },
  methods: {
    goToExercise(exerciseHash) {
      const routeName = this.$route.name;
      this.$router.push({ name: routeName, params: { exerciseHash } });
    }
  }
};
</script>

<style scoped></style>
