<template>
  <div>
    <v-row v-if="loading" dense>
      <v-col v-for="i in 2" :key="i" :cols="getFlexCols(i)">
        <v-skeleton-loader :loading="true" type="image" class="ma-3"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading" dense>
      <v-col v-for="(program, index) in pastPrograms" :key="program.id" :cols="getFlexCols(index)">
        <program-card
          :program="program"
          @click="programClicked(program.id)"
          class="ma-3"
        ></program-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProgramCard from "../Cards/ProgramCard";
import store from "../../store";

export default {
  name: "PastProgramsList",
  components: { ProgramCard },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.fetchPrograms();
  },
  computed: {
    ...mapState({ pastPrograms: state => state.logbook.pastPrograms })
  },
  methods: {
    fetchPrograms() {
      store.dispatch("logbook/fetchPastPrograms").then(() => {
        this.loading = false;
      });
    },
    getFlexCols(index) {
      if (this.$vuetify.breakpoint.lgAndUp) return 4;
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (index % 4 === 0) return 8;
        if (index % 4 === 1) return 4;
        if (index % 4 === 2) return 4;
        if (index % 4 === 3) return 8;
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        if (index % 3 === 0) return 12;
        return 6;
      } else {
        return 12;
      }
    },
    programClicked(id) {
      this.$emit("programClicked", id);
    }
  }
};
</script>

<style scoped></style>
