<template>
  <v-tabs align-with-title style="border-top: #EAEBEC solid 1px">
    <v-tab
      v-for="category in programCategories"
      :key="category"
      :to="{ name: 'Training Programs', query: { type: category } }"
      exact
      >{{ category }}</v-tab
    >
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProgramCategoryTabs",
  mounted() {
    this.getProgramCategories();
  },
  computed: {
    ...mapState({ programCategories: state => state.program.categories })
  },
  methods: {
    getProgramCategories() {
      this.$store.dispatch("program/fetchCategories");
    }
  }
};
</script>

<style scoped></style>
