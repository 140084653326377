<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-skeleton-loader loading class="mb-6" type="heading"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="chip"></v-skeleton-loader>
        <v-skeleton-loader loading class="mb-6" type="paragraph"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-skeleton-loader loading class="mb-6" type="divider"></v-skeleton-loader>
    <v-row v-if="!builder">
      <v-col cols="8" md="6" lg="4" xl="3" v-for="i in 3" :key="i" class="mx-auto ml-md-0">
        <v-skeleton-loader
          loading
          class="mb-4 mx-auto"
          type="image"
          height="150"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="builder">
      <v-col cols="12" v-for="i in 2" :key="i">
        <v-card>
          <v-card-text>
            <v-skeleton-loader loading class="mb-2" type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader loading type="list-item-two-line"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProgramSkeleton",
  props: {
    builder: { type: Boolean, default: false }
  }
};
</script>

<style scoped></style>
