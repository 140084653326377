<template>
  <v-form class="mt-3">
    <v-row v-if="!association" dense>
      <v-col cols="auto">
        <picture-icon :round-style="form.style" small class="mr-3" />
      </v-col>
      <v-col cols="10" sm="6" md="4">
        <v-select
          label="Exercise Type"
          outlined
          v-model="$v.form.style.$model"
          :error-messages="styleErrors"
          :items="styles"
        >
        </v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          label="Exercise Duration"
          outlined
          type="number"
          v-model.number="$v.form.duration.$model"
          :error-messages="durationErrors"
          suffix="sec"
          hint="Single Exercise Rep"
          persistent-hint
          :disabled="association"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="association" cols="auto">
        <picture-icon :round-style="form.style" small class="mr-2" />
      </v-col>
      <v-col>
        <v-text-field
          label="Title"
          outlined
          type="text"
          v-model.trim="$v.form.name.$model"
          :error-messages="nameErrors"
        ></v-text-field>
      </v-col>
      <v-col v-if="association" cols="12" sm="4" md="3">
        <v-text-field
          label="Exercise Duration"
          outlined
          type="number"
          v-model.number="$v.form.duration.$model"
          :error-messages="durationErrors"
          suffix="sec"
          hint="Single Exercise Rep"
          persistent-hint
          :disabled="association"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!association" dense>
      <v-col cols="auto">
        <v-switch
          v-model="isVideoMedia"
          inset
          hide-details
          :append-icon="mediaSwitchIcon"
          class="mt-3 pr-3"
        ></v-switch>
      </v-col>
      <v-col>
        <v-text-field
          v-show="!isVideoMedia"
          label="Image URL"
          outlined
          type="text"
          :error-messages="mediaErrors"
          v-model.trim="$v.form.image_url.$model"
        ></v-text-field>
        <v-text-field
          v-show="isVideoMedia"
          label="Video URL"
          outlined
          type="text"
          :error-messages="mediaErrors"
          v-model.trim="$v.form.video_url.$model"
          hint="All videos must be in mp4 format or embed links from youtube"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>
    <vue-trix
      v-model="$v.form.instructions.$model"
      :class="instructionsErrors.length > 0 ? 'trix-error' : ''"
      placeholder="Instructions"
    ></vue-trix>
    <trix-error-messages :error-messages="instructionsErrors"></trix-error-messages>
    <v-row v-if="!association" dense>
      <v-col cols="12" sm="6">
        <v-autocomplete
          v-show="form.style === 'Fingers'"
          label="Body Position"
          v-model.trim="$v.form.body_position_id.$model"
          :items="bodyPositions"
          item-text="name"
          item-value="id"
          :loading="bodyPositionsLoading"
          :error-messages="bodyPositionErrors"
          clearable
          outlined
        >
          <template v-slot:append>
            <v-btn
              fab
              x-small
              class="white--text"
              :color="bodyPositionButton.color"
              :disabled="bodyPositionButton.isDisabled"
              @click="openSubModal(bodyPositionButton.action, 'Body Position')"
            >
              <v-icon>{{ bodyPositionButton.icon }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete
          v-show="form.style === 'Fingers'"
          label="Grip Type"
          v-model.trim="$v.form.grip_type_id.$model"
          :items="gripTypes"
          item-text="name"
          item-value="id"
          :loading="gripTypesLoading"
          :error-messages="gripTypeErrors"
          clearable
          outlined
        >
          <template v-slot:append>
            <v-btn
              fab
              x-small
              class="white--text"
              :color="gripTypeButton.color"
              :disabled="gripTypeButton.isDisabled"
              @click="openSubModal(gripTypeButton.action, 'Grip Type')"
            >
              <v-icon>{{ gripTypeButton.icon }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="association">
      <v-divider class="my-4"></v-divider>
      <h2 class="headline">Association Details</h2>
      <v-tooltip bottom>
        <template v-slot:activator="on">
          <time-badge
            prefix="Total Calculated Time"
            :time="form.total_time"
            v-on="on"
            class="mb-3"
          ></time-badge>
        </template>
        <span>Tooltip</span>
      </v-tooltip>
      <v-row dense>
        <v-col cols="12" sm="3">
          <v-switch label="To Failure" v-model="form.to_failure" inset></v-switch>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch label="Add/Remove Weight" v-model="form.add_remove_weight" inset></v-switch>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4" md="2">
          <v-text-field
            label="Exercise Sets"
            outlined
            type="number"
            min="1"
            v-model.number="form.sets"
            @input="updateTotalTime"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-text-field
            label="Rest Between Sets"
            outlined
            type="number"
            min="0"
            v-model.number="form.rest_sets"
            suffix="sec"
            :disabled="form.sets === 1"
            @input="updateTotalTime"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-text-field
            label="Exercise Reps"
            outlined
            type="number"
            min="1"
            v-model.number="form.reps"
            @input="updateTotalTime"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-text-field
            label="Rest Between Reps"
            outlined
            type="number"
            min="0"
            :disabled="form.reps === 1"
            v-model.number="form.rest_exercise"
            @input="updateTotalTime"
            suffix="sec"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Time On"
            outlined
            type="number"
            min="0"
            v-model.number="form.time_on"
            suffix="sec"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Time Off"
            outlined
            type="number"
            min="0"
            v-model.number="form.time_off"
            suffix="sec"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-card-actions>
      <ContinueButton @buttonClick="submit" class="mx-auto mt-5" />
    </v-card-actions>
  </v-form>
</template>

<script>
import ContinueButton from "../../Buttons/ContinueButton";
import TrixErrorMessages from "../FormComponents/TrixErrorMessages";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import PictureIcon from "../../Avatars/IconAvatar";
import TimeBadge from "../../Badges/TimeBadge";
import { mapState } from "vuex";
import TimeCalculations from "@/utils/TimeCalculations";

export default {
  name: "ExerciseForm",
  components: { TimeBadge, PictureIcon, TrixErrorMessages, ContinueButton },
  props: {
    exercise: {
      type: Object,
      required: false
    },
    association: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      styles: [
        "Fingers",
        "Lifting",
        "Bouldering Volume",
        "Bouldering Difficulty",
        "Routes Volume",
        "Routes Difficulty"
      ],
      isVideoMedia: false,
      bodyPositionsLoading: true,
      gripTypesLoading: true,
      form: {
        name: null,
        duration: null,
        style: "Fingers",
        body_position_id: null,
        grip_type_id: null,
        image_url: null,
        video_url: null,
        instructions: null
      }
    };
  },
  validations: {
    form: {
      name: { required },
      duration: { required },
      style: { required },
      image_url: {
        url: requiredIf(function() {
          return !this.isVideoMedia;
        }),
        required: requiredIf(function() {
          return !this.isVideoMedia;
        })
      },
      video_url: {
        url: requiredIf(function() {
          return this.isVideoMedia;
        }),
        required: requiredIf(function() {
          return this.isVideoMedia;
        })
      },
      instructions: { required },
      body_position_id: {
        required: requiredIf(form => form.style === "Fingers")
      },
      grip_type_id: {
        required: requiredIf(form => form.style === "Fingers")
      }
    }
  },
  mounted() {
    this.getBodyPositions();
    this.getGripTypes();

    if (this.exercise) {
      this.setForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    isVideoMedia: function(oldValue, newValue) {
      if (newValue) {
        this.form.video_url = null;
      } else {
        this.form.image_url = null;
      }
    },
    exercise: function() {
      if (this.exercise) {
        this.setForm();
      } else {
        this.resetForm();
      }
    },
    bodyPositions: function() {
      const bodyPositionExists = this.bodyPositions.some(
        bodyPosition => bodyPosition.id === this.form.body_position_id
      );

      if (this.form.body_position_id !== null && !bodyPositionExists) {
        this.form.body_position_id = null;
        this.$v.form.body_position_id.$reset();
      }
    },
    gripType: function() {
      const gripTypeExists = this.gripTypes.some(
        gripType => gripType.id === this.form.grip_type_id
      );

      if (this.form.grip_type_id !== null && !gripTypeExists) {
        this.form.grip_type_id = null;
        this.$v.form.grip_type_id.$reset();
      }
    }
  },
  computed: {
    ...mapState({
      bodyPositions: state => state.builder.bodyPositions,
      gripTypes: state => state.builder.gripTypes
    }),
    mediaSwitchIcon() {
      return this.isVideoMedia ? "mdi-video" : "mdi-camera";
    },
    bodyPositionButton() {
      if (this.form.body_position_id === null || this.form.body_position_id === undefined) {
        return {
          color: "#3bb273",
          icon: "mdi-plus",
          action: "Create",
          isDisabled: this.form.style !== "Fingers"
        };
      }
      return {
        color: "#3d70b2",
        icon: "mdi-pencil",
        action: "Edit",
        isDisabled: this.form.style !== "Fingers"
      };
    },
    gripTypeButton() {
      if (this.form.grip_type_id === null || this.form.grip_type_id === undefined) {
        return {
          color: "#3bb273",
          icon: "mdi-plus",
          action: "Create",
          isDisabled: this.form.style !== "Fingers"
        };
      }
      return {
        color: "#3d70b2",
        icon: "mdi-pencil",
        action: "Edit",
        isDisabled: this.form.style !== "Fingers"
      };
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Title is required");
      return errors;
    },
    styleErrors() {
      const errors = [];
      if (!this.$v.form.style.$dirty) return errors;
      !this.$v.form.style.required && errors.push("Exercise Type is required");
      return errors;
    },
    durationErrors() {
      const errors = [];
      if (!this.$v.form.duration.$dirty) return errors;
      !this.$v.form.duration.required && errors.push("Duration is required");
      return errors;
    },
    instructionsErrors() {
      const errors = [];
      if (!this.$v.form.instructions.$dirty) return errors;
      !this.$v.form.instructions.required && errors.push("Instructions are required");
      return errors;
    },
    mediaErrors() {
      const errors = [];
      if (!this.$v.form.image_url.$dirty && !this.$v.form.video_url.$dirty) return errors;
      !this.$v.form.image_url.required && errors.push("Image is required");
      !this.$v.form.image_url.url && errors.push("Image URL must be a valid URL");
      !this.$v.form.video_url.required && errors.push("Video is required");
      !this.$v.form.video_url.url && errors.push("Video URL must be a valid URL");
      return errors;
    },
    bodyPositionErrors() {
      const errors = [];
      if (!this.$v.form.body_position_id.$dirty) return errors;
      !this.$v.form.body_position_id.required && errors.push("Body Position is required");
      return errors;
    },
    gripTypeErrors() {
      const errors = [];
      if (!this.$v.form.grip_type_id.$dirty) return errors;
      !this.$v.form.grip_type_id.required && errors.push("Grip Type is required");
      return errors;
    }
  },
  methods: {
    setForm() {
      this.form = {
        name: this.exercise.name,
        style: this.exercise.style,
        duration: this.exercise.duration,
        body_position_id: this.exercise.body_position_id,
        grip_type_id: this.exercise.grip_type_id,
        image_url: this.exercise.image_url,
        video_url: this.exercise.video_url,
        instructions: this.exercise.instructions,
        reps: this.exercise.reps || 1,
        sets: this.exercise.sets || 1,
        rest_sets: this.exercise.rest_sets || 0,
        rest_exercise: this.exercise.rest_exercise || 0,
        time_on: this.exercise.time_on || 0,
        time_off: this.exercise.time_off || 0,
        to_failure: this.exercise.to_failure || false,
        add_remove_weight: this.exercise.add_remove_weight || false,
        sort_order: this.exercise.sort_order
      };
      this.isVideoMedia = !!this.form.video_url;

      const total_time = this.exercise.total_time;
      if (typeof total_time === "undefined") {
        this.updateTotalTime();
      } else {
        this.form.total_time = total_time;
      }
    },
    resetForm() {
      this.form = {
        name: null,
        style: "Fingers",
        duration: null,
        body_position_id: null,
        grip_type_id: null,
        image_url: null,
        video_url: null,
        instructions: null
      };
      this.$v.$reset();
    },
    getBodyPositions() {
      this.$store
        .dispatch("builder/fetchBodyPositions")
        .then(() => (this.bodyPositionsLoading = false));
    },
    getGripTypes() {
      this.$store.dispatch("builder/fetchGripTypes").then(() => (this.gripTypesLoading = false));
    },
    updateTotalTime() {
      this.form.total_time = TimeCalculations.getExerciseTotalTime(this.form);
    },
    openSubModal(actionType, subObjectType) {
      let subObject = {
        action: actionType,
        type: subObjectType,
        objectId:
          subObjectType === "Body Position" ? this.form.body_position_id : this.form.grip_type_id
      };

      this.$emit("openSubModal", subObject);
    },
    submit() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit("submitForm", this.form);
      }
    }
  }
};
</script>

<style scoped>
.v-text-field--outlined .v-input__prepend-outer {
  margin-top: 5px !important;
}
</style>
